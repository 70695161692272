import * as yup from 'yup';

export const UserNameValidation = () => {
  return yup
    .string()
    .matches(/^[a-zA-Z0-9_.-]*$/, 'Only alphabets and numbers are allowed for this field')
    .min(3, 'Username should be minimum 3 characters length')
    .max(15, 'Username should be maximum 15 characters length');
};

export const checkEmailValidity = (email) =>
  yup
    .object()
    .shape({
      email: yup.string().email()
    })
    .isValid({ email });

export const EmailValidation = () => {
  return yup.string().email('Enter a valid email');
};

export const EmailValidationWithoutRequiredField = (message) => {
  return yup.string().email(message);
};

export const AlphabetsValidation = (name) => {
  return yup
    .string()
    .required(name + ' is required')
    .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field ');
};

export const PasswordValidation = () => {
  return yup
    .string('Password is required')
    .required('Password is required')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      'Password must be minimum of eight characters, with at least one uppercase letter, one lowercase letter, one number and one special character'
    );
};
export const ConfirmPassword = () => {
  return yup
    .string('Password is required')
    .required('Password is required')
    .oneOf([yup.ref('newPassword')], 'Passwords do not match')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      'Password must be minimum of eight characters, with at least one uppercase letter, one lowercase letter, one number and one special character'
    );
};

//TODO better validation
export const PhoneValidation = (min = 10, max = 14) => {
  return yup
    .string()
    .matches(/^[0-9]+$/, 'Only numbers are allowed for this field')
    .min(min, 'Phone number is not valid')
    .max(max, 'Phone number is not valid')
    .nullable();
};

export const AlphabetsWithoutRequiredValidation = () => {
  return yup.string().matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field ');
};

export const NumberWithoutRequiredValidation = (name) => {
  return yup
    .string()
    .matches(/^[0-9]+$/, 'Only numbers are allowed for this field')
    .max(10, name + ' should be maximum 10 characters length');
};
export const EmailValidationWithoutRequiredValidation = () => {
  return yup.string('Email is required').email('Enter a valid email');
};

export const ConfirmPasswordValidation = (name) => {
  return yup
    .string()
    .required('Confirm Password is required')
    .when(`${name}`, {
      is: (val) => (val && val.length > 0 ? true : false),
      then: yup.string().oneOf([yup.ref(`${name}`)], 'Both password needs to be the same')
    });
};

export const RequiredValidation = (name) => {
  return yup.string().required(`${name} is required`);
};
export const OnlyNumberWithoutRequired = (message) => {
  return yup.number().typeError(message);
};
export const RequiredField = (message) => {
  return yup.string().required(`${message} is required`);
};
export const RequiredArray = (message) => {
  return yup.array().min(1, `${message} is required`);
};
