import React, { useState, useEffect, useRef } from 'react';
import NumberFormat from 'react-number-format';
import { Formik, Form, Field, FieldArray } from 'formik';
import { ConnectedFocusError } from 'focus-formik-error';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useHistory, useParams } from 'react-router-dom';
import ClearIcon from '@material-ui/icons/Clear';
import UploadIcon from '../../assets/images/upload-icon.svg';
// import ClearIcon from '@material-ui/icons/Clear';
import {
  Button,
  TextField,
  Typography,
  Grid,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextareaAutosize,
  Checkbox,
  InputLabel,
  Box,
  FormHelperText,
  // Paper,
  AccordionSummary,
  AccordionDetails,
  Accordion,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Paper,
  IconButton
  // IconButton
} from '@material-ui/core';

import { ThemeProvider } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import { useDispatch, useSelector } from 'react-redux';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
// import ClearIcon from '@material-ui/icons/Clear';
import Dropzone from 'react-dropzone';
import { ToastContainer, toast } from 'react-toastify';

import theme from '../../styles/theme';

import UseUnsavedWarningMsg from '../../hooks/useUnsavedWarningMsg';
import { clientUpdateOnSubmit } from './../formik-component/clientUpdateFormFormik';
import Message from '../message/Message';
import PopupDialog from './PopupDialog';

import { createClientProfile } from './api';

import 'react-toastify/dist/ReactToastify.css';
import { getCrmUsers } from './../../store/actions/userAction';
import { ADMIN, SUPER_ADMIN } from './../../constants/roles';
import AreYouSurePopUp from '../dropdown/AreYouSure';
import { DUPLICATE_NAME_ERROR } from '../../constants';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

const ClientProfileFormTemplate = ({ formType, initialValues, validationSchema }) => {
  const [
    toastColor,
    // eslint-disable-next-line no-unused-vars
    setToastColor
  ] = useState('');
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();

  const formikRef = useRef();

  const clientLists = useSelector((state) => {
    return state.clientReducer;
  });
  const { clientDetails } = clientLists;
  const userState = useSelector((state) => {
    return state.userReducer;
  });

  const [Prompt, setDirty, resetDirty] = UseUnsavedWarningMsg();
  const [expanded, setExpanded] = React.useState(
    params?.id ? `panel[${clientDetails?.transactionDetails?.length - 1}]` : `panel[0]`
  );
  const togglePanelOpen = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const [leadOpen, setleadOpen] = useState(null);
  const [leadClose, setleadClose] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [leadOpenInitial, setleadOpenInitial] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [leadCloseInitial, setleadCloseInitial] = useState(null);
  const [followUpAssigendTo, setFollowUpAssignedTo] = useState(null);
  const [referral, setReferral] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [referralName, setReferralName] = useState(null);
  const [openAlert, setOpenAlert] = useState(false);
  const [createWithoutValidation, setCreateWithoutValidation] = useState(false);
  const [isClient, setIsClient] = useState(false);

  const clearDataOfAutoComplet = () => {
    setleadOpen(null);
    setleadClose(null);
    setFollowUpAssignedTo(null);
    setReferral(null);
    setReferralName(null);
  };

  useEffect(() => {
    if (formikRef.current && formType === 'create') {
      formikRef.current.setFieldValue('referralName', '');
      formikRef.current.setFieldValue('referralEmail', '');
      formikRef.current.setFieldValue('referralPhone', '');
      formikRef.current.setFieldValue('referralStreetNo', '');
      formikRef.current.setFieldValue('referralStreetAddress', '');
      formikRef.current.setFieldValue('referralSuburb', '');
      formikRef.current.setFieldValue('referralState', '');
      formikRef.current.setFieldValue('referralPostalCode', '');
      formikRef.current.setFieldValue('referralCountry', '');
    }
    if (referral && formType === 'create') setReferral(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClient]);

  useEffect(() => {
    if (formikRef.current && referral !== null && isClient) {
      formikRef.current.setFieldValue('referralEmail', referral?.email || '');
      formikRef.current.setFieldValue('referralPhone', referral?.phone || '');
      formikRef.current.setFieldValue('referralStreetNo', referral?.residential?.street || '');
      formikRef.current.setFieldValue('referralStreetAddress', referral?.residential?.streetAddress || '');
      formikRef.current.setFieldValue('referralSuburb', referral?.residential?.suburb || '');
      formikRef.current.setFieldValue('referralState', referral?.residential?.state || '');
      formikRef.current.setFieldValue('referralPostalCode', referral?.residential?.zip || '');
      formikRef.current.setFieldValue('referralCountry', referral?.residential?.country || '');
    }
    // if (referral && !isClient) {
    //   const referralName = `${referral?.firstName || ''} ${referral?.lastName || ''}`
    //   formikRef.current.setFieldValue('referralName', referralName.trim() || '');
    // }
  }, [referral, isClient, formType]);

  const onSubmit = (values_, onSubmitProps) => {
    const nonEmptyTransaction = values_.transactions.filter((transaction) => {
      return (
        transaction.ooInvst ||
        transaction.propertyLotAddress ||
        transaction.propertyType ||
        transaction.purchaseStatus ||
        transaction.resultNotes ||
        transaction.upTo === true
      );
    });

    const values = {
      ...values_,
      transactions: nonEmptyTransaction,
      referralName: referral !== null ? `${referral?.firstName} ${referral?.lastName}` : values_?.referralName || ''
    };
    // if (
    //   referral !== null &&
    //   values?.referralEmail?.toLowerCase() === referral?.email.toLowerCase()
    // ) {
    //   values.referralEmail = referral.email;
    //   values.referralPhone = referral?.phone;
    //   values.referralStreetNo = referral?.residential?.street;
    //   values.referralStreetAddress = referral?.residential?.streetAddress;
    //   values.referralSuburb = referral?.residential?.suburb;
    //   values.referralState = referral?.residential?.state;
    //   values.referralPostalCode = referral?.residential?.zip;
    //   values.referralCountry = referral?.residential?.country;
    // }
    // CREATE A NEW CLIENT
    if (formType === 'create') {
      // don't move forward if assigned to is not selected
      const hasFollowNote = values.clientFollowUpNotes.length !== 0 && values.clientFollowUpNotes[0].clientFollowUpDate;
      if (hasFollowNote && !followUpAssigendTo) {
        onSubmitProps.setFieldError('clientFollowUpNotes[0].assignedTo', 'Assigned To is required');
        onSubmitProps.setSubmitting(false);
        return;
      }

      createClientProfile(
        values,
        onSubmitProps,
        dispatch,
        leadOpen,
        leadClose,
        setleadOpen,
        setleadClose,
        resetDirty,
        followUpAssigendTo,
        setFollowUpAssignedTo,
        setReferral,
        setReferralName,
        createWithoutValidation,
        setCreateWithoutValidation
      );
    }
    // UPDATE CLIENT DETAILS
    if (formType === 'update') {
      clientUpdateOnSubmit(values, onSubmitProps, dispatch, params.id, history, leadClose, leadOpen, resetDirty);
    }
  };

  // let handleonDrop = (acceptedFiles, rejectedFiles, setFieldValue, values, index) => {
  //   // console.log('index', index);
  //   // console.log([...values.files[index]]);
  //   // return
  //   const prevFiles = values.files[index] ? [...values.files[index]] : [];
  //   if (acceptedFiles.length > 0) {
  //     setFieldValue(`files[${index}]`, [...prevFiles, ...acceptedFiles]);
  //     setToastColor('toast-success');
  //     toast('File successfully uploaded', {
  //       position: 'top-center',
  //       autoClose: 2000,
  //       className: 'toast-success',
  //       progressClassName: 'toastProgress'
  //     });
  //   }
  //   if (rejectedFiles.length > 0) {
  //     rejectedFiles.forEach((file) => {
  //       setToastColor('toast-error');
  //       if (file.file.size > 1024*1024*) {
  //         toast('File size must be less than 10MB', {
  //           position: 'top-center',
  //           autoClose: false,
  //           className: 'toast-error'
  //         });
  //       } else {
  //         toast('Only .docx and .pdf files are accepted', {
  //           position: 'top-center',
  //           autoClose: false,
  //           className: 'toast-error'
  //         });
  //       }
  //     });
  //   }
  // };

  // let handleClose = (li, si, setFieldValue, values) => {
  //   const changedarray = values.files.filter((value, i) => li === i);
  //   const notchangearray = values.files.filter((value, i) => li !== i);

  //   const changedarrayexact = changedarray[0].filter((value, i) => si !== i);

  //   notchangearray.splice(li, 0, changedarrayexact);

  //   setFieldValue('files', notchangearray);
  // };

  let handleonDrop = (acceptedFiles, rejectedFiles, setFieldValue, values) => {
    if (acceptedFiles.length > 0) {
      setFieldValue('files', [...values.files, ...acceptedFiles]);
      toast('File successfully uploaded', {
        position: 'top-center',
        autoClose: 2000,
        className: 'toast-success',
        progressClassName: 'toastProgress'
      });
    }
    if (rejectedFiles.length > 0) {
      rejectedFiles.forEach((file) => {
        if (file.file.size > 1024 * 1024 * 20) {
          toast('File size must be less than 20MB', {
            position: 'top-center',
            autoClose: false,
            className: 'toast-error'
          });
        } else {
          toast('Only .docx and .pdf files are accepted', {
            position: 'top-center',
            autoClose: false,
            className: 'toast-error'
          });
        }
      });
    }
  };

  let handleClose = (i, setFieldValue, values) => {
    let changedFiles = values.files.filter((file, index) => index !== i);
    setFieldValue('files', changedFiles);
  };

  const [clientDetailAutoComplete, setClientDetailAutoComplete] = useState([]);
  const [crmUsersList, setCrmUsersList] = useState([]);

  useEffect(() => {
    // FETCH USERS LIST
    dispatch(getCrmUsers());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const clientDataPull = () => {
      if (typeof userState.userList !== 'undefined' && typeof userState.userList.results !== 'undefined') {
        // const userNameList = userState.userList.results.map(user => `${user.firstName} ${user.lastName}`);
        setCrmUsersList(userState.userList.results || []);
        const adminUsers = userState.userList.results.filter(
          (user) => user.accessLevel === ADMIN || user.accessLevel === SUPER_ADMIN
        );
        setClientDetailAutoComplete(adminUsers);
      }
    };
    clientDataPull();
  }, [userState.userList]);

  useEffect(() => {
    if (typeof userState.userList !== 'undefined' && typeof userState.userList.results !== 'undefined') {
      const referralUser = userState.userList.results.find(
        (user) => user?.email?.toLowerCase() === initialValues?.referralEmail?.toLowerCase()
        // (user) => `${user?.firstName} ${user?.lastName}` === initialValues?.referralName
      );
      if (referralUser) {
        setIsClient(true);
        setReferral(referralUser);
      } else {
        setIsClient(false);
        setReferral(null);
        // formikRef.current?.resetForm({ values: initialValues })
      }
    }
  }, [userState.userList, initialValues]);

  useEffect(() => {
    if (formType === 'update') {
      if (initialValues.referralLeadOpen) {
        const referralLeadOpen = clientDetailAutoComplete.filter(
          (client) => client.email === initialValues.referralLeadOpen
        );
        setleadOpen(referralLeadOpen[0] || null);
        setleadOpenInitial(referralLeadOpen[0] || null);
      }
      if (initialValues.referralLeadClose) {
        const referralLeadClose = clientDetailAutoComplete.filter(
          (client) => client.email === initialValues.referralLeadClose
        );
        setleadClose(referralLeadClose[0] || null);
        setleadCloseInitial(referralLeadClose[0] || null);
      }
    }
  }, [clientDetailAutoComplete, initialValues, formType]);

  const handleClickOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
    setCreateWithoutValidation(false);
  };

  useEffect(() => {
    formType === 'create' && setCreateWithoutValidation(false);
    // if (formType === 'create' && DUPLICATE_NAME_ERROR === clientLists.message) {
    //   handleClickOpenAlert();
    // }
    formType === 'create' && DUPLICATE_NAME_ERROR === clientLists.message && handleClickOpenAlert();
  }, [clientLists.message, formType]);
  const [openRemoveTransaction, setOpenRemoveTransaction] = React.useState(false);

  const handleClickOpenRemoveTransaction = () => {
    setOpenRemoveTransaction(true);
  };

  const handleCloseRemoveTransaction = () => {
    setOpenRemoveTransaction(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Backdrop open={clientLists.loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {DUPLICATE_NAME_ERROR === clientLists.message ? null : (
        <Message status={clientLists.status} severity={clientLists.severity} message={clientLists.message} />
      )}
      <ToastContainer toastClassName={toastColor} />
      {Prompt}

      <Box className="client-form-wrapper">
        <div className="content-section">
          <h2>
            {formType === 'create' && 'Create'}
            {formType === 'update' && 'Update'} Client Profile
          </h2>
          <Formik
            innerRef={formikRef}
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            enableReinitialize
          >
            {(formik) => {
              const { values, isSubmitting, handleChange, handleSubmit } = formik;
              return (
                <Form
                  id="myform"
                  className="client-profile-form"
                  onChange={() => {
                    setDirty();
                  }}
                >
                  <ConnectedFocusError />
                  <div className="form-category">
                    <span className="category-title">Personal Details:</span>
                    <div className="form-row">
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={6} lg={3} xl={2}>
                          <Field name="title">
                            {(props) => {
                              const { field, meta } = props;
                              return (
                                <div>
                                  <InputLabel>Title</InputLabel>
                                  <TextField
                                    className="text-field-custom"
                                    onChange={() => {
                                      setDirty();
                                    }}
                                    {...field}
                                    error={
                                      // meta.touched &&
                                      meta.error ? true : false
                                    }
                                    helperText={
                                      // meta.touched &&
                                      meta.error ? <div>{meta.error}</div> : null
                                    }
                                  />
                                </div>
                              );
                            }}
                          </Field>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                          <Field name="firstName">
                            {(props) => {
                              const { field, meta } = props;

                              return (
                                <div>
                                  <TextField
                                    label={
                                      <>
                                        First Name <span style={{ color: 'red' }}>*</span>
                                      </>
                                    }
                                    {...field}
                                    error={meta.touched && meta.error ? true : false}
                                    helperText={meta.touched && meta.error ? <div>{meta.error}</div> : null}
                                    className="text-field-custom"
                                  />
                                </div>
                              );
                            }}
                          </Field>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                          <Field name="middleName">
                            {(props) => {
                              const { field, meta } = props;
                              return (
                                <div>
                                  <TextField
                                    label="Middle Name"
                                    className="text-field-custom"
                                    {...field}
                                    error={meta.touched && meta.error ? true : false}
                                    helperText={meta.touched && meta.error ? <div>{meta.error}</div> : null}
                                  />
                                </div>
                              );
                            }}
                          </Field>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                          <Field name="lastName">
                            {(props) => {
                              const { field, meta } = props;
                              return (
                                <div>
                                  <TextField
                                    label={
                                      <>
                                        Last Name <span className="color-red">*</span>
                                      </>
                                    }
                                    className="text-field-custom"
                                    {...field}
                                    error={meta.touched && meta.error ? true : false}
                                    helperText={meta.touched && meta.error ? <div>{meta.error}</div> : null}
                                  />
                                </div>
                              );
                            }}
                          </Field>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                          <Field name="personalEmail">
                            {(props) => {
                              const { field, meta } = props;
                              return (
                                <div>
                                  <TextField
                                    label={
                                      <>
                                        Email <span className="color-red">*</span>
                                      </>
                                    }
                                    className="text-field-custom"
                                    {...field}
                                    error={meta.touched && meta.error ? true : false}
                                    helperText={meta.touched && meta.error ? <div>{meta.error}</div> : null}
                                  />
                                </div>
                              );
                            }}
                          </Field>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                          <Field name="personalMobile">
                            {(props) => {
                              const { field, meta } = props;
                              return (
                                <div>
                                  <TextField
                                    label={<>Mobile Number</>}
                                    className="text-field-custom"
                                    {...field}
                                    error={
                                      // meta.touched &&
                                      meta.error ? true : false
                                    }
                                    helperText={
                                      // meta.touched &&
                                      meta.error ? <div>{meta.error}</div> : null
                                    }
                                  />
                                </div>
                              );
                            }}
                          </Field>
                        </Grid>

                        <Grid item xs={12} md={6} lg={3}>
                          <Field name="personalPhone">
                            {(props) => {
                              const { field, meta } = props;
                              return (
                                <div>
                                  <TextField
                                    label={<>Landline Number</>}
                                    className="text-field-custom"
                                    {...field}
                                    error={
                                      // meta.touched &&
                                      meta.error ? true : false
                                    }
                                    helperText={
                                      // meta.touched &&
                                      meta.error ? <div>{meta.error}</div> : null
                                    }
                                  />
                                </div>
                              );
                            }}
                          </Field>
                        </Grid>
                      </Grid>
                    </div>
                    <div className="form-row">
                      <span className="subtitle-label color-primary">Mailing Address</span>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={6} lg={3}>
                          <Field name="mailingStreetNo">
                            {(props) => {
                              const { field, meta } = props;
                              return (
                                <div>
                                  <TextField
                                    className="text-field-custom"
                                    {...field}
                                    label="Street No"
                                    error={
                                      // meta.touched &&
                                      meta.error ? true : false
                                    }
                                    helperText={
                                      // meta.touched &&
                                      meta.error ? <div>{meta.error}</div> : null
                                    }
                                  />
                                </div>
                              );
                            }}
                          </Field>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                          <Field name="mailingStreetAddress">
                            {(props) => {
                              const { field, meta } = props;
                              return (
                                <div>
                                  <TextField
                                    className="text-field-custom"
                                    {...field}
                                    label="Street Address"
                                    error={
                                      // meta.touched &&
                                      meta.error ? true : false
                                    }
                                    helperText={
                                      // meta.touched &&
                                      meta.error ? <div>{meta.error}</div> : null
                                    }
                                  />
                                </div>
                              );
                            }}
                          </Field>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                          <Field name="mailingSuburb">
                            {(props) => {
                              const { field, meta } = props;
                              return (
                                <div>
                                  <TextField
                                    className="text-field-custom"
                                    {...field}
                                    label="Suburb"
                                    error={
                                      // meta.touched &&
                                      meta.error ? true : false
                                    }
                                    helperText={
                                      // meta.touched &&
                                      meta.error ? <div>{meta.error}</div> : null
                                    }
                                  />
                                </div>
                              );
                            }}
                          </Field>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                          <Field name="mailingState">
                            {(props) => {
                              const { field, meta } = props;
                              return (
                                <div>
                                  <TextField
                                    className="text-field-custom"
                                    {...field}
                                    label="State"
                                    error={
                                      // meta.touched &&
                                      meta.error ? true : false
                                    }
                                    helperText={
                                      // meta.touched &&
                                      meta.error ? <div>{meta.error}</div> : null
                                    }
                                  />
                                </div>
                              );
                            }}
                          </Field>
                        </Grid>

                        <Grid item xs={12} md={6} lg={3} xl={2}>
                          <Field name="mailingZip">
                            {(props) => {
                              const { field, meta } = props;
                              return (
                                <div>
                                  <TextField
                                    className="text-field-custom"
                                    {...field}
                                    label="Postal Code"
                                    error={
                                      // meta.touched &&
                                      meta.error ? true : false
                                    }
                                    helperText={
                                      // meta.touched &&
                                      meta.error ? <div>{meta.error}</div> : null
                                    }
                                  />
                                </div>
                              );
                            }}
                          </Field>
                        </Grid>

                        <Grid item xs={12} md={6} lg={3}>
                          <Field name="mailingCountry">
                            {(props) => {
                              const { field, meta } = props;
                              return (
                                <div>
                                  <TextField
                                    className="text-field-custom"
                                    {...field}
                                    label="Country"
                                    error={
                                      // meta.touched &&
                                      meta.error ? true : false
                                    }
                                    helperText={
                                      // meta.touched &&
                                      meta.error ? <div>{meta.error}</div> : null
                                    }
                                  />
                                </div>
                              );
                            }}
                          </Field>
                        </Grid>
                      </Grid>
                    </div>
                    <div className="form-row">
                      <div className="same-value">
                        <span className="subtitle-label">Residential Address</span>
                        <div className="checkbox">
                          <Field name="sameAsMailingAddress">
                            {(props) => {
                              const { field, form } = props;
                              const { setFieldValue, values } = form;

                              return (
                                <Checkbox
                                  color="primary"
                                  style={{ padding: '0 2px 0 10px' }}
                                  {...field}
                                  checked={values.sameAsMailingAddress}
                                  onChange={(e) => {
                                    setFieldValue('sameAsMailingAddress', e.target.checked);
                                    if (e.target.checked) {
                                      setFieldValue('residentialStreetNo', values.mailingStreetNo);
                                      setFieldValue('residentialStreetAddress', values.mailingStreetAddress);
                                      setFieldValue('residentialSuburb', values.mailingSuburb);
                                      setFieldValue('residentialState', values.mailingState);
                                      setFieldValue('residentialZip', values.mailingZip);
                                      setFieldValue('residentialCountry', values.mailingCountry);
                                    }
                                  }}
                                />
                              );
                            }}
                          </Field>

                          <span className="checkbox-label">Same As Mailing Address</span>
                        </div>
                      </div>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={6} lg={3}>
                          <Field name="residentialStreetNo">
                            {(props) => {
                              const { field, form, meta } = props;
                              const { setFieldValue, values } = form;

                              return (
                                <div>
                                  <TextField
                                    className="text-field-custom"
                                    autoComplete="offResidentialStreetNo"
                                    {...field}
                                    label="Street No"
                                    value={
                                      values.sameAsMailingAddress ? values.mailingStreetNo : values.residentialStreetNo
                                    }
                                    onChange={(e) => {
                                      if (!values.sameAsMailingAddress) {
                                        setFieldValue('residentialStreetNo', e.target.value);
                                      }
                                    }}
                                    error={
                                      // meta.touched &&
                                      meta.error ? true : false
                                    }
                                    helperText={
                                      // meta.touched &&
                                      meta.error ? <div>{meta.error}</div> : null
                                    }
                                  />
                                </div>
                              );
                            }}
                          </Field>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                          <Field name="residentialStreetAddress">
                            {(props) => {
                              const { field, form, meta } = props;
                              const { setFieldValue, values } = form;

                              return (
                                <div>
                                  <TextField
                                    className="text-field-custom"
                                    autoComplete="offResidentialStreetAddress"
                                    {...field}
                                    label="Street Address"
                                    value={
                                      values.sameAsMailingAddress
                                        ? values.mailingStreetAddress
                                        : values.residentialStreetAddress
                                    }
                                    onChange={(e) => {
                                      setFieldValue('residentialStreetAddress', e.target.value);
                                    }}
                                    error={
                                      // meta.touched &&
                                      meta.error ? true : false
                                    }
                                    helperText={
                                      // meta.touched &&
                                      meta.error ? <div>{meta.error}</div> : null
                                    }
                                  />
                                </div>
                              );
                            }}
                          </Field>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                          <Field name="residentialSuburb">
                            {(props) => {
                              const { field, form, meta } = props;
                              const { setFieldValue, values } = form;

                              return (
                                <div>
                                  <TextField
                                    className="text-field-custom"
                                    autoComplete="offResidentialSuburb"
                                    {...field}
                                    label="Suburb"
                                    value={
                                      values.sameAsMailingAddress ? values.mailingSuburb : values.residentialSuburb
                                    }
                                    onChange={(e) => {
                                      if (!values.sameAsMailingAddress) {
                                        setFieldValue('residentialSuburb', e.target.value);
                                      }
                                    }}
                                    error={
                                      // meta.touched &&
                                      meta.error ? true : false
                                    }
                                    helperText={
                                      // meta.touched &&
                                      meta.error ? <div>{meta.error}</div> : null
                                    }
                                  />
                                </div>
                              );
                            }}
                          </Field>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                          <Field name="residentialState">
                            {(props) => {
                              const { field, form, meta } = props;
                              const { setFieldValue, values } = form;

                              return (
                                <div>
                                  <TextField
                                    className="text-field-custom"
                                    autoComplete="offResidentialState"
                                    {...field}
                                    label="State"
                                    value={values.sameAsMailingAddress ? values.mailingState : values.residentialState}
                                    onChange={(e) => {
                                      if (!values.sameAsMailingAddress) {
                                        setFieldValue('residentialState', e.target.value);
                                      }
                                    }}
                                    error={
                                      // meta.touched &&
                                      meta.error ? true : false
                                    }
                                    helperText={
                                      // meta.touched &&
                                      meta.error ? <div>{meta.error}</div> : null
                                    }
                                  />
                                </div>
                              );
                            }}
                          </Field>
                        </Grid>

                        <Grid item xs={12} md={6} lg={3} xl={2}>
                          <Field name="residentialZip">
                            {(props) => {
                              const { field, form, meta } = props;
                              const { setFieldValue, values } = form;

                              return (
                                <div>
                                  <TextField
                                    className="text-field-custom"
                                    autoComplete="offResidentialZip"
                                    {...field}
                                    label="Postal Code"
                                    value={values.sameAsMailingAddress ? values.mailingZip : values.residentialZip}
                                    onChange={(e) => {
                                      if (!values.sameAsMailingAddress) {
                                        setFieldValue('residentialZip', e.target.value);
                                      }
                                    }}
                                    error={
                                      // meta.touched &&
                                      meta.error ? true : false
                                    }
                                    helperText={
                                      // meta.touched &&
                                      meta.error ? <div>{meta.error}</div> : null
                                    }
                                  />
                                </div>
                              );
                            }}
                          </Field>
                        </Grid>

                        <Grid item xs={12} md={6} lg={3}>
                          <Field name="residentialCountry">
                            {(props) => {
                              const { field, form, meta } = props;
                              const { setFieldValue, values } = form;

                              return (
                                <div>
                                  <TextField
                                    className="text-field-custom"
                                    autoComplete="offResidentialCountry"
                                    {...field}
                                    label="Country"
                                    value={
                                      values.sameAsMailingAddress ? values.mailingCountry : values.residentialCountry
                                    }
                                    onChange={(e) => {
                                      if (!values.sameAsMailingAddress) {
                                        setFieldValue('residentialCountry', e.target.value);
                                      }
                                    }}
                                    error={
                                      // meta.touched &&
                                      meta.error ? true : false
                                    }
                                    helperText={
                                      // meta.touched &&
                                      meta.error ? <div>{meta.error}</div> : null
                                    }
                                  />
                                </div>
                              );
                            }}
                          </Field>
                        </Grid>
                      </Grid>
                    </div>
                    <div className="form-row">
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={6} lg={3}>
                          <Field name="maritalStatus">
                            {(props) => {
                              const { field, meta } = props;

                              return (
                                <div>
                                  <InputLabel>Marital status/ Partner</InputLabel>
                                  <Select
                                    className="text-field-custom"
                                    {...field}
                                    error={
                                      // meta.touched &&
                                      meta.error ? true : false
                                    }
                                    helperText={
                                      // meta.touched &&
                                      meta.error ? <div>{meta.error}</div> : null
                                    }
                                  >
                                    <MenuItem value="Single">Single</MenuItem>
                                    <MenuItem value="Married">Married</MenuItem>
                                    <MenuItem value="Partner">Partner</MenuItem>
                                    <MenuItem value="Divorced">Divorced</MenuItem>
                                    <MenuItem value="Widowed">Widowed</MenuItem>
                                    <MenuItem value="Separated">Separated</MenuItem>
                                    <MenuItem value="Others">Others</MenuItem>
                                  </Select>
                                </div>
                              );
                            }}
                          </Field>
                        </Grid>
                        {values.maritalStatus === 'Married' ? (
                          <>
                            <Grid item xs={12}>
                              <Grid container spacing={3}>
                                <Grid item xs={12} md={6} lg={3} xl={2}>
                                  <Field name="spouseTitle">
                                    {(props) => {
                                      const { field, meta } = props;
                                      return (
                                        <div>
                                          <InputLabel>Title</InputLabel>
                                          <TextField
                                            className="text-field-custom"
                                            {...field}
                                            error={
                                              // meta.touched &&
                                              meta.error ? true : false
                                            }
                                            helperText={
                                              // meta.touched &&
                                              meta.error ? <div>{meta.error}</div> : null
                                            }
                                          />
                                        </div>
                                      );
                                    }}
                                  </Field>
                                </Grid>
                                <Grid item xs={12} md={6} lg={3}>
                                  <Field name="spouseFirstName">
                                    {(props) => {
                                      const { field, meta } = props;
                                      return (
                                        <div>
                                          <InputLabel>Spouse's First Name</InputLabel>
                                          <TextField
                                            className="text-field-custom"
                                            {...field}
                                            error={
                                              // meta.touched &&
                                              meta.error ? true : false
                                            }
                                            helperText={
                                              // meta.touched &&
                                              meta.error ? <div>{meta.error}</div> : null
                                            }
                                          />
                                        </div>
                                      );
                                    }}
                                  </Field>
                                </Grid>
                                <Grid item xs={12} md={6} lg={3}>
                                  <Field name="spouseMiddleName">
                                    {(props) => {
                                      const { field, meta } = props;
                                      return (
                                        <div>
                                          <InputLabel>Spouse's Middle Name</InputLabel>
                                          <TextField
                                            className="text-field-custom"
                                            {...field}
                                            error={
                                              // meta.touched &&
                                              meta.error ? true : false
                                            }
                                            helperText={
                                              // meta.touched &&
                                              meta.error ? <div>{meta.error}</div> : null
                                            }
                                          />
                                        </div>
                                      );
                                    }}
                                  </Field>
                                </Grid>
                                <Grid item xs={12} md={6} lg={3}>
                                  <Field name="spouseLastName">
                                    {(props) => {
                                      const { field, meta } = props;
                                      return (
                                        <div>
                                          <InputLabel>Spouse's Last Name</InputLabel>
                                          <TextField
                                            className="text-field-custom"
                                            {...field}
                                            error={
                                              // meta.touched &&
                                              meta.error ? true : false
                                            }
                                            helperText={
                                              // meta.touched &&
                                              meta.error ? <div>{meta.error}</div> : null
                                            }
                                          />
                                        </div>
                                      );
                                    }}
                                  </Field>
                                </Grid>
                                <Grid item xs={12} md={6} lg={3}>
                                  <Field name="spouseEmail">
                                    {(props) => {
                                      const { field, meta } = props;
                                      return (
                                        <div>
                                          <InputLabel>Spouse's Email</InputLabel>
                                          <TextField
                                            className="text-field-custom"
                                            {...field}
                                            error={
                                              // meta.touched &&
                                              meta.error ? true : false
                                            }
                                            helperText={
                                              // meta.touched &&
                                              meta.error ? <div>{meta.error}</div> : null
                                            }
                                          />
                                        </div>
                                      );
                                    }}
                                  </Field>
                                </Grid>
                                <Grid item xs={12} md={6} lg={3}>
                                  <Field name="spouseNumber">
                                    {(props) => {
                                      const { field, meta } = props;
                                      return (
                                        <div>
                                          <TextField
                                            label={<>Mobile Number</>}
                                            className="text-field-custom"
                                            {...field}
                                            error={
                                              // meta.touched &&
                                              meta.error ? true : false
                                            }
                                            helperText={
                                              // meta.touched &&
                                              meta.error ? <div>{meta.error}</div> : null
                                            }
                                          />
                                        </div>
                                      );
                                    }}
                                  </Field>
                                </Grid>
                                <Grid item xs={12} md={6} lg={3}>
                                  <Field name="spouseLandline">
                                    {(props) => {
                                      const { field, meta } = props;
                                      return (
                                        <div>
                                          <TextField
                                            label={<>Landline Number</>}
                                            className="text-field-custom"
                                            {...field}
                                            error={
                                              // meta.touched &&
                                              meta.error ? true : false
                                            }
                                            helperText={
                                              // meta.touched &&
                                              meta.error ? <div>{meta.error}</div> : null
                                            }
                                          />
                                        </div>
                                      );
                                    }}
                                  </Field>
                                </Grid>
                              </Grid>
                            </Grid>
                          </>
                        ) : null}

                        {values.maritalStatus === 'Partner' ? (
                          <>
                            <Grid item xs={12}>
                              <Grid container spacing={3}>
                                <Grid item xs={12} md={6} lg={3} xl={2}>
                                  <Field name="partnerTitle">
                                    {(props) => {
                                      const { field, meta } = props;
                                      return (
                                        <div>
                                          <InputLabel>Title</InputLabel>
                                          <TextField
                                            className="text-field-custom"
                                            {...field}
                                            error={
                                              // meta.touched &&
                                              meta.error ? true : false
                                            }
                                            helperText={
                                              // meta.touched &&
                                              meta.error ? <div>{meta.error}</div> : null
                                            }
                                          />
                                        </div>
                                      );
                                    }}
                                  </Field>
                                </Grid>
                                <Grid item xs={12} md={6} lg={3}>
                                  <Field name="partnerFirstName">
                                    {(props) => {
                                      const { field, meta } = props;
                                      return (
                                        <div>
                                          <InputLabel>Partner's First Name</InputLabel>
                                          <TextField
                                            className="text-field-custom"
                                            {...field}
                                            error={
                                              // meta.touched &&
                                              meta.error ? true : false
                                            }
                                            helperText={
                                              // meta.touched &&
                                              meta.error ? <div>{meta.error}</div> : null
                                            }
                                          />
                                        </div>
                                      );
                                    }}
                                  </Field>
                                </Grid>
                                <Grid item xs={12} md={6} lg={3}>
                                  <Field name="partnerMiddleName">
                                    {(props) => {
                                      const { field, meta } = props;
                                      return (
                                        <div>
                                          <InputLabel>Partner's Middle Name</InputLabel>
                                          <TextField
                                            className="text-field-custom"
                                            {...field}
                                            error={
                                              // meta.touched &&
                                              meta.error ? true : false
                                            }
                                            helperText={
                                              // meta.touched &&
                                              meta.error ? <div>{meta.error}</div> : null
                                            }
                                          />
                                        </div>
                                      );
                                    }}
                                  </Field>
                                </Grid>
                                <Grid item xs={12} md={6} lg={3}>
                                  <Field name="partnerLastName">
                                    {(props) => {
                                      const { field, meta } = props;
                                      return (
                                        <div>
                                          <InputLabel>Partner's Last Name</InputLabel>
                                          <TextField
                                            className="text-field-custom"
                                            {...field}
                                            error={
                                              // meta.touched &&
                                              meta.error ? true : false
                                            }
                                            helperText={
                                              // meta.touched &&
                                              meta.error ? <div>{meta.error}</div> : null
                                            }
                                          />
                                        </div>
                                      );
                                    }}
                                  </Field>
                                </Grid>
                                <Grid item xs={12} md={6} lg={3}>
                                  <Field name="partnerEmail">
                                    {(props) => {
                                      const { field, meta } = props;
                                      return (
                                        <div>
                                          <InputLabel>Partner's Email</InputLabel>
                                          <TextField
                                            className="text-field-custom"
                                            {...field}
                                            error={
                                              // meta.touched &&
                                              meta.error ? true : false
                                            }
                                            helperText={
                                              // meta.touched &&
                                              meta.error ? <div>{meta.error}</div> : null
                                            }
                                          />
                                        </div>
                                      );
                                    }}
                                  </Field>
                                </Grid>
                                <Grid item xs={12} md={6} lg={3}>
                                  <Field name="partnerNumber">
                                    {(props) => {
                                      const { field, meta } = props;
                                      return (
                                        <div>
                                          <TextField
                                            label={<>Mobile Number</>}
                                            className="text-field-custom"
                                            {...field}
                                            error={
                                              // meta.touched &&
                                              meta.error ? true : false
                                            }
                                            helperText={
                                              // meta.touched &&
                                              meta.error ? <div>{meta.error}</div> : null
                                            }
                                          />
                                        </div>
                                      );
                                    }}
                                  </Field>
                                </Grid>
                                <Grid item xs={12} md={6} lg={3}>
                                  <Field name="partnerLandline">
                                    {(props) => {
                                      const { field, meta } = props;
                                      return (
                                        <div>
                                          <TextField
                                            label={<>Landline Number</>}
                                            className="text-field-custom"
                                            {...field}
                                            error={
                                              // meta.touched &&
                                              meta.error ? true : false
                                            }
                                            helperText={
                                              // meta.touched &&
                                              meta.error ? <div>{meta.error}</div> : null
                                            }
                                          />
                                        </div>
                                      );
                                    }}
                                  </Field>
                                </Grid>
                              </Grid>
                            </Grid>
                          </>
                        ) : null}
                      </Grid>
                    </div>
                    <div className="form-row">
                      <span className="subtitle-label mb-lg">Customer Type</span>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <Field name="customerType">
                            {(props) => {
                              const { field, meta } = props;
                              return (
                                <div>
                                  <RadioGroup
                                    aria-label="customClientType"
                                    name="customClientType1"
                                    {...field}
                                    error={
                                      // meta.touched &&
                                      meta.error ? true : false
                                    }
                                    helperText={
                                      // meta.touched &&
                                      meta.error ? <div>{meta.error}</div> : null
                                    }
                                  >
                                    <Grid container spacing={2}>
                                      <Grid item xs={6} md={4} lg={2}>
                                        <FormControlLabel
                                          value="Clients"
                                          control={<Radio color="primary" />}
                                          label="Clients"
                                        />
                                      </Grid>
                                      <Grid item xs={6} md={4} lg={2}>
                                        <FormControlLabel
                                          value="Prospect"
                                          control={<Radio color="primary" />}
                                          label="Prospect"
                                        />
                                      </Grid>
                                      <Grid item xs={6} md={4} lg={2}>
                                        <FormControlLabel
                                          value="Others"
                                          control={<Radio color="primary" />}
                                          label="Handover"
                                        />
                                      </Grid>
                                    </Grid>
                                  </RadioGroup>
                                </div>
                              );
                            }}
                          </Field>
                        </Grid>
                      </Grid>
                    </div>
                    <div className="form-row">
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={6} lg={3}>
                          <Field name="entryDate">
                            {(props) => {
                              const { field, meta } = props;
                              return (
                                <TextField
                                  label="Entry Date"
                                  type="date"
                                  className="calender-feild"
                                  {...field}
                                  error={
                                    // meta.touched &&
                                    meta.error ? true : false
                                  }
                                  helperText={
                                    // meta.touched &&
                                    meta.error ? <div>{meta.error}</div> : null
                                  }
                                />
                              );
                            }}
                          </Field>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                          <Field name="clientStatus">
                            {(props) => {
                              const { field, meta } = props;
                              return (
                                <div>
                                  <InputLabel>Client Status</InputLabel>

                                  <Select
                                    className="text-field-custom"
                                    {...field}
                                    error={
                                      // meta.touched &&
                                      meta.error ? true : false
                                    }
                                    helperText={
                                      // meta.touched &&
                                      meta.error ? <div>{meta.error}</div> : null
                                    }
                                  >
                                    <MenuItem value="Active">Active</MenuItem>
                                    <MenuItem value="Inactive">Inactive</MenuItem>
                                    <MenuItem value="Others">Others</MenuItem>
                                  </Select>
                                </div>
                              );
                            }}
                          </Field>
                        </Grid>
                      </Grid>
                    </div>
                  </div>

                  <div className="form-category" style={{ display: formType === 'create' ? 'block' : 'none' }}>
                    <span className="category-title">Other Details:</span>
                    <div className="form-row">
                      <Box width="100%">
                        <Field name="files">
                          {(props) => {
                            const { field, form } = props;
                            const { setFieldValue } = form;
                            return (
                              <Dropzone
                                accept=".pdf,.docx,.txt,.jpeg,.jpg,.png"
                                minSize={0}
                                maxSize={1024 * 1024 * 20}
                                // maxSize={1024*1024*2}
                                {...field}
                                onDrop={(acceptedFiles, rejectedFiles) => {
                                  handleonDrop(acceptedFiles, rejectedFiles, setFieldValue, values);
                                }}
                              >
                                {({ getRootProps, getInputProps }) => (
                                  <section>
                                    <div {...getRootProps()}>
                                      <input {...getInputProps()} />
                                      <Box>
                                        <Typography variant="subtitle1" className="textarea-label">
                                          Files & Correspondence Upload
                                        </Typography>
                                      </Box>

                                      <Box
                                        display="flex"
                                        flexDirection="column"
                                        justifyContent="center"
                                        alignItems="center"
                                        border="1px solid #DCDADA;"
                                        padding="45px"
                                        margin="10px 0 30px"
                                      >
                                        <Box textAlign="center" className="fileupload-content">
                                          <img src='https://ganesh.arsgroup.com.au/static/upload.png' width="101px" height="73.49px" alt="upload icons" />
                                          <Typography className="upload-subtitle">Click to Upload</Typography>
                                          <Typography>Drag & Drop File Here</Typography>
                                        </Box>
                                      </Box>
                                    </div>
                                    <Box textAlign="left">
                                      <Paper>
                                        {values.files.map((file, i) => {
                                          return (
                                            <Box key={i}>
                                              <Typography display="inline">{`File Name: ${file.name}, File Size: ${(
                                                file.size / 1024
                                              ).toFixed(3)}KB`}</Typography>
                                              <IconButton
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  handleClose(i, setFieldValue, values);
                                                  const selectedFiles = [...values.files];
                                                  selectedFiles.splice(i, 1);
                                                  // setFieldValue('files', selectedFiles);
                                                  // if there are no files clear note too
                                                  if (selectedFiles?.length === 0)
                                                    setFieldValue('otherDetails[0].newOtherNotes[0].newOtherNote', '');
                                                }}
                                              >
                                                <ClearIcon />
                                              </IconButton>
                                            </Box>
                                          );
                                        })}
                                      </Paper>
                                    </Box>
                                  </section>
                                )}
                              </Dropzone>
                            );
                          }}
                        </Field>

                        <Typography
                          variant="subtitle1"
                          className={`textarea-label`}
                          style={{ display: formType === 'create' ? 'block' : 'none' }}
                        >
                          Correspondence Note:
                        </Typography>

                        <Field
                          name="otherDetails[0].newOtherNotes[0].newOtherNote"
                          style={{ display: formType === 'create' ? 'block' : 'none' }}
                        >
                          {(props) => {
                            const { field } = props;
                            return (
                              <div>
                                <TextareaAutosize rowsMin={4} rowsMax={4} className="text-field-custom" {...field} />
                              </div>
                            );
                          }}
                        </Field>
                      </Box>
                    </div>
                  </div>

                  <div className="form-category">
                    <div className="category-title-hold">
                      <Typography variant="subtitle1" component="subtitle1" style={{ color: '#FF7D24', fontSize: 20 }}>
                        Referral:{' '}
                      </Typography>

                      {/* {formType === 'create' && ( */}
                      <>
                        <Checkbox
                          checked={isClient}
                          color="primary"
                          onChange={(event) => {
                            setIsClient(event.target.checked);
                          }}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />{' '}
                        <span>Is User?</span>
                      </>
                      {/* )} */}
                    </div>
                    <div className="form-row">
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={6} lg={3}>
                          <Field name="referralCode">
                            {(props) => {
                              const { field, meta } = props;
                              return (
                                <div>
                                  <InputLabel>Referral code id</InputLabel>
                                  <Select
                                    {...field}
                                    error={
                                      // meta.touched &&
                                      meta.error ? true : false
                                    }
                                    helperText={
                                      // meta.touched &&
                                      meta.error ? <div>{meta.error}</div> : null
                                    }
                                  >
                                    <MenuItem value=" " disabled>
                                      Please Select
                                    </MenuItem>
                                    <MenuItem value="RF 01">RF 01</MenuItem>
                                    <MenuItem value="RF 02">RF 02</MenuItem>
                                    <MenuItem value="RF 03">RF 03</MenuItem>
                                    <MenuItem value="RF 04">RF 04</MenuItem>
                                    <MenuItem value="RF 05">RF 05</MenuItem>
                                  </Select>
                                </div>
                              );
                            }}
                          </Field>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                          <Field name="referralName">
                            {(props) => {
                              const { field, meta } = props;

                              return (
                                <div>
                                  {isClient ? (
                                    <Autocomplete
                                      name="referralName"
                                      id="referralName"
                                      className="text-field-custom"
                                      options={crmUsersList}
                                      getOptionLabel={({ firstName, lastName, email }) =>
                                        `${firstName || ''} ${lastName || ''} - ${email}`
                                      }
                                      value={referral}
                                      onChange={(_, newValue) => {
                                        setReferral(newValue);
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...field}
                                          error={
                                            // meta.touched &&
                                            meta.error ? true : false
                                          }
                                          {...params}
                                          inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'new-password'
                                          }}
                                          label="Name"
                                        />
                                      )}
                                    />
                                  ) : (
                                    <TextField
                                      autoComplete="offReferralPhone"
                                      label="Name"
                                      className="text-field-custom"
                                      {...field}
                                      error={
                                        // meta.touched &&
                                        meta.error ? true : false
                                      }
                                      helperText={
                                        // meta.touched &&
                                        meta.error ? <div>{meta.error}</div> : null
                                      }
                                    />
                                  )}
                                </div>
                              );
                            }}
                          </Field>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                          <Field name="referralEmail">
                            {(props) => {
                              const { field, meta } = props;
                              return (
                                <div>
                                  <TextField
                                    type="email"
                                    label={<>Email</>}
                                    className="text-field-custom"
                                    autoComplete="offReferralEmail"
                                    {...field}
                                    error={
                                      // meta.touched &&
                                      meta.error ? true : false
                                    }
                                    helperText={
                                      // meta.touched &&
                                      meta.error ? <div>{meta.error}</div> : null
                                    }
                                    disabled={isClient}
                                  />
                                </div>
                              );
                            }}
                          </Field>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                          <Field name="referralPhone">
                            {(props) => {
                              const { field, meta } = props;
                              return (
                                <div>
                                  <TextField
                                    autoComplete="offReferralPhone"
                                    label="Mobile"
                                    className="text-field-custom"
                                    {...field}
                                    error={
                                      // meta.touched &&
                                      meta.error ? true : false
                                    }
                                    helperText={
                                      // meta.touched &&
                                      meta.error ? <div>{meta.error}</div> : null
                                    }
                                    disabled={isClient}
                                  />
                                </div>
                              );
                            }}
                          </Field>
                        </Grid>
                      </Grid>
                    </div>
                    <div className="form-row">
                      <span className="subtitle-label color-primary">Address</span>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={6} lg={3}>
                          <Field name="referralStreetNo">
                            {(props) => {
                              const { field, meta } = props;
                              return (
                                <div>
                                  <TextField
                                    className="text-field-custom"
                                    autoComplete="offReferralStreetNo"
                                    {...field}
                                    label="Street No"
                                    error={
                                      // meta.touched &&
                                      meta.error ? true : false
                                    }
                                    helperText={
                                      // meta.touched &&
                                      meta.error ? <div>{meta.error}</div> : null
                                    }
                                    disabled={isClient}
                                  />
                                </div>
                              );
                            }}
                          </Field>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                          <Field name="referralStreetAddress">
                            {(props) => {
                              const { field, meta } = props;
                              return (
                                <div>
                                  <TextField
                                    className="text-field-custom"
                                    autoComplete="offReferralStreetAddress"
                                    {...field}
                                    label="Street Address"
                                    error={
                                      // meta.touched &&
                                      meta.error ? true : false
                                    }
                                    helperText={
                                      // meta.touched &&
                                      meta.error ? <div>{meta.error}</div> : null
                                    }
                                    disabled={isClient}
                                  />
                                </div>
                              );
                            }}
                          </Field>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                          <Field name="referralSuburb">
                            {(props) => {
                              const { field, meta } = props;
                              return (
                                <div>
                                  <TextField
                                    className="text-field-custom"
                                    autoComplete="offReferralSuburb"
                                    {...field}
                                    label="Suburb"
                                    error={
                                      // meta.touched &&
                                      meta.error ? true : false
                                    }
                                    helperText={
                                      // meta.touched &&
                                      meta.error ? <div>{meta.error}</div> : null
                                    }
                                    disabled={isClient}
                                  />
                                </div>
                              );
                            }}
                          </Field>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                          <Field name="referralState">
                            {(props) => {
                              const { field, meta } = props;
                              return (
                                <div>
                                  <TextField
                                    className="text-field-custom"
                                    autoComplete="offReferralState"
                                    {...field}
                                    label="State"
                                    error={
                                      // meta.touched &&
                                      meta.error ? true : false
                                    }
                                    helperText={
                                      // meta.touched &&
                                      meta.error ? <div>{meta.error}</div> : null
                                    }
                                    disabled={isClient}
                                  />
                                </div>
                              );
                            }}
                          </Field>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3} xl={2}>
                          <Field name="referralPostalCode">
                            {(props) => {
                              const { field, meta } = props;
                              return (
                                <div>
                                  <TextField
                                    className="text-field-custom"
                                    autoComplete="offReferralPostalCode"
                                    {...field}
                                    label="Postal Code"
                                    error={
                                      // meta.touched &&
                                      meta.error ? true : false
                                    }
                                    helperText={
                                      // meta.touched &&
                                      meta.error ? <div>{meta.error}</div> : null
                                    }
                                    disabled={isClient}
                                  />
                                </div>
                              );
                            }}
                          </Field>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                          <Field name="referralCountry">
                            {(props) => {
                              const { field, meta } = props;
                              return (
                                <div>
                                  <TextField
                                    className="text-field-custom"
                                    autoComplete="offReferralCountry"
                                    {...field}
                                    label="Country"
                                    error={
                                      // meta.touched &&
                                      meta.error ? true : false
                                    }
                                    helperText={
                                      // meta.touched &&
                                      meta.error ? <div>{meta.error}</div> : null
                                    }
                                    disabled={isClient}
                                  />
                                </div>
                              );
                            }}
                          </Field>
                        </Grid>
                      </Grid>
                    </div>
                    <div className="form-row">
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={6} lg={3}>
                          <Field name="referralLeadOpen">
                            {(props) => {
                              const { field, meta } = props;
                              return (
                                <div>
                                  <Autocomplete
                                    name="lereferralLeadOpen"
                                    id="leadOpen"
                                    className="text-field-custom"
                                    options={clientDetailAutoComplete}
                                    getOptionLabel={({ firstName, lastName, email }) =>
                                      firstName + ' ' + lastName + ' (' + email + ')'
                                    }
                                    value={leadOpen}
                                    onChange={(_, newValue) => {
                                      setleadOpen(newValue);
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...field}
                                        error={
                                          // meta.touched &&
                                          meta.error ? true : false
                                        }
                                        {...params}
                                        label="Lead Open"
                                      />
                                    )}
                                    get
                                  />
                                </div>
                              );
                            }}
                          </Field>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                          <Field name="lereferralLeadClose">
                            {(props) => {
                              const { field, meta } = props;

                              return (
                                <div>
                                  <Autocomplete
                                    id="leadClose"
                                    className="text-field-custom"
                                    options={clientDetailAutoComplete}
                                    getOptionLabel={({ firstName, lastName, email }) =>
                                      firstName + ' ' + lastName + ' (' + email + ')'
                                    }
                                    value={leadClose}
                                    onChange={(_, newValue) => {
                                      setleadClose(newValue);
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...field}
                                        error={
                                          // meta.touched &&
                                          meta.error ? true : false
                                        }
                                        {...params}
                                        label="Lead Close"
                                      />
                                    )}
                                  />
                                </div>
                              );
                            }}
                          </Field>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                  <FieldArray name="transactions">
                    {(props) => {
                      const { push, remove, form } = props;
                      const { values } = form;
                      const { transactions } = values;

                      return (
                        <div className="form-category">
                          <div className="transaction-title">
                            <span className="category-title">Transaction Details:</span>
                            <Button
                              className="add-button"
                              startIcon={<AddIcon />}
                              onClick={() => {
                                push({});
                                // setExpanded(`panel[${clientDetails?.transactionDetails?.length?clientDetails?.transactionDetails?.length:0}]`)
                                setExpanded(`panel[${transactions?.length ? transactions?.length : 0}]`);
                              }}
                            >
                              Add New Transaction
                            </Button>
                          </div>

                          <div className="transaction-feild-wrapper ">
                            {transactions &&
                              transactions.map((fieldName, index) => {
                                return (
                                  <Accordion
                                    className="transaction-feildset"
                                    square
                                    expanded={expanded === `panel[${index}]`}
                                    onChange={togglePanelOpen(`panel[${index}]`)}
                                  >
                                    <AccordionSummary
                                      aria-controls={`panel-header${index}`}
                                      id={`panel-header${index}`}
                                    >
                                      <h3>Transaction {index + 1}</h3>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                      <div class="form-row">
                                        <Grid container spacing={3}>
                                          <Grid item xs={12} lg={4}>
                                            <Field name={`transactions[${index}].propertyLotAddress`}>
                                              {(props) => {
                                                const { field, meta } = props;
                                                return (
                                                  <div>
                                                    <TextField
                                                      label={
                                                        <>
                                                          Property Lot Address{' '}
                                                          {fieldName?.upTo ||
                                                          fieldName?.propertyType ||
                                                          fieldName?.purchaseStatus ||
                                                          fieldName?.ooInvst ||
                                                          fieldName?.resultNotes?.[0]?.note ? (
                                                            <span style={{ color: 'red' }}>*</span>
                                                          ) : null}
                                                        </>
                                                      }
                                                      autoComplete="offPropertyLotAddress"
                                                      className="text-field-custom"
                                                      {...field}
                                                      error={
                                                        // meta.touched &&
                                                        meta.error ? true : false
                                                      }
                                                      helperText={
                                                        // meta.touched &&
                                                        meta.error ? <div>{meta.error}</div> : null
                                                      }
                                                    />
                                                  </div>
                                                );
                                              }}
                                            </Field>
                                          </Grid>
                                          <Grid item xs={12} lg={8} xl={6} className="purchase-price-range">
                                            <span className="subtitle-label p-0">Purchase Price Range</span>
                                            <Grid container spacing={0}>
                                              <Grid item xs={6}>
                                                <Field name={`transactions[${index}].upTo`}>
                                                  {(props) => {
                                                    const { field, meta } = props;
                                                    return (
                                                      <div>
                                                        <Grid container spacing={1}>
                                                          <Grid item xs={3} md={2}>
                                                            <Typography variant="subtitle2">Upto</Typography>
                                                          </Grid>

                                                          <Grid item xs={9}>
                                                            <NumberFormat
                                                              thousandSeparator={true}
                                                              prefix={'$'}
                                                              customInput={TextField}
                                                              {...field}
                                                              error={
                                                                // meta.touched &&
                                                                meta.error ? true : false
                                                              }
                                                              helperText={
                                                                // meta.touched &&
                                                                meta.error ? <div>{meta.error}</div> : null
                                                              }
                                                              onChange={(e) => {
                                                                const evt = { ...e };
                                                                if (e.target.value) {
                                                                  evt.target.value = String(
                                                                    Number(
                                                                      e.target.value.split('$')[1].replaceAll(/,/gi, '')
                                                                    )
                                                                  );
                                                                }
                                                                handleChange(evt);
                                                              }}
                                                            />
                                                          </Grid>
                                                        </Grid>
                                                      </div>
                                                    );
                                                  }}
                                                </Field>
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </div>
                                      <div className="form-row">
                                        <Grid container spacing={3}>
                                          <Grid item xs={12}>
                                            <Field name={`transactions[${index}].propertyType`}>
                                              {(props) => {
                                                const { field, meta } = props;
                                                return (
                                                  <div>
                                                    <span className="subtitle-label p-0">Property Type</span>
                                                    <RadioGroup
                                                      aria-label="type"
                                                      name="type"
                                                      {...field}
                                                      error={
                                                        // meta.touched &&
                                                        meta.error ? true : false
                                                      }
                                                      helperText={
                                                        // meta.touched &&
                                                        meta.error ? <div>{meta.error}</div> : null
                                                      }
                                                    >
                                                      <Grid container spacing={2}>
                                                        <Grid item xs={6} lg={2}>
                                                          <FormControlLabel
                                                            value="land_and_house"
                                                            control={<Radio color="primary" />}
                                                            label="House & Land"
                                                          />
                                                        </Grid>
                                                        <Grid item xs={6} lg={2}>
                                                          <FormControlLabel
                                                            value="town_home"
                                                            control={<Radio color="primary" />}
                                                            label="Town House"
                                                          />
                                                        </Grid>
                                                        <Grid item xs={6} lg={3}>
                                                          <FormControlLabel
                                                            value="units"
                                                            control={<Radio color="primary" />}
                                                            label="Units/ Apartment"
                                                          />
                                                        </Grid>
                                                        <Grid item xs={6} lg={2}>
                                                          <FormControlLabel
                                                            value="all_kinds"
                                                            control={<Radio color="primary" />}
                                                            label="All Kinds"
                                                          />
                                                        </Grid>
                                                        <Grid item xs={6} lg={2}>
                                                          <FormControlLabel
                                                            value="Others"
                                                            control={<Radio color="primary" />}
                                                            label="Others"
                                                          />
                                                        </Grid>
                                                      </Grid>
                                                    </RadioGroup>
                                                  </div>
                                                );
                                              }}
                                            </Field>
                                          </Grid>
                                        </Grid>
                                      </div>
                                      <div className="form-row">
                                        <Grid item xs={12}>
                                          <Field name={`transactions[${index}].purchaseStatus`}>
                                            {(props) => {
                                              const { field, meta } = props;
                                              return (
                                                <div>
                                                  <span className="subtitle-label">Purchase Status</span>
                                                  <RadioGroup
                                                    aria-label="status"
                                                    name="status"
                                                    {...field}
                                                    error={
                                                      // meta.touched &&
                                                      meta.error ? true : false
                                                    }
                                                    helperText={
                                                      // meta.touched &&
                                                      meta.error ? <div>{meta.error}</div> : null
                                                    }
                                                  >
                                                    <Grid container spacing={2}>
                                                      <Grid item xs={6} lg={2}>
                                                        <FormControlLabel
                                                          value="raw_lead"
                                                          control={<Radio color="primary" />}
                                                          label="Raw Lead"
                                                        />
                                                      </Grid>
                                                      <Grid item xs={6} lg={2}>
                                                        <FormControlLabel
                                                          value="qualified"
                                                          control={<Radio color="primary" />}
                                                          label="Qualified"
                                                        />
                                                      </Grid>
                                                      <Grid item xs={6} lg={2}>
                                                        <FormControlLabel
                                                          value="negotiating"
                                                          control={<Radio color="primary" />}
                                                          label="Negotiating"
                                                        />
                                                      </Grid>
                                                      <Grid item xs={6} lg={2}>
                                                        <FormControlLabel
                                                          value="win"
                                                          control={<Radio color="primary" />}
                                                          label="Win"
                                                        />
                                                      </Grid>
                                                      <Grid item xs={6} lg={2}>
                                                        <FormControlLabel
                                                          value="lost"
                                                          control={<Radio color="primary" />}
                                                          label="Lost"
                                                        />
                                                      </Grid>
                                                      <Grid item xs={6} lg={3}>
                                                        <FormControlLabel
                                                          value="follow_up"
                                                          control={<Radio color="primary" />}
                                                          label="Follow up in future"
                                                        />
                                                      </Grid>
                                                      <Grid item xs={6} lg={1}>
                                                        <FormControlLabel
                                                          value="Others"
                                                          control={<Radio color="primary" />}
                                                          label="Others"
                                                        />
                                                      </Grid>
                                                    </Grid>
                                                  </RadioGroup>
                                                </div>
                                              );
                                            }}
                                          </Field>
                                        </Grid>
                                      </div>
                                      <div className="form-row">
                                        <Grid item xs={12} md={8} lg={4} xl={4}>
                                          <Field name={`transactions[${index}].ooInvst`}>
                                            {(props) => {
                                              const { field, meta } = props;
                                              return (
                                                <div>
                                                  <InputLabel>OO/INVST</InputLabel>
                                                  <Select
                                                    {...field}
                                                    error={
                                                      // meta.touched &&
                                                      meta.error ? true : false
                                                    }
                                                    helperText={
                                                      // meta.touched &&
                                                      meta.error ? <div>{meta.error}</div> : null
                                                    }
                                                  >
                                                    <MenuItem value="" disabled>
                                                      Please Select
                                                    </MenuItem>
                                                    <MenuItem value="OO">OO - Owner Occupier</MenuItem>
                                                    <MenuItem value="INVST">INVST - Investment Property</MenuItem>
                                                    <MenuItem value="OTHERS">Others</MenuItem>
                                                  </Select>
                                                </div>
                                              );
                                            }}
                                          </Field>
                                        </Grid>
                                      </div>
                                      <div className="form-row">
                                        <Grid item xs={12}>
                                          <Field name={`transactions[${index}].resultNotes[0].note`}>
                                            {(props) => {
                                              const { field, meta } = props;
                                              return (
                                                <div>
                                                  <Typography variant="subtitle1" className="textarea-label">
                                                    Result Notes:
                                                  </Typography>
                                                  <TextareaAutosize
                                                    rowsMin={4}
                                                    rowsMax={4}
                                                    className="text-field-custom"
                                                    {...field}
                                                    error={
                                                      // meta.touched &&
                                                      meta.error ? true : false
                                                    }
                                                    helperText={
                                                      // meta.touched &&
                                                      meta.error ? <div>{meta.error}</div> : null
                                                    }
                                                  />
                                                </div>
                                              );
                                            }}
                                          </Field>
                                        </Grid>
                                      </div>

                                      {/* <div
                                        className="form-category"
                                        style={{ display: formType === 'create' ? 'block' : 'none' }}
                                      >
                                        <div className="form-row">
                                          <Box width="100%">
                                            <Field name={`files[${index}]`}>
                                              {(props) => {
                                                const { field, form } = props;
                                                const { setFieldValue } = form;
                                                return (
                                                  <Dropzone
                                                    accept=".pdf,.docx,.txt"
                                                    minSize={0}
                                                    maxSize={1024*1024*}
                                                    // maxSize={1024*1024*2}
                                                    {...field}
                                                    onDrop={(acceptedFiles, rejectedFiles) => {
                                                      handleonDrop(
                                                        acceptedFiles,
                                                        rejectedFiles,
                                                        setFieldValue,
                                                        values,
                                                        index
                                                      );
                                                    }}
                                                  >
                                                    {({ getRootProps, getInputProps }) => (
                                                      <section>
                                                        <div {...getRootProps()}>
                                                          <input {...getInputProps()} />
                                                          <Box>
                                                            <Typography variant="subtitle1" className="textarea-label">
                                                              Files & Correspondence Upload
                                                            </Typography>
                                                          </Box>

                                                          <Box
                                                            display="flex"
                                                            flexDirection="column"
                                                            justifyContent="center"
                                                            alignItems="center"
                                                            border="1px solid #DCDADA;"
                                                            padding="45px"
                                                            margin="10px 0 30px"
                                                          >
                                                            <Box textAlign="center" className="fileupload-content">
                                                              <img
                                                                src="https://i.ibb.co/BC3gcFt/upload.png"
                                                                width="101px"
                                                                height="73.49px"
                                                                alt=""
                                                              />
                                                              <Typography className="upload-subtitle">
                                                                Click to Upload
                                                              </Typography>
                                                              <Typography>Drag & Drop File Here</Typography>
                                                            </Box>
                                                          </Box>
                                                        </div>
                                                        <Box textAlign="left">
                                                          <Paper>
                                                            {values?.files[index]?.map((file, i) => {
                                                              return (
                                                                <Box key={i}>
                                                                  <Typography display="inline">{`File Name: ${
                                                                    file.name
                                                                  }, File Size: ${(file.size / 1024).toFixed(
                                                                    3
                                                                  )}KB`}</Typography>
                                                                  <IconButton
                                                                    onClick={(e) => {
                                                                      e.preventDefault();
                                                                      handleClose(index, i, setFieldValue, values);
                                                                    }}
                                                                  >
                                                                    <ClearIcon />
                                                                  </IconButton>
                                                                </Box>
                                                              );
                                                            })}
                                                          </Paper>
                                                        </Box>
                                                      </section>
                                                    )}
                                                  </Dropzone>
                                                );
                                              }}
                                            </Field>

                                            <Typography
                                              variant="subtitle1"
                                              className="textarea-label"
                                              style={{ display: formType === 'create' ? 'block' : 'none' }}
                                            >
                                              Correspondence Note:
                                            </Typography>

                                            <Field
                                              name={`transactions[${index}].correspondingNote[0].otherNotes[0].otherNote`}
                                              style={{ display: formType === 'create' ? 'block' : 'none' }}
                                            >
                                              {(props) => {
                                                const { field } = props;
                                                return (
                                                  <div>
                                                    <TextareaAutosize
                                                      rowsMin={4}
                                                      rowsMax={4}
                                                      className="text-field-custom"
                                                      {...field}
                                                    />
                                                  </div>
                                                );
                                              }}
                                            </Field>
                                          </Box>
                                        </div>
                                      </div> */}

                                      <div className="form-row">
                                        <div style={{ zIndex: '1000', display: 'inline-block' }}>
                                          <Dialog
                                            open={openRemoveTransaction}
                                            onClose={handleCloseRemoveTransaction}
                                            aria-labelledby="alert-dialog-title"
                                            aria-describedby="alert-dialog-description"
                                          >
                                            <DialogTitle id="alert-dialog-title">
                                              Are you sure want to Delete?
                                            </DialogTitle>
                                            <DialogContent>
                                              <DialogContentText id="alert-dialog-description">
                                                <span className="text-danger"> Alert!!! </span> Are you sure you want to
                                                delete transaction?
                                              </DialogContentText>
                                            </DialogContent>
                                            <DialogActions className="alert-btn-holder">
                                              <Button
                                                onClick={() => {
                                                  remove(index);
                                                  handleCloseRemoveTransaction();
                                                }}
                                                color="primary"
                                                variant="contained"
                                                className="delete-button"
                                              >
                                                Delete
                                              </Button>
                                              <Button
                                                onClick={handleCloseRemoveTransaction}
                                                color="primary"
                                                variant="outlined"
                                                // className="cancel-button"
                                              >
                                                Cancel
                                              </Button>
                                            </DialogActions>
                                          </Dialog>
                                        </div>
                                        {/* <AreYouSurePopUp
                                          variant="contained"
                                          buttonName="Remove"
                                          popUpHeading="Are you sure you want to clear everything"
                                          popUpDetail="Alert!!! Are you sure you want to delete transaction?"
                                          className="remove-btn"
                                          buttonIcon={<CloseIcon />}
                                          onAgreeClick={remove(index)}
                                        /> */}
                                        <Button
                                          type="button"
                                          variant="contained"
                                          color="secondary"
                                          className="remove-btn"
                                          onClick={handleClickOpenRemoveTransaction}
                                          startIcon={<CloseIcon />}
                                        >
                                          Remove
                                        </Button>
                                      </div>
                                    </AccordionDetails>
                                  </Accordion>
                                );
                              })}
                          </div>
                        </div>
                      );
                    }}
                  </FieldArray>

                  {formType === 'create' && (
                    <div className="form-category">
                      <span className="category-title">Note a follow up call to client:</span>
                      <div className="form-row">
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={5} lg={3}>
                            <Field name="clientFollowUpNotes[0].clientFollowUpDate">
                              {(props) => {
                                const { form } = props;
                                return (
                                  <div>
                                    <div className="follow-up-feild-create">
                                      <label className="MuiInputLabel-root">Date</label>
                                      <DatePicker
                                        showTimeInput
                                        dateFormat="dd/MM/yyyy h:mm aa"
                                        selected={
                                          values?.clientFollowUpNotes[0]?.clientFollowUpDate
                                            ? new Date(values?.clientFollowUpNotes[0]?.clientFollowUpDate)
                                            : null
                                        }
                                        onChange={(date) => {
                                          // console.log("Date: ", date.toUTCString());
                                          form.setFieldValue(
                                            'clientFollowUpNotes[0].clientFollowUpDate',
                                            date?.toISOString()
                                          );
                                        }}
                                        placeholderText="Please Select Date"
                                      />
                                    </div>
                                    {/* <TextField type="date" label="Date" className="calender-feild" {...field} /> */}
                                  </div>
                                );
                              }}
                            </Field>
                          </Grid>
                          <Grid item xs={12} md={7} lg={9}>
                            <Field name="clientFollowUpNotes[0].followUpNote">
                              {(props) => {
                                const { field, meta } = props;
                                return (
                                  <div>
                                    <InputLabel>
                                      <span
                                        className={`${
                                          // meta.touched &&
                                          meta.error && 'color-red'
                                        }`}
                                      >
                                        Follow Up Note:{' '}
                                      </span>
                                      {values.clientFollowUpNotes[0].clientFollowUpDate !== '' ? (
                                        <span className="color-red">*</span>
                                      ) : null}
                                    </InputLabel>

                                    <TextareaAutosize
                                      rowsMin={1}
                                      rowsMax={1}
                                      error={true}
                                      label="Follow Up Note"
                                      {...field}
                                      className={`${
                                        // meta.touched &&
                                        meta.error && 'border-red'
                                      }`}
                                    />

                                    <FormHelperText
                                      error={
                                        // meta.touched &&
                                        meta.error ? true : false
                                      }
                                    >
                                      {' '}
                                      {
                                        // meta.touched &&
                                        meta.error ? <div>{meta.error}</div> : null
                                      }{' '}
                                    </FormHelperText>
                                  </div>
                                );
                              }}
                            </Field>
                          </Grid>
                          <Grid item xs={12} md={6} lg={3}>
                            <Field name="clientFollowUpNotes[0].assignedTo">
                              {(props) => {
                                const { field, meta } = props;

                                return (
                                  <div>
                                    <InputLabel>
                                      <span
                                        className={`${
                                          // meta.touched &&
                                          meta.error && 'color-red'
                                        }`}
                                      >
                                        Assigned To:
                                      </span>
                                      {values.clientFollowUpNotes[0].clientFollowUpDate !== '' ? (
                                        <span className="color-red">*</span>
                                      ) : null}
                                    </InputLabel>
                                    <Autocomplete
                                      id="Assigned To"
                                      className="text-field-custom"
                                      options={clientDetailAutoComplete}
                                      getOptionLabel={({ firstName, lastName, email }) =>
                                        firstName + ' ' + lastName + ' (' + email + ')'
                                      }
                                      value={followUpAssigendTo}
                                      renderInput={(params) => <TextField {...params} {...field} />}
                                      onChange={(e, value) => {
                                        setFollowUpAssignedTo(value);
                                      }}
                                    />
                                    <FormHelperText
                                      error={
                                        // meta.touched &&
                                        meta.error ? true : false
                                      }
                                    >
                                      {
                                        // meta.touched &&
                                        meta.error ? <div>{meta.error}</div> : null
                                      }{' '}
                                    </FormHelperText>
                                  </div>
                                );
                              }}
                            </Field>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  )}
                  <div className="form-buttons">
                    <div className="form-row">
                      {formType === 'update' ? (
                        <AreYouSurePopUp
                          buttonColor="primary"
                          buttonName="Update"
                          popUpHeading="Are you sure want to update?"
                          formName="myform"
                          buttonType="submit"
                          popUpDetail=" if you update this profile then it might cause a problem for the selected user.
                           You will not be able to see the previous details. Are you sure want to update?"
                          isSubmitting={isSubmitting}
                          buttonIcon={<CheckIcon />}
                          validationError={Object.keys(formik.errors).length !== 0}
                          yesButtonName="Update"
                        />
                      ) : (
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          startIcon={<CheckIcon />}
                          disabled={isSubmitting}
                        >
                          Submit
                        </Button>
                      )}
                      <div className="cancel-button-holder">
                        <AreYouSurePopUp
                          variant="outlined"
                          buttonName="Clear"
                          popUpHeading="Are you sure you want to clear everything"
                          popUpDetail="Are you sure you want to clear everything?"
                          formName="myform"
                          buttonType="reset"
                          className="cancel-button"
                          buttonIcon={<CloseIcon />}
                          onClickRemoveData={formType !== 'update' ? clearDataOfAutoComplet : null}
                          yesButtonName="Clear"
                        />
                      </div>
                    </div>
                  </div>
                  <PopupDialog
                    openAlert={openAlert}
                    handleCloseAlert={handleCloseAlert}
                    onlyForUpdate="Alert!!! "
                    message={DUPLICATE_NAME_ERROR}
                    onSubmit={() => {
                      setCreateWithoutValidation(true);
                      handleSubmit();
                      setOpenAlert(false);
                    }}
                  />
                </Form>
              );
            }}
          </Formik>
        </div>
      </Box>
    </ThemeProvider>
  );
};

export default ClientProfileFormTemplate;
