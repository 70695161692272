import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { IconButton, ThemeProvider } from '@material-ui/core';
import { Avatar } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import CloseIcon from '@material-ui/icons/Close';

import theme from '../../styles/theme';
import profilePic from '../../assets/images/user.JPG';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { logout, passwordUpdate } from '../../store/actions/authAction';
import { ConfirmPassword, PasswordValidation } from '../validations/YupValidations';
import Message from '../message/Message';
import { useHistory } from 'react-router-dom';
// import { AuthRole } from '../common/AuthRole';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';

const validationSchema = yup.object({
  currentPassword: '',

  newPassword: PasswordValidation(),
  confirmPassword: ConfirmPassword()
});

const Sidebar = ({ auth }) => {
  const dispatch = useDispatch();

  const [userRole, setUserRole] = useState({});

  useEffect(() => {
    if (userRole !== {}) {
      setUserRole(auth.accessLevel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  const history = useHistory();
  const formik = useFormik({
    initialValues: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: ''
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      const passwordValue = {
        oldPassword: values.currentPassword,
        newPassword: values.newPassword
      };
      // alert(JSON.stringify(values, null, 2));
      dispatch(passwordUpdate(passwordValue, resetForm));
    }
  });

  const [values, setValues] = React.useState({
    password: '',
    showPassword: false,
    showPassword1: false,
    showPassword2: false
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  const handleClickShowPassword1 = () => {
    setValues({ ...values, showPassword1: !values.showPassword1 });
  };
  const handleClickShowPassword2 = () => {
    setValues({ ...values, showPassword2: !values.showPassword2 });
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    // menuCloseSideBar();
    // setAnchorEl(null);
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setAnchorEl(null);
    setOpen(false);
    menuCloseSideBar();
  };
  // const handleCancel = () => {
  //   setOpen(false);
  //   menuCloseSideBar();
  // };
  const toogleFunctions = () => {
    let body = document.querySelector('body');
    body.classList.toggle('sidebar-toogle');
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleViewAllUsers = () => {
    setAnchorEl(null);
    history.push('/view-users');
  };
  const handleCreateUser = () => {
    setAnchorEl(null);
    history.push('/crm-user');
  };
  const menuCloseSideBar = () => {
    let body = document.querySelector('body');
    body.classList.remove('sidebar-toogle');
  };
  const handleClickUpdate = () => {
    setAnchorEl(null);
    history.push(`/user-detail/${auth._id}`);
  };
  const handleCreateCategory = () => {
    setAnchorEl(null);
    history.push('/create-category');
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
  };
  const authReducer = useSelector((state) => state.authReducer);

  return (
    <ThemeProvider theme={theme}>
      {/* <Backdrop open={authReducer.loading}>
        <CircularProgress color="inherit" />
      </Backdrop> */}
      <Message status={authReducer.status} severity={authReducer.severity} message={authReducer.message} />
      <div className="side-nav">
        <div className="sidebar-toggle-wrapper-desktop">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a className="opener icon-toggle-desktop" onClick={toogleFunctions}>
            <span>menu</span>
          </a>
        </div>
        <div className="top-menu">
          <div className="search-sm">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a href="#">
              <span className="icon-search"></span>
            </a>
          </div>

          <div className="profile-picture">
            <div aria-controls="user-menu" aria-haspopup="true" onClick={handleClick}>
              <div className="image-holder bg-image">
                <Avatar alt="user" src={profilePic} />
              </div>
            </div>
            <Menu id="user-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
              {userRole === 'SUPER_ADMIN' || userRole === 'ADMIN' || userRole === 'USER' ? (
                <MenuItem
                  onClick={() => {
                    handleClickUpdate(setAnchorEl);
                    menuCloseSideBar();
                  }}
                >
                  My Profile
                </MenuItem>
              ) : null}

              <MenuItem
                onClick={() => {
                  handleClickOpen(setAnchorEl);
                }}
              >
                Update Password
              </MenuItem>
              {userRole === 'SUPER_ADMIN' || userRole === 'ADMIN' ? (
                <MenuItem
                  onClick={() => {
                    handleViewAllUsers(setAnchorEl);
                    menuCloseSideBar();
                  }}
                >
                  View Users
                </MenuItem>
              ) : null}
              {userRole === 'SUPER_ADMIN' || userRole === 'ADMIN' ? (
                <MenuItem
                  onClick={() => {
                    menuCloseSideBar();
                    handleCreateUser(setAnchorEl);
                  }}
                >
                  Create User
                </MenuItem>
              ) : null}
              {userRole === 'SUPER_ADMIN' || userRole === 'ADMIN' ? (
                <MenuItem
                  onClick={() => {
                    menuCloseSideBar();
                    handleCreateCategory(setAnchorEl);
                  }}
                >
                  Create Category
                </MenuItem>
              ) : null}
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </div>
        </div>
        <div className="nav-list">
          <div className="list">
            <NavLink onClick={() => menuCloseSideBar()} exact to="/dashboard" activeClassName="list-active">
              <div className="icon-holder">
                <span className="icon-dashboard nav-icon"></span>
              </div>
              <span className="nav-title">Dashboard</span>
            </NavLink>
          </div>
          <div className="list">
            <NavLink
              exact
              onClick={() => menuCloseSideBar()}
              to="/client-profile/view"
              activeClassName="list-active"
              isActive={(_, location) => {
                if (location.pathname.includes('client-profile')) {
                  return true;
                }

                return false;
              }}
            >
              <div className="icon-holder">
                <span className="icon-profile nav-icon"></span>
              </div>
              <span className="nav-title">Client profile</span>
            </NavLink>
          </div>
          <div className="list">
            <NavLink onClick={() => menuCloseSideBar()} exact to="/event-management" activeClassName="list-active">
              <div className="icon-holder">
                <span className="icon-event nav-icon"></span>
              </div>
              <span className="nav-title">
                event
                <br /> management
              </span>
            </NavLink>
          </div>
          <div className="list">
            <NavLink onClick={() => menuCloseSideBar()} exact to="/email-management" activeClassName="list-active">
              <div className="icon-holder">
                <span className="icon-envelope nav-icon"></span>
              </div>
              <span className="nav-title">
                email
                <br /> management
              </span>
            </NavLink>
          </div>
        </div>
      </div>
      <Dialog open={open} onClose={handleCloseDialog} aria-labelledby="form-dialog-title">
        <div className="Dialog-box-wrapper">
          <h3>Update Password</h3>
          <IconButton className="cross-button" aria-label="close" onClick={handleCloseDialog}>
            <CloseIcon />
          </IconButton>
          <form className="update-password-form" onSubmit={formik.handleSubmit}>
            <TextField
              name="currentPassword"
              label=" Current Password"
              type={values.showPassword ? 'text' : 'password'}
              value={formik.values.currentPassword}
              onChange={formik.handleChange}
              error={formik.touched.currentPassword && Boolean(formik.errors.currentPassword)}
              helperText={formik.touched.currentPassword && formik.errors.currentPassword}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword}>
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <TextField
              name="newPassword"
              label=" New Password"
              type={values.showPassword1 ? 'text' : 'password'}
              value={formik.values.newPassword}
              onChange={formik.handleChange}
              error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
              helperText={formik.touched.newPassword && formik.errors.newPassword}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword1}>
                      {values.showPassword1 ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <TextField
              name="confirmPassword"
              label="Confirm Password"
              type={values.showPassword2 ? 'text' : 'password'}
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
              helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword2}>
                      {values.showPassword2 ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />

            <div className="form-buttons">
              <Button type="submit" variant="contained" color="primary">
                Update
              </Button>
              <Button
                type="reset"
                // variant="outlined"
                // color="primary"
                className="cancel-button"
                onClick={() => {
                  formik.resetForm();
                }}
              >
                Clear
              </Button>
            </div>
          </form>
        </div>
      </Dialog>
    </ThemeProvider>
  );
};

export default Sidebar;
