import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { fetchSearchData } from "../../store/actions/authAction";
import { CircularProgress } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';


const Search = ({ userId }) => {
  const dispatch = useDispatch()
  const authReducer = useSelector(state => state.authReducer)

  const [key, setKey] = useState('')
  const [data, setData] = useState(authReducer?.searchData)

  const handleSearchChange = (e) => {
    setKey(e.target.value)
  }

  useEffect(() => {
    dispatch(fetchSearchData())
  }, [dispatch])

  useEffect(() => {
    // /MY_PROFILE
    const searchDataExtended = authReducer?.searchData?.map(data => {
      if (userId) {
        if (data?.url === '/MY_PROFILE') {
          return { _id: data._id, label: data?.label, url: `/user-detail/${userId}` }
        }
        if (data?.url === '/UPDATE_MY_PROFILE') {
          return { _id: data._id, label: data?.label, url: `/update-user/${userId}` }
        }
      }
      return data
    })
    // setData(authReducer?.searchData)
    setData(searchDataExtended)
    if (key) {
      const filteredData = searchDataExtended?.filter(d => d.label.toLowerCase().includes(key?.trim()?.toLowerCase()))
      // console.log(filteredData);
      setData(filteredData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(authReducer?.searchData), key])

  return (
    <div className="searchFormDiv">
      <form action="#" className="top-search-form">
        <div className="form-control">
          <input type="search" placeholder="search" onChange={handleSearchChange} />
          <span className="icon-search" />
          <span className="icon-arrow-down" />
        </div>
      </form>
      {/* show list when user types in the input */}
      {key && <div className='searchContainer'>
        {authReducer.searchDataLoading && <CircularProgress size={20}
          style={{
            position: 'absolute',
            top: 2,
            right: 2
          }}
        />}

        {data.length === 0 &&
          <span className="no-data-label">
            No data found.
          </span>
        }
        {data?.map(data => {
          return <Link
            key={data?._id}
            to={data?.url}
            onClick={() => {
              setKey('')
            }}
            className="link"
          >
            {data?.label} <ArrowForwardIcon className="arrow" />
          </Link>
        })}
      </div>}
    </div>
  )
}

export default Search
