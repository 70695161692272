import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({ component: Component, showFor, auth, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        auth && auth.accessLevel && showFor.indexOf(auth.accessLevel) !== -1 ? (
          <Component {...props} auth={auth} />
        ) : (
          <Redirect to="/dashboard" />
        )
      }
    />
  );
};

export default PrivateRoute;
