import CategoryService from '../../services/category-service';
import {
  BULK_EMAIL_SEND_FAILED,
  BULK_EMAIL_SEND_PROCESSING,
  BULK_EMAIL_SEND_RESET,
  BULK_EMAIL_SEND_SUCCESS,
  CREATE_CATEGORY_FAILED,
  CREATE_CATEGORY_PROCESSING,
  CREATE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_FAILED,
  DELETE_CATEGORY_PROCESSING,
  DELETE_CATEGORY_SUCCESS,
  GET_CATEGORY_FAILED,
  GET_CATEGORY_PROCESSING,
  GET_CATEGORY_SUCCESS,
  RESET_MESSAGE_STATE,
  UPDATE_CATEGORY_FAILED,
  UPDATE_CATEGORY_PROCESSING,
  UPDATE_CATEGORY_SUCCESS
} from '../types/types';

export const createCategoryAction = (category, onSubmitProps, history, setCheckValue) => (dispatch) => {
  dispatch({
    type: CREATE_CATEGORY_PROCESSING
  });

  return CategoryService.createCategory(category)
    .then(({ status, data }) => {
      if (status === 200) {
        dispatch({
          type: CREATE_CATEGORY_SUCCESS,
          payload: data
        });
        onSubmitProps.resetForm();
        setCheckValue([]);
        // history.push("/event-management")
      } else {
        dispatch({
          type: CREATE_CATEGORY_FAILED,
          payload: 'Failed'
        });
      }
    })
    .catch((e) => {
      dispatch({
        type: CREATE_CATEGORY_FAILED,
        payload: e.response.data.message
      });
    })
    .finally(() => {
      setTimeout(() => {
        // dispatch({ type: RESET_MESSAGE_STATE });
      }, 4000);
    });
};
export const updateCategoryAction = (category, onSubmitProps, history, setCheckValue, paramsId) => (dispatch) => {
  dispatch({
    type: UPDATE_CATEGORY_PROCESSING
  });

  return CategoryService.updateCategory(category, paramsId)
    .then(({ status, data }) => {
      if (status === 200) {
        dispatch({
          type: UPDATE_CATEGORY_SUCCESS,
          payload: data
        });
        onSubmitProps.resetForm();
        setCheckValue([]);
        history.push('/email-management');
      } else {
        dispatch({
          type: UPDATE_CATEGORY_FAILED,
          payload: 'Failed'
        });
      }
    })
    .catch((e) => {
      dispatch({
        type: UPDATE_CATEGORY_FAILED,
        payload: e.response.data.message
      });
    })
    .finally(() => {
      setTimeout(() => {
        dispatch({ type: RESET_MESSAGE_STATE });
      }, 4000);
    });
};

export const getCategoryAction = () => (dispatch) => {
  dispatch({
    type: GET_CATEGORY_PROCESSING
  });

  return CategoryService.getCategory()
    .then(({ status, data }) => {
      if (status === 200) {
        dispatch({
          type: GET_CATEGORY_SUCCESS,
          payload: data
        });
        dispatch(getCategoryAction);
      } else {
        dispatch({
          type: GET_CATEGORY_FAILED,
          payload: 'Failed'
        });
      }
    })
    .catch((e) => {
      dispatch({
        type: GET_CATEGORY_FAILED,
        payload: 'Failed'
      });
    });
};

export const deleteCategoryAction = (id) => (dispatch) => {
  dispatch({
    type: DELETE_CATEGORY_PROCESSING
  });

  return CategoryService.deleteCategory(id)
    .then(({ status, data }) => {
      if (status === 200) {
        dispatch(getCategoryAction);
        dispatch({
          type: DELETE_CATEGORY_SUCCESS,
          payload: data
        });
      } else {
        dispatch({
          type: DELETE_CATEGORY_FAILED,
          payload: 'Failed'
        });
      }
    })
    .catch((e) => {
      dispatch({
        type: DELETE_CATEGORY_FAILED,
        payload: e.response.data.message
      });
    })
    .finally(() => {
      setTimeout(() => {
        dispatch({ type: RESET_MESSAGE_STATE });
      }, 4000);
    });
};

export const resetCategoryState = () => (dispatch) => {
  dispatch({ type: RESET_MESSAGE_STATE });
};

export const bulkEmailSendAction =
  (emailValues, onSubmitProps, setEmailValue, setConvertedContent, setEditorState, EditorState, setSelectedTemplate, setMyEmailContent) =>
    (dispatch) => {
      dispatch({
        type: BULK_EMAIL_SEND_PROCESSING
      });

      return CategoryService.bulkEmailSend(emailValues)
        .then(({ status, data }) => {
          if (status === 200) {
            dispatch({
              type: BULK_EMAIL_SEND_SUCCESS,
              payload: data
            });
            onSubmitProps.resetForm();
            setEmailValue([]);
            setConvertedContent('');
            setEditorState(EditorState.createEmpty());
            setSelectedTemplate(null);
            setMyEmailContent('')
            // toast.info(data);
          } else {
            dispatch({
              type: BULK_EMAIL_SEND_FAILED,
              payload: 'Failed'
            });
          }
        })
        .catch((e) => {
          dispatch({
            type: BULK_EMAIL_SEND_FAILED,
            payload: 'Failed'
          });
        }).finally(() => {
          setTimeout(() => {
            dispatch({
              type: BULK_EMAIL_SEND_RESET
            });
          }, 4000);
        });
    };
