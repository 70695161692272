import {
  FETCH_TEMPLATES_PROCESSING,
  FETCH_TEMPLATE_FAILED,
  FETCH_TEMPLATE_SUCCESS
} from '../types/types';

const initialState = {
  loading: false,
  templates: [],
};

export const templateReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TEMPLATES_PROCESSING:
      return {
        ...state,
        loading: true,
      };
    case FETCH_TEMPLATE_FAILED:
      return {
        ...state,
        loading: false,
      };
    case FETCH_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
        templates: action.payload,
      };
    default:
      return state;
  }
};
