import {
  USER_LIST_PROCESSING,
  USER_LIST_SUCCESS,
  USER_LIST_FAILED,
  UPDATE_USER_PROCESSING,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILED,
  GET_USER_PROCESSING,
  GET_USER_SUCCESS,
  GET_USER_FAILED,
  UPDATE_USER_RESET,
  DELETE_USER_PROCESSING,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILED,
  DELETE_USER_RESET,
  UN_DELETE_USER_PROCESSING,
  UN_DELETE_USER_SUCCESS,
  UN_DELETE_USER_FAILED,
  UPLOAD_PROFILE_PICTURE_PROCESSING,
  UPLOAD_PROFILE_PICTURE_SUCCESS,
  UPLOAD_PROFILE_PICTURE_FAILED,
  UPLOAD_PROFILE_PICTURE_RESET,
} from '../types/types';

const initialState = {
  loading: false,
  status: false,
  severity: '',
  message: '',
  user: {},
  userList: [],
  userDletedInfo: {},

  uploadingPicture: false,
  uploadError: null,
  uploadSuccess: null,
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_LIST_PROCESSING:
      return {
        ...state,
        loading: true,
        status: false,
        userList: []
      };
    case USER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        status: false,
        userList: action.payload
      };
    case USER_LIST_FAILED:
      return {
        ...state,
        loading: false,
        status: true,
        userList: []
      };

    case UPDATE_USER_PROCESSING:
      return {
        ...state,
        loading: true,
        status: false,
        severity: '',
        message: ''
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        status: true,
        severity: 'success',
        message: 'Congratulations, user data has been updated successfully.'
      };
    case UPDATE_USER_FAILED:
      return {
        ...state,
        loading: false,
        status: true,
        severity: 'warning',
        message: action.payload
      };
    case UPDATE_USER_RESET:
      return {
        ...state,
        status: false,
        message: '',
        severity: ''
      };
    case GET_USER_PROCESSING:
      return {
        ...state,
        loading: true,
        // status: false,
        user: {}
      };
    case GET_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        // status: true,

        user: action.payload
      };
    case GET_USER_FAILED:
      return {
        ...state,
        loading: false,
        // status: true,

        user: {}
      };
    case DELETE_USER_PROCESSING:
      return {
        ...state,
        loading: true,
        status: false,
        severity: '',
        message: '',
        userDletedInfo: {}
      };
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        status: true,
        severity: 'success',
        message: 'Congratulations, user data has been deleted successfully.',
        userDletedInfo: action.payload
      };
    case DELETE_USER_FAILED:
      return {
        ...state,
        loading: false,
        status: true,
        severity: 'warning',
        message: action.payload,
        userDletedInfo: {}
      };
    case DELETE_USER_RESET:
      return {
        ...state,
        loading: false,
        status: false,
        severity: '',
        message: ''
      };
    case UN_DELETE_USER_PROCESSING:
      return {
        ...state,
        loading: true,
        status: false,
        severity: '',
        message: '',
      };
    case UN_DELETE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        status: true,
        severity: 'success',
        message: 'Congratulations, user data has been undeleted successfully.',
      };
    case UN_DELETE_USER_FAILED:
      return {
        ...state,
        loading: false,
        status: true,
        severity: 'warning',
        message: action.payload,
      };
    case UPLOAD_PROFILE_PICTURE_PROCESSING:
      return {
        ...state,
        uploadingPicture: true,
        uploadError: null,
      };
    case UPLOAD_PROFILE_PICTURE_SUCCESS:
      return {
        ...state,
        uploadingPicture: false,
        uploadError: null,
        user: action.payload,
        status: true,
        severity: 'success',
        uploadSuccess: 'Congratulations, your profile picture has been updated successfully.'
      };
    case UPLOAD_PROFILE_PICTURE_FAILED:
      return {
        ...state,
        uploadingPicture: false,
        uploadError: action.payload,
        status: true,
        severity: 'warning',
      };
    case UPLOAD_PROFILE_PICTURE_RESET:
      return {
        ...state,
        uploadingPicture: false,
        uploadError: null,
        status: false,
        severity: '',
        uploadSuccess: null
      };
    default:
      return state;
  }
};
