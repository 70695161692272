import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { IconButton, ThemeProvider } from '@material-ui/core';
import { Avatar } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import CloseIcon from '@material-ui/icons/Close';
import theme from '../../styles/theme';
import logo from '../../assets/images/logo.svg';
import profilePic from '../../assets/images/user.JPG';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { logout, passwordUpdate } from '../../store/actions/authAction';
import { useHistory } from 'react-router-dom';
import { ConfirmPassword, PasswordValidation } from '../validations/YupValidations';
import Message from '../message/Message';
// import { AuthRole } from '../common/AuthRole';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import Search from './Search';

const validationSchema = yup.object({
  currentPassword: '',
  newPassword: PasswordValidation(),
  confirmPassword: ConfirmPassword()
});

const Header = ({ auth }) => {
  const dispatch = useDispatch();

  const [userRole, setUserRole] = useState({});

  const authReducer = useSelector((state) => state.authReducer);
  const user = useSelector((state) => state.userReducer.user);

  useEffect(() => {
    if (userRole !== {}) {
      setUserRole(auth.accessLevel);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  useEffect(() => {
    if (window.sessionStorage.length === 0) {
      logout();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.sessionStorage]);

  const [values, setValues] = React.useState({
    password: '',
    showPassword: false,
    showPassword1: false,
    showPassword2: false
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  const handleClickShowPassword1 = () => {
    setValues({ ...values, showPassword1: !values.showPassword1 });
  };
  const handleClickShowPassword2 = () => {
    setValues({ ...values, showPassword2: !values.showPassword2 });
  };

  const formik = useFormik({
    initialValues: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: ''
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      const passwordValue = {
        oldPassword: values.currentPassword,
        newPassword: values.newPassword
      };
      // alert(JSON.stringify(values, null, 2));
      dispatch(passwordUpdate(passwordValue, resetForm));
    }
  });

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setAnchorEl(null);
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };
  const history = useHistory();
  const toogleFunctions = () => {
    let body = document.querySelector('body');
    body.classList.toggle('sidebar-toogle');
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [eventAnchor, setEventAnchor] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleEventClick = (event) => {
    setEventAnchor(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEventClose = () => {
    setEventAnchor(null);
  };

  const handleCreateUser = () => {
    setAnchorEl(null);
    history.push('/crm-user');
  };
  const handleViewAllUsers = () => {
    setAnchorEl(null);
    history.push('/view-users');
  };

  const handleClickUpdate = () => {
    setAnchorEl(null);
    history.push(`/user-detail/${auth._id}`);
  };

  const handleSelfEventClick = () => {
    setEventAnchor(null);
    history.push('/my-event');
  };

  const handleCompanyEvent = () => {
    setEventAnchor(null);
    history.push('/event-management');
  };

  const handleCreateCategory = () => {
    setAnchorEl(null);
    history.push('/create-category');
  };

  const handleLogout = () => {
    logout();
  };

  // const handleTest = (e) => {
  //   if (e.charCode === 13) {
  //     e.preventDefault();
  //   }
  //   if (e.keyCode === 13) {
  //     e.preventDefault();
  //   }
  // };

  const handleCancel = () => {
    setOpen(false);
  };

  const IMAGE_URL = user.imageUploadPath
    ? `/${user.imageUploadPath}`
    : auth.imageUploadPath
    ? `/${auth.imageUploadPath}`
    : profilePic;

  return (
    <ThemeProvider theme={theme}>
      {/* <Backdrop open={authReducer.loading}>
        <CircularProgress color="inherit" />
      </Backdrop> */}
      <Message status={authReducer.status} severity={authReducer.severity} message={authReducer.message} />
      <header id="header">
        <div className="nav-wrapper">
          <div className="logo-holder">
            <div className="logo">
              <Link to="/dashboard">
                <img src={logo} alt="ARS mates property groups" />
              </Link>
            </div>
            <div className="ganesh-logo">
              <span className="icon-ganesh-logo"></span>
            </div>
          </div>
          <nav id="nav">
            {/* <form action="#" className="top-search-form">
              <div className="form-control">
                <input type="search" placeholder="search" onChange={handleTest} />
                <span className="icon-search"></span>
                <span className="icon-arrow-down"></span>
              </div>
            </form> */}

            <Search userId={auth?._id} />

            <div className="create-profile">
              <NavLink to="/client-profile/create">
                <span className="icon-plus-circle"></span>
                <div className="tooltip">Create profile</div>
              </NavLink>
            </div>
            <div className="calender-wrapper">
              <div onClick={handleEventClick}>
                <span className="icon-calender"></span>
                <span className="icon-arrow-down"></span>
              </div>
              <Menu
                id="user-menu"
                anchorEl={eventAnchor}
                keepMounted
                open={Boolean(eventAnchor)}
                onClose={handleEventClose}
              >
                <MenuItem
                  onClick={() => {
                    handleSelfEventClick(setEventAnchor);
                  }}
                >
                  My Event
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleCompanyEvent(setEventAnchor);
                  }}
                >
                  Company Event
                </MenuItem>
              </Menu>
            </div>
            <div className="profile-picture">
              <div aria-controls="user-menu" aria-haspopup="true" onClick={handleClick}>
                <div className="image-holder bg-image" style={{ cursor: 'pointer' }}>
                  <Avatar alt="user" src={IMAGE_URL} style={{ alignSelf: 'center' }} />
                </div>
              </div>
              <Menu id="user-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                {userRole === 'SUPER_ADMIN' || userRole === 'ADMIN' || userRole === 'USER' ? (
                  <MenuItem
                    onClick={() => {
                      handleClickUpdate(setAnchorEl);
                    }}
                  >
                    My Profile
                  </MenuItem>
                ) : null}

                <MenuItem
                  onClick={() => {
                    handleClickOpen(setAnchorEl);
                  }}
                >
                  Update Password
                </MenuItem>
                {userRole === 'SUPER_ADMIN' || userRole === 'ADMIN' ? (
                  <MenuItem
                    onClick={() => {
                      handleViewAllUsers(setAnchorEl);
                    }}
                  >
                    View Users
                  </MenuItem>
                ) : null}
                {userRole === 'SUPER_ADMIN' || userRole === 'ADMIN' ? (
                  <MenuItem
                    onClick={() => {
                      handleCreateUser(setAnchorEl);
                    }}
                  >
                    Create User
                  </MenuItem>
                ) : null}
                {userRole === 'SUPER_ADMIN' || userRole === 'ADMIN' ? (
                  <MenuItem
                    onClick={() => {
                      handleCreateCategory(setAnchorEl);
                    }}
                  >
                    Create Category
                  </MenuItem>
                ) : null}

                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </Menu>
            </div>

            <Dialog open={open} onClose={handleCloseDialog} aria-labelledby="form-dialog-title">
              <div className="Dialog-box-wrapper">
                <h3>Update Password</h3>
                <IconButton className="cross-button" aria-label="close" onClick={handleCancel}>
                  <CloseIcon />
                </IconButton>
                <form className="update-password-form" onSubmit={formik.handleSubmit}>
                  <TextField
                    name="currentPassword"
                    label=" Current Password"
                    type={values.showPassword ? 'text' : 'password'}
                    value={formik.values.currentPassword}
                    onChange={formik.handleChange}
                    error={formik.touched.currentPassword && Boolean(formik.errors.currentPassword)}
                    helperText={formik.touched.currentPassword && formik.errors.currentPassword}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword}>
                            {values.showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                  <TextField
                    name="newPassword"
                    label=" New Password"
                    type={values.showPassword1 ? 'text' : 'password'}
                    value={formik.values.newPassword}
                    onChange={formik.handleChange}
                    error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
                    helperText={formik.touched.newPassword && formik.errors.newPassword}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword1}>
                            {values.showPassword1 ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                  <TextField
                    name="confirmPassword"
                    label="Confirm Password"
                    type={values.showPassword2 ? 'text' : 'password'}
                    value={formik.values.confirmPassword}
                    onChange={formik.handleChange}
                    error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                    helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword2}>
                            {values.showPassword2 ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />

                  <div className="form-buttons">
                    <Button type="submit" variant="contained" color="primary">
                      Update
                    </Button>
                    <Button
                      type="reset"
                      variant="outlined"
                      // color="primary"
                      className="forClearHover onlyForBig"
                      onClick={() => {
                        formik.resetForm();
                      }}
                    >
                      Clear
                    </Button>
                  </div>
                </form>
              </div>
            </Dialog>
            <div className="sidebar-toggle-wrapper showin-mobile">
              <button className="opener icon-toggle" onClick={toogleFunctions}>
                <span>menu</span>
              </button>
            </div>
          </nav>
        </div>
      </header>
    </ThemeProvider>
  );
};

export default Header;
