import ThemeProvider from '@material-ui/styles/ThemeProvider';
import React, { useEffect, useState, useRef } from 'react';
import theme from '../../src/styles/theme';
import {
  Button,
  TextField,
  Grid,
  TextareaAutosize,
  Checkbox
  // InputLabel,
  // Backdrop,
  // CircularProgress
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { useDispatch, useSelector } from 'react-redux';
import { clientListsAction } from '../store/actions/clientAction';
import * as Yup from 'yup';
import {
  Field,
  Form,
  Formik
  // useFormik, useFormikContext
} from 'formik';
import { updateCategoryAction } from '../store/actions/categoryAction';
import { RequiredArray, RequiredField } from '../components/validations/YupValidations';
import { useHistory } from 'react-router-dom';
import { capitilized } from '../components/common/CapitalizedWord';
import { useParams } from 'react-router-dom';
// import Message from '../message/Message';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const EditCategory = () => {
  const history = useHistory();
  const params = useParams();
  const [editData, setEditData] = useState({});
  const dispatch = useDispatch();
  const clientLists = useSelector((state) => {
    return state.clientReducer;
  });
  const { userdata } = clientLists;
  // const { results } = userdata;
  const categoryReducer = useSelector((state) => {
    return state.categoryReducer;
  });
  const { errorMessage, categoryList } = categoryReducer;
  let [states, setStates] = useState([]);
  let [checkValue, setCheckValue] = useState([]);
  let [initialEmailList, setInitialEmailList] = useState([]);

  const formikRef = useRef();

  useEffect(() => {
    if (userdata && userdata.results) {
      const _states = [];
      userdata.results.forEach((result, i) => {
        // eslint-disable-next-line no-unused-expressions
        result.email ? _states.push({ email: result.email }) : null;
      });
      setStates(_states);
    }
  }, [userdata]);

  useEffect(() => {
    dispatch(clientListsAction());
  }, [dispatch]);

  useEffect(() => {
    const data = categoryList.find((category, i) => {
      return category.id === params.id;
    });

    setEditData(data);

    const arr = data.emailList.map((email, i) => {
      return { email: email.email };
    });
    setCheckValue(arr);
    setInitialEmailList(arr);
  }, [categoryList, params.id]);

  const initialValues = {
    categoryname: editData?.categoryName || '',
    description: editData?.categoryDescription || '',
    emailList: []
  };

  const onSubmit = (values, onSubmitProps) => {
    const categorydata = {
      categoryName: capitilized(values.categoryname),
      categoryDescription: values.description,
      emailList: values.emailList
    };
    dispatch(updateCategoryAction(categorydata, onSubmitProps, history, setCheckValue, params.id));
    onSubmitProps.setSubmitting(false);
  };

  const validationSchema = Yup.object({
    categoryname: RequiredField('Category Name'),
    emailList: RequiredArray('At least a client email')
  });

  useEffect(() => {
    if (initialEmailList.length > 0 && formikRef.current) {
      formikRef.current.setFieldValue('emailList', initialEmailList);
    }
  }, [initialEmailList]);

  return (
    <ThemeProvider theme={theme}>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        enableReinitialize
        innerRef={formikRef}
      >
        {(formik) => {
          const { values, isSubmitting, setFieldValue } = formik;
          return (
            <div className="content-section email-management-wrapper">
              <h2>Edit Category </h2>
              <Form className="add-category-form">
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Grid item xs={12} md={6} lg={4}>
                      <Field name="categoryname">
                        {(props) => {
                          const {
                            field,
                            // form,
                            meta
                          } = props;
                          return (
                            <div>
                              <TextField
                                type="text"
                                label={
                                  <>
                                    Category <span className="color-red">*</span>
                                  </>
                                }
                                {...field}
                                error={
                                  meta.touched && meta.error
                                    ? true
                                    : errorMessage ===
                                      `Category name ${capitilized(values.categoryname)} already exists`
                                    ? true
                                    : false
                                }
                                helperText={
                                  meta.touched && meta.error ? (
                                    <div>{meta.error}</div>
                                  ) : errorMessage ===
                                    `Category name ${capitilized(values.categoryname)} already exists` ? (
                                    <div>{errorMessage}</div>
                                  ) : null
                                }
                              />
                            </div>
                          );
                        }}
                      </Field>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid item xs={12} lg={8}>
                      <Field name="description">
                        {(props) => {
                          const { field, meta } = props;
                          return (
                            <div>
                              <span variant="subtitle1" className="textarea-label">
                                Description
                              </span>
                              <TextareaAutosize
                                rowsMin={4}
                                rowsMax={4}
                                {...field}
                                error={meta.touched && meta.error ? true : false}
                                helperText={meta.touched && meta.error ? <div>{meta.error}</div> : null}
                              />
                            </div>
                          );
                        }}
                      </Field>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid item xs={12} lg={8}>
                      <Field name="emailList">
                        {(props) => {
                          const {
                            // field,
                            form,
                            meta
                          } = props;
                          const {
                            setFieldValue
                            // values
                          } = form;

                          return (
                            <div>
                              <Autocomplete
                                name="emailList"
                                onChange={(_, value) => {
                                  setFieldValue('emailList', value);
                                  setCheckValue(value);
                                }}
                                multiple
                                id="checkboxes-tags"
                                options={states}
                                disableCloseOnSelect
                                className="email-list-autocomplete"
                                getOptionLabel={(option) => option.email}
                                value={checkValue}
                                renderOption={(option, { selected }) => (
                                  <React.Fragment>
                                    <Checkbox
                                      icon={icon}
                                      checkedIcon={checkedIcon}
                                      style={{ marginRight: 8 }}
                                      checked={selected}
                                      colorPrimary
                                    />
                                    {option.email}
                                  </React.Fragment>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Add Client"
                                    error={meta.touched && meta.error ? true : false}
                                    helperText={meta.touched && meta.error ? <div>{meta.error}</div> : null}
                                  />
                                )}
                              />
                            </div>
                          );
                        }}
                      </Field>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="form-row category-submit-button">
                      <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>
                        Update
                      </Button>
                      <Button
                        variant="outlined"
                        // color="primary"
                        className="forClearHover onlyForBig"
                        type="reset"
                        onClick={() => {
                          setCheckValue(initialEmailList);
                          setFieldValue('categoryname', '');
                        }}
                      >
                        Clear
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </Form>
            </div>
          );
        }}
      </Formik>
    </ThemeProvider>
  );
};

export default EditCategory;
