import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function PopupDialog({ openAlert, handleCloseAlert, message, onSubmit, onlyForUpdate }) {
  return (
    <div>
      {/* <Button variant="outlined" color="primary" onClick={handleClickOpenAlert}>
        Open alert dialog
      </Button> */}
      <Dialog
        open={openAlert}
        onClose={handleCloseAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Are you sure you want to create this client?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {onlyForUpdate && <span style={{ color: '#f44336' }}> {onlyForUpdate} </span>}
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className={`${onlyForUpdate && 'onlyForUpdate'} `}
            onClick={onSubmit}
            variant="contained"
            color="primary"
          >
            Create
          </Button>
          <Button onClick={handleCloseAlert} variant="outlined" className="forClearHover">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
