import storage from 'redux-persist/lib/storage';
import AuthService from '../../services/auth-service';
import {
  LOGIN_FAILED,
  LOGIN_PROCESSING,
  LOGIN_SUCCESS,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_PROCESSING,
  UPDATE_PASSWORD_FAILED,
  SEND_EMAIL_PROCESSING,
  SEND_EMAIL_SUCCESS,
  SEND_EMAIL_FAILED,
  RESET_PASSWORD_PROCESSING,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILED,
  SIGNUP_PROCESSING,
  SIGNUP_FAILED,
  SIGNUP_SUCCESS,
  SIGNUP_VERIFY_EMAIL_FAILED,
  SIGNUP_VERIFY_EMAIL_PROCESSING,
  SIGNUP_VERIFY_EMAIL_SUCCESS,
  RESET_LOGIN_STATE,
  LOGIN_RESET,
  FETCH_SEARCH_PROCESSING,
  FETCH_SEARCH_SUCCESS,
  FETCH_SEARCH_FAILED
} from '../types/types';

export const loginAction = (email, password, userName, setSnackbarMessage, history, setSubmitting) => (dispatch) => {
  dispatch({
    type: LOGIN_PROCESSING
  });

  return AuthService.login(email, password, userName)
    .then(({ status, data }) => {
      if (status === 200) {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: data
        });
        sessionStorage.setItem('user_accessToken', data.user.accessToken);
        history.push('/dashboard');
      } else {
        setSnackbarMessage('Email or password is incorrect');
        setSubmitting(false);
        dispatch({
          type: LOGIN_FAILED,
          payload: 'Failed'
        });
      }
    })
    .catch((err) => {
      if (
        typeof err !== 'undefined' &&
        typeof err.response !== 'undefined' &&
        typeof err.response.data !== 'undefined' &&
        err.response.data.message ===
          `An attempt was made to perform an operation that is not permitted: email ${email} is not verified`
      ) {
        setSnackbarMessage('Please verify your email');
      } else {
        setSnackbarMessage('Email or password is incorrect');
      }
      setSubmitting(false);
      dispatch({
        type: LOGIN_FAILED,
        payload: 'Failed'
      });
    })
    .finally(() => {
      setTimeout(() => {
        dispatch({
          type: LOGIN_RESET
        });
      }, 4000);
    });
};

export const resetLoginState = () => (dispatch) => {
  dispatch({
    type: RESET_LOGIN_STATE
  });
};

export const logout = () => {
  storage.removeItem('persist:root');
  sessionStorage.clear();
  localStorage.clear();
  window.location.href = '/auth/login';
};

export const signupAction = (signupValues, resetForm, setPristine) => (dispatch) => {
  dispatch({
    type: SIGNUP_PROCESSING
  });

  return AuthService.signUp(signupValues)
    .then(({ status, data }) => {
      if (status === 200) {
        resetForm();
        setPristine();
        dispatch({
          type: SIGNUP_SUCCESS,
          payload: data
        });
      } else {
        dispatch({
          type: SIGNUP_FAILED,
          payload: 'Failed'
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: SIGNUP_FAILED,
        payload:
          typeof err?.response?.data?.message === 'string'
            ? err.response.data.message
            : 'Sorry, but we are unable to create your account right now. Please try again later'
      });
    })
    .finally(() => {
      setTimeout(() => {
        dispatch({
          type: RESET_LOGIN_STATE
        });
      }, 4000);
    });
};

export const signupEmailVerifyAction = (verifyValues) => (dispatch) => {
  dispatch({
    type: SIGNUP_VERIFY_EMAIL_PROCESSING
  });

  return AuthService.signupVerify(verifyValues)
    .then(({ status, data }) => {
      if (status === 200) {
        dispatch({
          type: SIGNUP_VERIFY_EMAIL_SUCCESS,
          payload: data
        });

        logout();
      } else {
        dispatch({
          type: SIGNUP_VERIFY_EMAIL_FAILED,
          payload: 'Failed'
        });
      }
    })
    .catch(() => {
      dispatch({
        type: SIGNUP_VERIFY_EMAIL_FAILED,
        payload: 'Failed'
      });
    })
    .finally(() => {
      setTimeout(() => {
        dispatch({
          type: RESET_LOGIN_STATE
        });
      }, 4000);
    });
};

export const passwordUpdate = (passwordValues, resetForm) => (dispatch) => {
  dispatch({
    type: UPDATE_PASSWORD_PROCESSING
  });

  return AuthService.updateClientPassword(passwordValues)
    .then(({ status, data }) => {
      if (status === 200) {
        dispatch({
          type: UPDATE_PASSWORD_SUCCESS,
          payload: data
        });
        logout();
        resetForm();
      } else {
        dispatch({
          type: UPDATE_PASSWORD_FAILED,
          payload: 'Failed'
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: UPDATE_PASSWORD_FAILED,
        payload:
          typeof err?.response?.data?.message !== 'string'
            ? 'Sorry, but we are unable to update your password right now. Please make sure your current password is correct and try again.'
            : err.response.data.message === '(You cannot change your old password.) undefined'
            ? 'You cannot change your old password'
            : err.response.data.message
      });
    })
    .finally(() => {
      setTimeout(() => {
        dispatch({
          type: RESET_LOGIN_STATE
        });
      }, 4000);
    });
};

// send email
export const sendEmail = (email, resetForm) => (dispatch) => {
  dispatch({
    type: SEND_EMAIL_PROCESSING
  });

  return AuthService.sendClientEmail(email)
    .then(({ status, data }) => {
      if (status === 200) {
        dispatch({
          type: SEND_EMAIL_SUCCESS,
          payload: data
        });
        resetForm();
      } else {
        dispatch({
          type: SEND_EMAIL_FAILED,
          payload: 'Failed'
        });
      }
    })
    .catch(() => {
      dispatch({
        type: SEND_EMAIL_FAILED,
        payload: 'Failed'
      });
    })
    .finally(() => {
      setTimeout(() => {
        dispatch({
          type: RESET_LOGIN_STATE
        });
      }, 4000);
    });
};
//reset Password
export const resetPassword = (newPassword, email, verificationToken, resetForm) => (dispatch) => {
  dispatch({
    type: RESET_PASSWORD_PROCESSING
  });

  return AuthService.resetClientPassword(newPassword, email, verificationToken)
    .then(({ status, data }) => {
      if (status === 200) {
        dispatch({
          type: RESET_PASSWORD_SUCCESS,
          payload: data
        });
        logout();
        resetForm();
      } else {
        dispatch({
          type: RESET_PASSWORD_FAILED,
          payload: 'Failed'
        });
      }
    })
    .catch(() => {
      dispatch({
        type: RESET_PASSWORD_FAILED,
        payload: 'Failed'
      });
    })
    .finally(() => {
      setTimeout(() => {
        dispatch({
          type: RESET_LOGIN_STATE
        });
      }, 4000);
    });
};

export const fetchSearchData = () => (dispatch) => {
  dispatch({
    type: FETCH_SEARCH_PROCESSING
  });

  return AuthService.fetchSearchData()
    .then(({ status, data }) => {
      if (status === 200) {
        dispatch({
          type: FETCH_SEARCH_SUCCESS,
          payload: data
        });
      } else {
        dispatch({
          type: FETCH_SEARCH_FAILED,
          payload: 'Failed'
        });
      }
    })
    .catch(() => {
      dispatch({
        type: FETCH_SEARCH_FAILED,
        payload: 'Failed'
      });
    });
};
