import ClientProfileService from '../../services/client-profile-service';
import {
  ADD_CLIENT_FAILED,
  ADD_CLIENT_PROCESSING,
  ADD_CLIENT_SUCCESS,
  CLIENT_DETAILS_FAILED,
  CLIENT_DETAILS_PROCESSING,
  CLIENT_DETAILS_SUCCESS,
  CLIENT_LISTS_FAILED,
  CLIENT_LISTS_PROCESSING,
  CLIENT_LISTS_SUCCESS,
  DELETE_CLIENT_DETAILS_FAILED,
  DELETE_CLIENT_DETAILS_PROCESSING,
  DELETE_CLIENT_DETAILS_SUCCESS,
  UPDATE_CLIENT_DETAILS_FAILED,
  UPDATE_CLIENT_DETAILS_PROCESSING,
  UPDATE_CLIENT_DETAILS_SUCCESS,
  UPDATE_CLIENT_FOLLOWUPNOTES_FAILED,
  UPDATE_CLIENT_FOLLOWUPNOTES_PROCESSING,
  UPDATE_CLIENT_FOLLOWUPNOTES_SUCCESS,
  SEARCH_PROCESSING,
  SEARCH_SUCCESS,
  SEARCH_FAILED,
  ADD_TRANSACTION_PROCESSING,
  ADD_TRANSACTION_SUCCESS,
  ADD_TRANSACTION_FAILED,
  ADD_FILES_AND_CORRESPONDENCE_PROCESSING,
  ADD_FILES_AND_CORRESPONDENCE_SUCCESS,
  ADD_FILES_AND_CORRESPONDENCE_FAILED,
  CLIENT_LISTS_RESET,
  UPDATE_TRANSACTIONSNOTES_SUCCESS,
  UPDATE_TRANSACTIONSNOTES_FAILED,
  UPDATE_TRANSACTIONSNOTES_PROCESSING,
  UPDATE_FILESNOTES_PROCESSING,
  UPDATE_FILESNOTES_SUCCESS,
  UPDATE_FILESNOTES_FAILED,
  DELETE_FILES_AND_CORRESPONDENCE_PROCESSING,
  DELETE_FILES_AND_CORRESPONDENCE_SUCCESS,
  DELETE_FILES_AND_CORRESPONDENCE_FAILED,
  DELETE_FILES_PROCESSING,
  DELETE_FILES_SUCCESS,
  DELETE_FILES_FAILED,
  DELETE_CORRESPONDENCE_PROCESSING,
  DELETE_CORRESPONDENCE_SUCCESS,
  DELETE_CORRESPONDENCE_FAILED,
  UPDATE_CORRESPONDENCE_PROCESSING,
  UPDATE_CORRESPONDENCE_SUCCESS,
  UPDATE_CORRESPONDENCE_FAILED,
  DELETE_FOLLOWUPNOTE_PROCESSING,
  DELETE_FOLLOWUPNOTE_SUCCESS,
  DELETE_FOLLOWUPNOTE_FAILED,
  UPDATE_FOLLOWUPNOTE_SUCCESS,
  UPDATE_FOLLOWUPNOTE_PROCESSING,
  UPDATE_FOLLOWUPNOTE_FAILED,
  EDIT_TRANSACTIONSNOTES_PROCESSING,
  EDIT_TRANSACTIONSNOTES_SUCCESS,
  EDIT_TRANSACTIONSNOTES_FAILED,
  DELETE_TRANSACTIONSNOTES_PROCESSING,
  DELETE_TRANSACTIONSNOTES_SUCCESS,
  DELETE_TRANSACTIONSNOTES_FAILED,
  ADD_TRANSACTION_RESET,
  ADD_FILES_AND_CORRESPONDENCE_RESET,
  DELETE_FILES_RESET,
  DELETE_CORRESPONDENCE_RESET,
  UPDATE_CORRESPONDENCE_RESET,
  DELETE_FILES_AND_CORRESPONDENCE_RESET,
  DELETE_TRANSACTIONSDETAILS_PROCESSING,
  DELETE_TRANSACTIONSDETAILS_SUCCESS,
  DELETE_TRANSACTIONSDETAILS_FAILED,
  ADDNEW_CLIENT_PROCESSING,
  ADDNEW_CLIENT_SUCCESS,
  ADDNEW_CLIENT_FAILED,
  DELETE_CORRESPONDING_NOTE_FAILED,
  DELETE_CORRESPONDING_NOTE_PROCESSING,
  // ADD_OTHERDETAILS_PROCESSING,
  // ADD_OTHERDETAILS_SUCCESS,
  // ADD_OTHERDETAILS_FAILED,
  // ADD_OTHERDETAILS_RESET,
  FOLLOW_UP_TASK_COMPLETE_PROCESSING,
  FOLLOW_UP_TASK_COMPLETE_SUCCESS,
  FOLLOW_UP_TASK_COMPLETE_FAILED,
  OTHERS_DETAILS_FILES_AND_CORRESPONDENCE_PROCESSING,
  OTHERS_DETAILS_FILES_AND_CORRESPONDENCE_SUCCESS,
  OTHERS_DETAILS_FILES_AND_CORRESPONDENCE_FAILED,
  FILES_AND_CORRESPONDENCE_OTHERS_DETAILS_PROCESSING,
  FILES_AND_CORRESPONDENCE_OTHERS_DETAILS_SUCCESS,
  FILES_AND_CORRESPONDENCE_OTHERS_DETAILS_FAILED,
  GET_FOLLOW_UP_NOTES_PROCESSING,
  GET_FOLLOW_UP_NOTES_SUCCESS,
  GET_FOLLOW_UP_NOTES_FAILED
} from '../types/types';
// import { addEventAction } from './eventAction';
import { DELETE_CORRESPONDING_NOTE_SUCCESS } from './../types/types';

export const addClientDataAction =
  (
    clientDetail,
    resetForm,
    setPristine,
    setleadOpen,
    setleadClose,
    setFollowUpAssignedTo,
    setReferral,
    setReferralName
  ) =>
  (dispatch) => {
    dispatch({
      type: ADD_CLIENT_PROCESSING
    });

    return ClientProfileService.create(clientDetail)
      .then(({ status, data }) => {
        if (status === 200) {
          dispatch({
            type: ADD_CLIENT_SUCCESS,
            payload: data
          });
          setPristine();
          resetForm();
          setleadOpen(null);
          setleadClose(null);
          setFollowUpAssignedTo(null);
          setReferral(null);
          setReferralName(null);
        } else {
          dispatch({
            type: ADD_CLIENT_FAILED,
            payload: 'Failed'
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: ADD_CLIENT_FAILED,
          payload:
            typeof err?.response?.data?.message === 'string'
              ? err.response.data.message
              : 'Sorry, we are unable to post this client profile right now. Please try again later.'
        });
      })
      .finally(() => {
        setTimeout(() => {
          dispatch({
            type: CLIENT_LISTS_RESET
          });
        }, 4000);
      });
  };

//duplicate Client add
export const addNewClientDataAction =
  (
    clientDetail,
    resetForm,
    setPristine,
    setleadOpen,
    setleadClose,
    setFollowUpAssignedTo,
    setReferral,
    setReferralName
  ) =>
  (dispatch) => {
    dispatch({
      type: ADDNEW_CLIENT_PROCESSING
    });

    return ClientProfileService.createnew(clientDetail)
      .then(({ status, data }) => {
        if (status === 200) {
          dispatch({
            type: ADDNEW_CLIENT_SUCCESS,
            payload: data
          });

          setPristine();
          resetForm();
          setleadOpen(null);
          setleadClose(null);
          setFollowUpAssignedTo(null);
          setReferral(null);
          setReferralName(null);
        } else {
          dispatch({
            type: ADDNEW_CLIENT_FAILED,
            payload: 'Failed'
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: ADDNEW_CLIENT_FAILED,
          payload:
            typeof err?.response?.data?.message === 'string'
              ? err.response.data.message
              : 'Sorry, we are unable to post this client profile right now. Please try again later.'
        });
      })
      .finally(() => {
        setTimeout(() => {
          dispatch({
            type: CLIENT_LISTS_RESET
          });
        }, 4000);
      });
  };

export const clientListsAction = () => (dispatch) => {
  dispatch({
    type: CLIENT_LISTS_PROCESSING
  });

  return ClientProfileService.showClientLists()
    .then(({ status, data }) => {
      if (status === 200) {
        dispatch({
          type: CLIENT_LISTS_SUCCESS,
          payload: data
        });
      } else {
        dispatch({
          type: CLIENT_LISTS_FAILED,
          payload: 'Failed'
        });
      }
    })
    .catch(() => {
      dispatch({
        type: CLIENT_LISTS_FAILED,
        payload: 'Failed'
      });
    });
};

export const updateClientDetailsAction = (updatedData, id, onSubmitProps, history, resetDirty) => (dispatch) => {
  dispatch({
    type: UPDATE_CLIENT_DETAILS_PROCESSING
  });
  return ClientProfileService.updateClient(updatedData, id)
    .then(({ status, data }) => {
      if (status === 200) {
        dispatch({
          type: UPDATE_CLIENT_DETAILS_SUCCESS,
          payload: data
        });
        onSubmitProps.resetForm();
        resetDirty();
        history.push(`/client-profile/update/${id}`);
      } else {
        dispatch({
          type: UPDATE_CLIENT_DETAILS_FAILED,
          payload: 'Failed'
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: UPDATE_CLIENT_DETAILS_FAILED,
        payload:
          typeof err?.response?.data?.message === 'string'
            ? err.response.data.message
            : 'Sorry, we are unable to update this client profile right now. Please try again later.'
      });
    })
    .finally(() => {
      setTimeout(() => {
        dispatch({
          type: CLIENT_LISTS_RESET
        });
      }, 4000);
    });
};

export const deleteClientDetailsAction = (id) => (dispatch) => {
  dispatch({
    type: DELETE_CLIENT_DETAILS_PROCESSING
  });
  return ClientProfileService.deleteClient(id)
    .then(({ status, data }) => {
      if (status === 200) {
        dispatch({
          type: DELETE_CLIENT_DETAILS_SUCCESS,
          payload: data
        });
        dispatch(clientListsAction());
      } else {
        dispatch({
          type: DELETE_CLIENT_DETAILS_FAILED,
          payload: 'Failed'
        });
      }
    })
    .catch(() => {
      dispatch({
        type: DELETE_CLIENT_DETAILS_FAILED,
        payload: 'Failed'
      });
    })
    .finally(() => {
      setTimeout(() => {
        dispatch({
          type: CLIENT_LISTS_RESET
        });
      }, 4000);
    });
};

export const resetStatus = () => (dispatch) => {
  dispatch({
    type: CLIENT_LISTS_RESET
  });
};

export const clientDetailsAction = (id) => (dispatch) => {
  dispatch({
    type: CLIENT_DETAILS_PROCESSING
  });

  return ClientProfileService.showClientDetails(id)
    .then(({ status, data }) => {
      if (status === 200) {
        dispatch({
          type: CLIENT_DETAILS_SUCCESS,
          payload: data
        });
      } else {
        dispatch({
          type: CLIENT_DETAILS_FAILED,
          payload: 'Failed'
        });
      }
    })
    .catch(() => {
      dispatch({
        type: CLIENT_DETAILS_FAILED,
        payload: 'Failed'
      });
    });
};

export const updateClientFollowUpNotesAction = (updatedData, id) => (dispatch) => {
  dispatch({
    type: UPDATE_CLIENT_FOLLOWUPNOTES_PROCESSING
  });

  return ClientProfileService.updateClientFollowUpNotes(updatedData, id)
    .then(({ status, data }) => {
      if (status === 200) {
        dispatch({
          type: UPDATE_CLIENT_FOLLOWUPNOTES_SUCCESS,
          payload: data
        });
      } else {
        dispatch({
          type: UPDATE_CLIENT_FOLLOWUPNOTES_FAILED,
          payload: 'Failed'
        });
      }
    })
    .catch(() => {
      dispatch({
        type: UPDATE_CLIENT_FOLLOWUPNOTES_FAILED,
        payload: 'Failed'
      });
    })
    .finally(() => {
      setTimeout(() => {
        dispatch({
          type: CLIENT_LISTS_RESET
        });
      }, 4000);
    });
};
export const searchUsers = (text) => (dispatch) => {
  dispatch({
    type: SEARCH_PROCESSING
  });

  return ClientProfileService.searchUsers(text)
    .then(({ status, data }) => {
      if (status === 200) {
        dispatch({
          type: SEARCH_SUCCESS,
          payload: data
        });
      } else {
        dispatch({
          type: SEARCH_FAILED,
          payload: 'Failed'
        });
      }
    })
    .catch(() => {
      dispatch({
        type: SEARCH_FAILED,
        payload: 'Failed'
      });
    });
};

export const addTransactionDetails = (data, paramsId) => (dispatch) => {
  dispatch({
    type: ADD_TRANSACTION_PROCESSING
  });

  return ClientProfileService.addTransaction(data, paramsId)
    .then(({ status, data }) => {
      if (status === 200) {
        dispatch({
          type: ADD_TRANSACTION_SUCCESS
          // payload: data
          // payload:
        });
      } else {
        dispatch({
          type: ADD_TRANSACTION_FAILED,
          payload: 'Failed'
        });
      }
    })
    .catch(() => {
      dispatch({
        type: ADD_TRANSACTION_FAILED,
        payload: 'Failed'
      });
    })
    .finally(() => {
      setTimeout(() => {
        dispatch({
          type: ADD_TRANSACTION_RESET
        });
      }, 4000);
    });
};

export const addFilesAndCorrespondence = (clientId, transactionId, formData, handleClose) => (dispatch) => {
  dispatch({
    type: ADD_FILES_AND_CORRESPONDENCE_PROCESSING
  });

  return ClientProfileService.addFilesCorrespondence(clientId, transactionId, formData)
    .then(({ status, data }) => {
      if (status === 200) {
        dispatch({
          type: ADD_FILES_AND_CORRESPONDENCE_SUCCESS,
          payload: data
        });
        // close modal
        handleClose();
      } else {
        dispatch({
          type: ADD_FILES_AND_CORRESPONDENCE_FAILED,
          payload: 'Failed'
        });
      }
    })
    .catch(() => {
      dispatch({
        type: ADD_FILES_AND_CORRESPONDENCE_FAILED,
        payload: 'Unable to add file. Please attach atleast one file. '
      });
    })
    .finally(() => {
      setTimeout(() => {
        dispatch({
          type: ADD_FILES_AND_CORRESPONDENCE_RESET
        });
      }, 2000);
    });
};
export const addOtherDetails = (paramsId, formData, handleCloseOtherDetails) => (dispatch) => {
  dispatch({
    type: ADD_FILES_AND_CORRESPONDENCE_PROCESSING
  });

  return ClientProfileService.addOtherDetails(paramsId, formData, handleCloseOtherDetails)
    .then(({ status, data }) => {
      if (status === 200) {
        dispatch({
          type: ADD_FILES_AND_CORRESPONDENCE_SUCCESS,
          payload: data
        });
        // close modal
        handleCloseOtherDetails();
      } else {
        dispatch({
          type: ADD_FILES_AND_CORRESPONDENCE_FAILED,
          payload: 'Failed'
        });
      }
    })
    .catch(() => {
      dispatch({
        type: ADD_FILES_AND_CORRESPONDENCE_FAILED,
        payload: 'Unable to add file. Please attach atleast one file. '
      });
    })
    .finally(() => {
      setTimeout(() => {
        dispatch({
          type: ADD_FILES_AND_CORRESPONDENCE_RESET
        });
      }, 2000);
    });
};

export const addFilesAction = (clientId, transactionId, correspondingId, formData, handleClose) => (dispatch) => {
  dispatch({
    type: ADD_FILES_AND_CORRESPONDENCE_PROCESSING
  });

  return ClientProfileService.addFiles(clientId, transactionId, correspondingId, formData)
    .then(({ status, data }) => {
      if (status === 200) {
        dispatch({
          type: ADD_FILES_AND_CORRESPONDENCE_SUCCESS,
          payload: data
        });
        // close modal
        handleClose();
      } else {
        dispatch({
          type: ADD_FILES_AND_CORRESPONDENCE_FAILED,
          payload: 'Failed'
        });
      }
    })
    .catch(() => {
      dispatch({
        type: ADD_FILES_AND_CORRESPONDENCE_FAILED,
        payload: 'Unable to add file. Please attach atleast one file. '
      });
    })
    .finally(() => {
      setTimeout(() => {
        dispatch({
          type: ADD_FILES_AND_CORRESPONDENCE_RESET
        });
      }, 2000);
    });
};
export const addFilesActionOther = (clientId, currentCorrespondingId, formData, handleClose) => (dispatch) => {
  dispatch({
    type: ADD_FILES_AND_CORRESPONDENCE_PROCESSING
  });

  return ClientProfileService.addFilesActionOther(clientId, currentCorrespondingId, formData, handleClose)
    .then(({ status, data }) => {
      if (status === 200) {
        dispatch({
          type: ADD_FILES_AND_CORRESPONDENCE_SUCCESS,
          payload: data
        });
        // close modal
        handleClose();
      } else {
        dispatch({
          type: ADD_FILES_AND_CORRESPONDENCE_FAILED,
          payload: 'Failed'
        });
      }
    })
    .catch(() => {
      dispatch({
        type: ADD_FILES_AND_CORRESPONDENCE_FAILED,
        payload: 'Unable to add file. Please attach atleast one file. '
      });
    })
    .finally(() => {
      setTimeout(() => {
        dispatch({
          type: ADD_FILES_AND_CORRESPONDENCE_RESET
        });
      }, 2000);
    });
};

export const deleteFilesAndCorrespondence = (paramsId, othersId) => (dispatch) => {
  dispatch({
    type: DELETE_FILES_AND_CORRESPONDENCE_PROCESSING
  });

  return ClientProfileService.deleteFilesCorrespondence(paramsId, othersId)
    .then(({ status, data }) => {
      if (status === 200) {
        dispatch({
          type: DELETE_FILES_AND_CORRESPONDENCE_SUCCESS,
          payload: data
        });
      } else {
        dispatch({
          type: DELETE_FILES_AND_CORRESPONDENCE_FAILED,
          payload: 'Failed'
        });
      }
    })
    .catch(() => {
      dispatch({
        type: DELETE_FILES_AND_CORRESPONDENCE_FAILED,
        payload: 'Failed'
      });
    })
    .finally(() => {
      setTimeout(() => {
        dispatch({
          type: DELETE_FILES_AND_CORRESPONDENCE_RESET
        });
      }, 4000);
    });
};
export const deleteOtherDetails = (paramsId, othersId) => (dispatch) => {
  dispatch({
    type: DELETE_FILES_AND_CORRESPONDENCE_PROCESSING
  });

  return ClientProfileService.deleteOtherDetails(paramsId, othersId)
    .then(({ status, data }) => {
      if (status === 200) {
        dispatch({
          type: DELETE_FILES_AND_CORRESPONDENCE_SUCCESS,
          payload: data
        });
      } else {
        dispatch({
          type: DELETE_FILES_AND_CORRESPONDENCE_FAILED,
          payload: 'Failed'
        });
      }
    })
    .catch(() => {
      dispatch({
        type: DELETE_FILES_AND_CORRESPONDENCE_FAILED,
        payload: 'Failed'
      });
    })
    .finally(() => {
      setTimeout(() => {
        dispatch({
          type: DELETE_FILES_AND_CORRESPONDENCE_RESET
        });
      }, 4000);
    });
};

export const deleteFiles = (paramsId, deleteFileTransactions, othersId, index) => (dispatch) => {
  dispatch({
    type: DELETE_FILES_PROCESSING
  });

  return ClientProfileService.deleteFiles(paramsId, deleteFileTransactions, othersId, index)
    .then(({ status, data }) => {
      if (status === 200) {
        dispatch({
          type: DELETE_FILES_SUCCESS,
          payload: data
        });
      } else {
        dispatch({
          type: DELETE_FILES_FAILED,
          payload: 'Failed'
        });
      }
    })
    .catch(() => {
      dispatch({
        type: DELETE_FILES_FAILED,
        payload: 'Failed'
      });
    })
    .finally(() => {
      setTimeout(() => {
        dispatch({
          type: DELETE_FILES_RESET
        });
      }, 4000);
    });
};
export const deleteFilesOther = (paramsId, othersId, index) => (dispatch) => {
  dispatch({
    type: DELETE_FILES_PROCESSING
  });

  return ClientProfileService.deleteFilesOther(paramsId, othersId, index)
    .then(({ status, data }) => {
      if (status === 200) {
        dispatch({
          type: DELETE_FILES_SUCCESS,
          payload: data
        });
      } else {
        dispatch({
          type: DELETE_FILES_FAILED,
          payload: 'Failed'
        });
      }
    })
    .catch(() => {
      dispatch({
        type: DELETE_FILES_FAILED,
        payload: 'Failed'
      });
    })
    .finally(() => {
      setTimeout(() => {
        dispatch({
          type: DELETE_FILES_RESET
        });
      }, 4000);
    });
};
export const deleteCorrespondigNotes = (paramsId, tranId, othersId, index) => (dispatch) => {
  dispatch({
    type: DELETE_CORRESPONDENCE_PROCESSING
  });

  return ClientProfileService.deleteCorrespondingNotes(paramsId, tranId, othersId, index)
    .then(({ status, data }) => {
      if (status === 200) {
        dispatch({
          type: DELETE_CORRESPONDENCE_SUCCESS,
          payload: data
        });
      } else {
        dispatch({
          type: DELETE_CORRESPONDENCE_FAILED,
          payload: 'Failed'
        });
      }
    })
    .catch(() => {
      dispatch({
        type: DELETE_CORRESPONDENCE_FAILED,
        payload: 'Failed'
      });
    })
    .finally(() => {
      setTimeout(() => {
        dispatch({
          type: DELETE_CORRESPONDENCE_RESET
        });
      }, 4000);
    });
};
export const deleteCorrespondigNotesOther = (paramsId, othersId, index) => (dispatch) => {
  dispatch({
    type: DELETE_CORRESPONDENCE_PROCESSING
  });

  return ClientProfileService.deleteCorrespondigNotesOther(paramsId, othersId, index)
    .then(({ status, data }) => {
      if (status === 200) {
        dispatch({
          type: DELETE_CORRESPONDENCE_SUCCESS,
          payload: data
        });
      } else {
        dispatch({
          type: DELETE_CORRESPONDENCE_FAILED,
          payload: 'Failed'
        });
      }
    })
    .catch(() => {
      dispatch({
        type: DELETE_CORRESPONDENCE_FAILED,
        payload: 'Failed'
      });
    })
    .finally(() => {
      setTimeout(() => {
        dispatch({
          type: DELETE_CORRESPONDENCE_RESET
        });
      }, 4000);
    });
};
export const updateCorrespondigNotes = (data, paramsId, tranId, othersId, index) => (dispatch) => {
  dispatch({
    type: UPDATE_CORRESPONDENCE_PROCESSING
  });

  return ClientProfileService.updateCorrespondingNotes(data, paramsId, tranId, othersId, index)
    .then(({ status, data }) => {
      if (status === 200) {
        dispatch({
          type: UPDATE_CORRESPONDENCE_SUCCESS,
          payload: data
        });
      } else {
        dispatch({
          type: UPDATE_CORRESPONDENCE_FAILED,
          payload: 'Failed'
        });
      }
    })
    .catch(() => {
      dispatch({
        type: UPDATE_CORRESPONDENCE_FAILED,
        payload: 'Failed'
      });
    })
    .finally(() => {
      setTimeout(() => {
        dispatch({
          type: UPDATE_CORRESPONDENCE_RESET
        });
      }, 4000);
    });
};
export const updateCorrespondigNotesOther = (data, paramsId, othersId, index) => (dispatch) => {
  dispatch({
    type: UPDATE_CORRESPONDENCE_PROCESSING
  });

  return ClientProfileService.updateCorrespondingNotesOther(data, paramsId, othersId, index)
    .then(({ status, data }) => {
      if (status === 200) {
        dispatch({
          type: UPDATE_CORRESPONDENCE_SUCCESS,
          payload: data
        });
      } else {
        dispatch({
          type: UPDATE_CORRESPONDENCE_FAILED,
          payload: 'Failed'
        });
      }
    })
    .catch(() => {
      dispatch({
        type: UPDATE_CORRESPONDENCE_FAILED,
        payload: 'Failed'
      });
    })
    .finally(() => {
      setTimeout(() => {
        dispatch({
          type: UPDATE_CORRESPONDENCE_RESET
        });
      }, 4000);
    });
};

export const updateFilesNotes = (data, paramsId, transactionIdForFileNote, valuesId, history) => (dispatch) => {
  dispatch({
    type: UPDATE_FILESNOTES_PROCESSING
  });

  return ClientProfileService.updateFilesNotes(data, paramsId, transactionIdForFileNote, valuesId)
    .then(({ status, data }) => {
      if (status === 200) {
        dispatch({
          type: UPDATE_FILESNOTES_SUCCESS,
          payload: data
        });
        // onSubmitProps.resetForm();
        // resetDirty();
      } else {
        dispatch({
          type: UPDATE_FILESNOTES_FAILED,
          payload: 'Failed'
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: UPDATE_FILESNOTES_FAILED,
        payload:
          typeof err?.response?.data?.message === 'string'
            ? err.response.data.message
            : 'Sorry, we are unable to update this file note right now. Please try again later.'
      });
    })
    .finally(() => {
      setTimeout(() => {
        dispatch({
          type: CLIENT_LISTS_RESET
        });
      }, 4000);
    });
};
export const updateFilesNotesOthers = (data, paramsId, valuesId, history) => (dispatch) => {
  dispatch({
    type: UPDATE_FILESNOTES_PROCESSING
  });

  return ClientProfileService.updateFilesNotesOthers(data, paramsId, valuesId, history)
    .then(({ status, data }) => {
      if (status === 200) {
        dispatch({
          type: UPDATE_FILESNOTES_SUCCESS,
          payload: data
        });
        // onSubmitProps.resetForm();
        // resetDirty();
      } else {
        dispatch({
          type: UPDATE_FILESNOTES_FAILED,
          payload: 'Failed'
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: UPDATE_FILESNOTES_FAILED,
        payload:
          typeof err?.response?.data?.message === 'string'
            ? err.response.data.message
            : 'Sorry, we are unable to update this file note right now. Please try again later.'
      });
    })
    .finally(() => {
      setTimeout(() => {
        dispatch({
          type: CLIENT_LISTS_RESET
        });
      }, 4000);
    });
};

export const updateTransactionsNotes = (data, paramsId, transactionId) => (dispatch) => {
  dispatch({
    type: UPDATE_TRANSACTIONSNOTES_PROCESSING
  });

  return ClientProfileService.updateTransactionsNotes(data, paramsId, transactionId)
    .then(({ status, data }) => {
      if (status === 200) {
        dispatch({
          type: UPDATE_TRANSACTIONSNOTES_SUCCESS,
          payload: data
        });
      } else {
        dispatch({
          type: UPDATE_TRANSACTIONSNOTES_FAILED,
          payload: 'Failed'
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: UPDATE_TRANSACTIONSNOTES_FAILED,
        payload:
          typeof err?.response?.data?.message === 'string'
            ? err.response.data.message
            : 'Sorry, we are unable to update this transaction detail right now. Please try again later.'
      });
    })
    .finally(() => {
      setTimeout(() => {
        dispatch({
          type: CLIENT_LISTS_RESET
        });
      }, 4000);
    });
};
export const editTransactionsNotes = (data, paramsId, transactionId, index) => (dispatch) => {
  dispatch({
    type: EDIT_TRANSACTIONSNOTES_PROCESSING
  });

  return ClientProfileService.editTransactionsNotes(data, paramsId, transactionId, index)
    .then(({ status, data }) => {
      if (status === 200) {
        dispatch({
          type: EDIT_TRANSACTIONSNOTES_SUCCESS,
          payload: data
        });
      } else {
        dispatch({
          type: EDIT_TRANSACTIONSNOTES_FAILED,
          payload: 'Failed'
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: EDIT_TRANSACTIONSNOTES_FAILED,
        payload:
          typeof err?.response?.data?.message === 'string'
            ? err.response.data.message
            : 'Sorry, we are unable to update this transaction note right now. Please try again later.'
      });
    })
    .finally(() => {
      setTimeout(() => {
        dispatch({
          type: CLIENT_LISTS_RESET
        });
      }, 4000);
    });
};
export const deleteTransactionsNotes = (paramsId, transactionId, index) => (dispatch) => {
  dispatch({
    type: DELETE_TRANSACTIONSNOTES_PROCESSING
  });

  return ClientProfileService.deleteTransactionsNotes(paramsId, transactionId, index)
    .then(({ status, data }) => {
      if (status === 200) {
        dispatch({
          type: DELETE_TRANSACTIONSNOTES_SUCCESS,
          payload: data
        });
      } else {
        dispatch({
          type: DELETE_TRANSACTIONSNOTES_FAILED,
          payload: 'Failed'
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: DELETE_TRANSACTIONSNOTES_FAILED,
        payload:
          typeof err?.response?.data?.message === 'string'
            ? err.response.data.message
            : 'Sorry, we are unable to delete this transaction note right now. Please try again later.'
      });
    })
    .finally(() => {
      setTimeout(() => {
        dispatch({
          type: CLIENT_LISTS_RESET
        });
      }, 4000);
    });
};

export const updatefollowupnote = (paramsId, othersId, data) => (dispatch) => {
  dispatch({
    type: UPDATE_FOLLOWUPNOTE_PROCESSING
  });

  return ClientProfileService.updatefollowupnote(paramsId, othersId, data)
    .then(({ status, data }) => {
      if (status === 200) {
        dispatch({
          type: UPDATE_FOLLOWUPNOTE_SUCCESS,
          payload: data
        });
      } else {
        dispatch({
          type: UPDATE_FOLLOWUPNOTE_FAILED,
          payload: 'Failed'
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: UPDATE_FOLLOWUPNOTE_FAILED,
        payload:
          typeof err?.response?.data?.message === 'string'
            ? err.response.data.message
            : 'Sorry, we are unable to update this note right now. Please try again later.'
      });
    })
    .finally(() => {
      setTimeout(() => {
        dispatch({
          type: CLIENT_LISTS_RESET
        });
      }, 4000);
    });
};
export const deletefollowupnote = (paramsId, noteId) => (dispatch) => {
  dispatch({
    type: DELETE_FOLLOWUPNOTE_PROCESSING
  });

  return ClientProfileService.deletefollowupnote(paramsId, noteId)
    .then(({ status, data }) => {
      if (status === 200) {
        dispatch({
          type: DELETE_FOLLOWUPNOTE_SUCCESS,
          payload: data
        });
      } else {
        dispatch({
          type: DELETE_FOLLOWUPNOTE_FAILED,
          payload: 'Failed'
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: DELETE_FOLLOWUPNOTE_FAILED,
        payload:
          typeof err?.response?.data?.message === 'string'
            ? err.response.data.message
            : 'Sorry, we are unable to delete this note right now. Please try again later.'
      });
    })
    .finally(() => {
      setTimeout(() => {
        dispatch({
          type: CLIENT_LISTS_RESET
        });
      }, 4000);
    });
};

export const deleteTransactionsDetails = (paramsId, transactionId) => (dispatch) => {
  dispatch({
    type: DELETE_TRANSACTIONSDETAILS_PROCESSING
  });

  return ClientProfileService.deleteTransactionsDetails(paramsId, transactionId)
    .then(({ status, data }) => {
      if (status === 200) {
        dispatch({
          type: DELETE_TRANSACTIONSDETAILS_SUCCESS,
          payload: data
        });
      } else {
        dispatch({
          type: DELETE_TRANSACTIONSDETAILS_FAILED,
          payload: 'Failed'
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: DELETE_TRANSACTIONSDETAILS_FAILED,
        payload:
          typeof err?.response?.data?.message === 'string'
            ? err.response.data.message
            : 'Sorry, we are unable to delete this transaction detail right now. Please try again later.'
      });
    })
    .finally(() => {
      setTimeout(() => {
        dispatch({
          type: CLIENT_LISTS_RESET
        });
      }, 4000);
    });
};
export const deleteCorrespondingNote = (clientId, transactionId, correspondingId) => (dispatch) => {
  dispatch({
    type: DELETE_CORRESPONDING_NOTE_PROCESSING
  });

  return ClientProfileService.deleteCorrespndingFileAndNote(clientId, transactionId, correspondingId)
    .then(({ status, data }) => {
      if (status === 200) {
        dispatch({
          type: DELETE_CORRESPONDING_NOTE_SUCCESS,
          payload: data
        });
      } else {
        dispatch({
          type: DELETE_CORRESPONDING_NOTE_FAILED,
          payload: 'Failed'
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: DELETE_TRANSACTIONSDETAILS_FAILED,
        payload:
          typeof err?.response?.data?.message === 'string'
            ? err.response.data.message
            : 'Sorry, we are unable to delete this transaction detail right now. Please try again later.'
      });
    })
    .finally(() => {
      setTimeout(() => {
        dispatch({
          type: CLIENT_LISTS_RESET
        });
      }, 4000);
    });
};

export const followUpTaskCompleted = (paramsId, othersId) => (dispatch) => {
  dispatch({
    type: FOLLOW_UP_TASK_COMPLETE_PROCESSING
  });

  return ClientProfileService.followUpTaskCompleted(paramsId, othersId)
    .then(({ status, data }) => {
      if (status === 200) {
        dispatch({
          type: FOLLOW_UP_TASK_COMPLETE_SUCCESS,
          payload: data
        });
      } else {
        dispatch({
          type: FOLLOW_UP_TASK_COMPLETE_FAILED,
          payload: 'Failed'
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: FOLLOW_UP_TASK_COMPLETE_FAILED,
        payload:
          typeof err?.response?.data?.message === 'string'
            ? err.response.data.message
            : 'Sorry, we are unable to complete this task right now. Please try again later.'
      });
    })
    .finally(() => {
      setTimeout(() => {
        dispatch({
          type: CLIENT_LISTS_RESET
        });
      }, 4000);
    });
};

export const othersdetailsfileandcorrespondence = (paramsId) => (dispatch) => {
  dispatch({
    type: OTHERS_DETAILS_FILES_AND_CORRESPONDENCE_PROCESSING
  });

  return ClientProfileService.othersdetailsfileandcorrespondence(paramsId)
    .then(({ status, data }) => {
      if (status === 200) {
        dispatch({
          type: OTHERS_DETAILS_FILES_AND_CORRESPONDENCE_SUCCESS,
          payload: data
        });
      } else {
        dispatch({
          type: OTHERS_DETAILS_FILES_AND_CORRESPONDENCE_FAILED,
          payload: 'Failed'
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: OTHERS_DETAILS_FILES_AND_CORRESPONDENCE_FAILED,
        payload:
          typeof err?.response?.data?.message === 'string'
            ? err.response.data.message
            : 'Sorry, we are unable to complete this task right now. Please try again later.'
      });
    })
    .finally(() => {
      setTimeout(() => {
        dispatch({
          type: CLIENT_LISTS_RESET
        });
      }, 4000);
    });
};


export const fileandcorrespondenceothersdetails = (paramsId) => (dispatch) => {
  dispatch({
    type: FILES_AND_CORRESPONDENCE_OTHERS_DETAILS_PROCESSING
  });

  return ClientProfileService.fileandcorrespondenceothersdetails(paramsId)
    .then(({ status, data }) => {
      if (status === 200) {
        dispatch({
          type: FILES_AND_CORRESPONDENCE_OTHERS_DETAILS_SUCCESS,
          payload: data
        });
      } else {
        dispatch({
          type: FILES_AND_CORRESPONDENCE_OTHERS_DETAILS_FAILED,
          payload: 'Failed'
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: FILES_AND_CORRESPONDENCE_OTHERS_DETAILS_FAILED,
        payload:
          typeof err?.response?.data?.message === 'string'
            ? err.response.data.message
            : 'Sorry, we are unable to complete this task right now. Please try again later.'
      });
    })
    .finally(() => {
      setTimeout(() => {
        dispatch({
          type: CLIENT_LISTS_RESET
        });
      }, 4000);
    });
};
export const getFollowupNotes = (clientId) => (dispatch) => {
  dispatch({
    type: GET_FOLLOW_UP_NOTES_PROCESSING
  });

  return ClientProfileService.getFollowupNotes(clientId)
    .then(({ status, data }) => {
      if (status === 200) {
        dispatch({
          type: GET_FOLLOW_UP_NOTES_SUCCESS,
          payload: data
        });
      } else {
        dispatch({
          type: GET_FOLLOW_UP_NOTES_FAILED,
          payload: 'Failed'
        });
      }
    })
    .catch(() => {
      dispatch({
        type: GET_FOLLOW_UP_NOTES_FAILED,
        payload: 'Failed'
      });
    });
};



