import {
  DELETE_CORRESPONDING_NOTE_SUCCESS,
  ADD_CLIENT_FAILED,
  ADD_CLIENT_PROCESSING,
  ADD_CLIENT_SUCCESS,
  CLIENT_DETAILS_FAILED,
  CLIENT_DETAILS_PROCESSING,
  CLIENT_DETAILS_SUCCESS,
  CLIENT_LISTS_FAILED,
  CLIENT_LISTS_PROCESSING,
  CLIENT_LISTS_SUCCESS,
  DELETE_CLIENT_DETAILS_FAILED,
  DELETE_CLIENT_DETAILS_PROCESSING,
  DELETE_CLIENT_DETAILS_SUCCESS,
  UPDATE_CLIENT_DETAILS_FAILED,
  UPDATE_CLIENT_DETAILS_PROCESSING,
  UPDATE_CLIENT_DETAILS_SUCCESS,
  UPDATE_CLIENT_FOLLOWUPNOTES_FAILED,
  UPDATE_CLIENT_FOLLOWUPNOTES_PROCESSING,
  UPDATE_CLIENT_FOLLOWUPNOTES_SUCCESS,
  SEARCH_PROCESSING,
  SEARCH_SUCCESS,
  SEARCH_FAILED,
  ADD_TRANSACTION_PROCESSING,
  ADD_TRANSACTION_SUCCESS,
  ADD_TRANSACTION_FAILED,
  ADD_FILES_AND_CORRESPONDENCE_PROCESSING,
  ADD_FILES_AND_CORRESPONDENCE_SUCCESS,
  ADD_FILES_AND_CORRESPONDENCE_FAILED,
  CLIENT_LISTS_RESET,
  UPDATE_TRANSACTIONSNOTES_PROCESSING,
  UPDATE_TRANSACTIONSNOTES_SUCCESS,
  UPDATE_TRANSACTIONSNOTES_FAILED,
  UPDATE_FILESNOTES_PROCESSING,
  UPDATE_FILESNOTES_SUCCESS,
  UPDATE_FILESNOTES_FAILED,
  DELETE_FILES_AND_CORRESPONDENCE_PROCESSING,
  DELETE_FILES_AND_CORRESPONDENCE_SUCCESS,
  DELETE_FILES_AND_CORRESPONDENCE_FAILED,
  DELETE_FILES_PROCESSING,
  DELETE_FILES_SUCCESS,
  DELETE_FILES_FAILED,
  UPDATE_CORRESPONDENCE_PROCESSING,
  UPDATE_CORRESPONDENCE_SUCCESS,
  UPDATE_CORRESPONDENCE_FAILED,
  DELETE_CORRESPONDENCE_PROCESSING,
  DELETE_CORRESPONDENCE_SUCCESS,
  DELETE_CORRESPONDENCE_FAILED,
  UPDATE_FOLLOWUPNOTE_PROCESSING,
  UPDATE_FOLLOWUPNOTE_SUCCESS,
  UPDATE_FOLLOWUPNOTE_FAILED,
  DELETE_FOLLOWUPNOTE_PROCESSING,
  DELETE_FOLLOWUPNOTE_SUCCESS,
  DELETE_FOLLOWUPNOTE_FAILED,
  EDIT_TRANSACTIONSNOTES_PROCESSING,
  EDIT_TRANSACTIONSNOTES_SUCCESS,
  EDIT_TRANSACTIONSNOTES_FAILED,
  DELETE_TRANSACTIONSNOTES_PROCESSING,
  DELETE_TRANSACTIONSNOTES_SUCCESS,
  DELETE_TRANSACTIONSNOTES_FAILED,
  ADD_TRANSACTION_RESET,
  ADD_FILES_AND_CORRESPONDENCE_RESET,
  DELETE_FILES_AND_CORRESPONDENCE_RESET,
  DELETE_FILES_RESET,
  DELETE_CORRESPONDENCE_RESET,
  UPDATE_CORRESPONDENCE_RESET,
  DELETE_TRANSACTIONSDETAILS_PROCESSING,
  DELETE_TRANSACTIONSDETAILS_SUCCESS,
  DELETE_TRANSACTIONSDETAILS_FAILED,
  ADDNEW_CLIENT_PROCESSING,
  ADDNEW_CLIENT_SUCCESS,
  ADDNEW_CLIENT_FAILED,
  DELETE_CORRESPONDING_NOTE_PROCESSING,
  DELETE_CORRESPONDING_NOTE_FAILED,
  FOLLOW_UP_TASK_COMPLETE_PROCESSING,
  FOLLOW_UP_TASK_COMPLETE_SUCCESS,
  FOLLOW_UP_TASK_COMPLETE_FAILED,
  OTHERS_DETAILS_FILES_AND_CORRESPONDENCE_PROCESSING,
  OTHERS_DETAILS_FILES_AND_CORRESPONDENCE_SUCCESS,
  OTHERS_DETAILS_FILES_AND_CORRESPONDENCE_FAILED,
  FILES_AND_CORRESPONDENCE_OTHERS_DETAILS_PROCESSING,
  FILES_AND_CORRESPONDENCE_OTHERS_DETAILS_SUCCESS,
  FILES_AND_CORRESPONDENCE_OTHERS_DETAILS_FAILED,
  GET_FOLLOW_UP_NOTES_PROCESSING,
  GET_FOLLOW_UP_NOTES_SUCCESS,
  GET_FOLLOW_UP_NOTES_FAILED
} from '../../store/types/types';

const initialState = {
  status: false,
  severity: '',
  message: '',
  loading: false,
  userdata: [],
  updatedInfo: {},
  deletedInfo: {},
  clientDetails: {},
  clientFollowUpNotesUpdatedInfo: {},
  clientSearchData: [],
  correspondingfilesAndNotes: {},
  filesNotes: [],
  transactionsDetails: {},
  transactionsNotes: {},
  deletedcorrespondingfilesAndNotes: {},
  deletedFiles: {},
  updatedcorrespondencenotes: {},
  deletedcorrespondencenotes: {},
  deletedfollowupnote: {},
  updatedfollowupnote: {},
  updatedtransactionsdetails: {},
  deletedtransactionsNotes: {},
  deletetransactionsDetails: {},
  deleteCorrespondingNote: {},
  followUpTaskCompleted: {},
  othersdetailsfilesandcorrespondence: [],
  filesandcorrespondenceothersdetails: [],
  getFollowupNotes: [],
  addFollowupSuccessMsg: null,
  followUpTaskCompletedsuccessmsg: null,
  deletedfollowupnotesuccessmsg: null
};

export const clientReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_CLIENT_PROCESSING:
      return {
        ...state,
        loading: true,
        status: false,
        severity: '',
        message: ''
      };
    case ADD_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        status: true,
        severity: 'success',
        message: 'Congratulations, this client profile  has been posted successfully.'
      };
    case CLIENT_LISTS_RESET:
      return {
        ...state,
        status: false,
        severity: '',
        message: ''
      };
    case ADD_CLIENT_FAILED:
      return {
        ...state,
        loading: false,
        status: true,
        severity: 'warning',
        message: action.payload
      };

    //Duplicate
    case ADDNEW_CLIENT_PROCESSING:
      return {
        ...state,
        loading: true,
        status: false,
        severity: '',
        message: ''
      };
    case ADDNEW_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        status: true,
        severity: 'success',
        message: 'Congratulations, this client profile  has been posted successfully.'
      };
    // case CLIENT_LISTS_RESET:
    //   return {
    //     ...state,
    //     status: false,
    //     severity: '',
    //     message: ''
    //   };
    case ADDNEW_CLIENT_FAILED:
      return {
        ...state,
        loading: false,
        status: true,
        severity: 'warning',
        message: action.payload
      };

    case CLIENT_LISTS_PROCESSING:
      return {
        ...state,
        loading: true,
        userdata: []
      };
    case CLIENT_LISTS_SUCCESS:
      return {
        ...state,
        loading: false,
        userdata: action.payload
      };
    case CLIENT_LISTS_FAILED:
      return {
        ...state,
        loading: false,
        errorClientLists: action.payload,
        userdata: []
      };

    case UPDATE_CLIENT_DETAILS_PROCESSING:
      return {
        ...state,
        loading: true,
        updatedInfo: {},
        status: false,
        severity: '',
        message: ''
      };
    case UPDATE_CLIENT_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        updatedInfo: action.payload,
        successClientUpdate: true,
        status: true,
        severity: 'success',
        message: 'Congratulations, this client profile detail has been updated successfully.'
      };
    case UPDATE_CLIENT_DETAILS_FAILED:
      return {
        ...state,
        loading: false,
        errorClientUpdate: action.payload,
        successClientUpdate: false,
        updatedInfo: {},
        status: true,
        severity: 'warning',
        message: action.payload
      };

    case DELETE_CLIENT_DETAILS_PROCESSING:
      return {
        ...state,
        loading: true,
        status: false,
        severity: '',
        message: ''
      };
    case DELETE_CLIENT_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        deletedInfo: action.payload,
        successClientDelete: true,
        status: true,
        severity: 'success',
        message: 'Congratulations, this client has been deleted successfully.'
      };
    case DELETE_CLIENT_DETAILS_FAILED:
      return {
        ...state,
        loading: false,
        errorClientDelete: action.payload,
        status: true,
        severity: 'warning',
        message: 'Sorry, we are unable to delete this client right now. Please try again later.'
      };

    case CLIENT_DETAILS_PROCESSING:
      return {
        ...state,
        loading: true,
        clientDetails: []
      };
    case CLIENT_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        clientDetails: action.payload
      };
    case CLIENT_DETAILS_FAILED:
      return {
        ...state,
        loading: false,
        errorClientDetails: action.payload,
        clientDetails: []
      };
    case UPDATE_CLIENT_FOLLOWUPNOTES_PROCESSING:
      return {
        ...state,
        loading: true,
        status: false,
        severity: '',
        message: '',
        clientFollowUpNotesUpdatedInfo: {},
        addFollowupSuccessMsg: null
      };
    case UPDATE_CLIENT_FOLLOWUPNOTES_SUCCESS:
      return {
        ...state,
        clientFollowUpNotesUpdatedInfo: action.payload,
        loading: false,
        status: true,
        severity: 'success',
        message: 'Congratulations, this note has been posted successfully.',
        addFollowupSuccessMsg: 'success'
      };
    case UPDATE_CLIENT_FOLLOWUPNOTES_FAILED:
      return {
        ...state,
        clientFollowUpNotesUpdatedInfo: {},
        loading: false,
        status: true,
        severity: 'warning',
        message: 'Sorry, we are unable to post this note right now. Please try again later.',
        addFollowupSuccessMsg: null
      };

    case SEARCH_PROCESSING:
      return {
        ...state,
        loading: true,
        clientSearchData: []
      };
    case SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        clientSearchData: action.payload
      };
    case SEARCH_FAILED:
      return {
        ...state,
        loading: false,
        clientSearchData: []
      };
    case ADD_TRANSACTION_PROCESSING:
      return {
        ...state,
        loading: true,
        transactionsDetails: {},
        status: false,
        severity: '',
        message: ''
      };
    case ADD_TRANSACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        transactionsDetails: action.payload,
        status: true,
        severity: 'success',
        message: 'Congratulations, transaction details  has been posted successfully.'
      };
    case ADD_TRANSACTION_FAILED:
      return {
        ...state,
        loading: false,
        transactionsDetails: {},
        status: true,
        severity: 'warning',
        message: action.payload
      };
    case ADD_TRANSACTION_RESET:
      return {
        ...state,
        loading: false,
        status: false,
        severity: '',
        message: ''
      };

    case ADD_FILES_AND_CORRESPONDENCE_PROCESSING:
      return {
        ...state,
        loading: true,
        status: false,
        correspondingfilesAndNotes: [],
        severity: '',
        message: ''
      };
    case ADD_FILES_AND_CORRESPONDENCE_SUCCESS:
      return {
        ...state,
        loading: false,
        status: true,
        correspondingfilesAndNotes: action.payload,
        severity: 'success',
        message: 'Congratulations, files and correspondence  has been posted successfully.'
      };
    case ADD_FILES_AND_CORRESPONDENCE_FAILED:
      return {
        ...state,
        loading: false,
        status: true,
        correspondingfilesAndNotes: [],
        severity: 'warning',
        message: action.payload
      };
    case ADD_FILES_AND_CORRESPONDENCE_RESET:
      return {
        ...state,
        loading: false,
        status: false,
        severity: '',
        message: ''
      };
    case DELETE_FILES_AND_CORRESPONDENCE_PROCESSING:
      return {
        ...state,
        loading: true,
        status: false,
        deletedcorrespondingfilesAndNotes: [],
        severity: '',
        message: ''
      };
    case DELETE_FILES_AND_CORRESPONDENCE_SUCCESS:
      return {
        ...state,
        loading: false,
        status: true,
        deletedcorrespondingfilesAndNotes: action.payload,
        severity: 'success',
        message: 'Congratulations, files and correspondance  has been deleted successfully.'
      };
    case DELETE_FILES_AND_CORRESPONDENCE_FAILED:
      return {
        ...state,
        loading: false,
        status: true,
        deletedcorrespondingfilesAndNotes: [],
        severity: 'warning',
        message: action.payload
      };
    case DELETE_FILES_AND_CORRESPONDENCE_RESET:
      return {
        ...state,
        loading: false,
        status: false,
        severity: '',
        message: ''
      };
    case DELETE_FILES_PROCESSING:
      return {
        ...state,
        loading: true,
        status: false,
        deletedfiles: [],
        severity: '',
        message: ''
      };
    case DELETE_FILES_SUCCESS:
      return {
        ...state,
        loading: false,
        status: true,
        deletedfiles: action.payload,
        severity: 'success',
        message: 'Congratulations, files has been deleted successfully.'
      };
    case DELETE_FILES_FAILED:
      return {
        ...state,
        loading: false,
        status: true,
        deletedfiles: [],
        severity: 'warning',
        message: action.payload
      };
    case DELETE_FILES_RESET:
      return {
        ...state,
        loading: false,
        status: false,
        severity: '',
        message: ''
      };
    case UPDATE_CORRESPONDENCE_PROCESSING:
      return {
        ...state,
        loading: true,
        status: false,
        updatedcorrespondencenotes: [],
        severity: '',
        message: ''
      };
    case UPDATE_CORRESPONDENCE_SUCCESS:
      return {
        ...state,
        loading: false,
        status: true,
        updatedcorrespondencenotes: action.payload,
        severity: 'success',
        message: 'Congratulations, notes has been updated successfully.'
      };
    case UPDATE_CORRESPONDENCE_FAILED:
      return {
        ...state,
        loading: false,
        status: true,
        updatedcorrespondencenotes: [],
        severity: 'warning',
        message: action.payload
      };
    case UPDATE_CORRESPONDENCE_RESET:
      return {
        ...state,
        loading: false,
        status: false,
        severity: '',
        message: ''
      };

    case DELETE_CORRESPONDENCE_PROCESSING:
      return {
        ...state,
        loading: true,
        status: false,
        deletedcorrespondencenotes: {},
        severity: '',
        message: ''
      };
    case DELETE_CORRESPONDENCE_SUCCESS:
      return {
        ...state,
        loading: false,
        status: true,
        deleteddcorrespondencenotes: action.payload,
        severity: 'success',
        message: 'Congratulations, notes has been deleted successfully.'
      };
    case DELETE_CORRESPONDENCE_FAILED:
      return {
        ...state,
        loading: false,
        status: true,
        deleteddcorrespondencenotes: {},
        severity: 'warning',
        message: action.payload
      };
    case DELETE_CORRESPONDENCE_RESET:
      return {
        ...state,
        loading: false,
        status: false,
        severity: '',
        message: ''
      };
    case UPDATE_TRANSACTIONSNOTES_PROCESSING:
      return {
        ...state,
        loading: true,
        updatedtransactionsdetails: {},
        status: false,
        severity: '',
        message: ''
      };
    case UPDATE_TRANSACTIONSNOTES_SUCCESS:
      return {
        ...state,
        loading: false,
        updatedtransactionsdetails: action.payload,
        successClientUpdate: true,
        status: true,
        severity: 'success',
        message: 'Congratulations, this transaction details has been posted successfully.'
      };
    case UPDATE_TRANSACTIONSNOTES_FAILED:
      return {
        ...state,
        loading: false,
        errorClientUpdate: action.payload,
        successClientUpdate: false,
        updatedtransactionsdetails: {},
        status: true,
        severity: 'warning',
        message: action.payload
      };
    case EDIT_TRANSACTIONSNOTES_PROCESSING:
      return {
        ...state,
        loading: true,
        transactionsNotes: {},
        status: false,
        severity: '',
        message: ''
      };
    case EDIT_TRANSACTIONSNOTES_SUCCESS:
      return {
        ...state,
        loading: false,
        transactionsNotes: action.payload,
        status: true,
        severity: 'success',
        message: 'Congratulations, this transaction notes has been updated successfully.'
      };
    case EDIT_TRANSACTIONSNOTES_FAILED:
      return {
        ...state,
        loading: false,
        transactionsNotes: {},
        status: true,
        severity: 'warning',
        message: action.payload
      };
    case DELETE_TRANSACTIONSNOTES_PROCESSING:
      return {
        ...state,
        loading: true,
        deletedtransactionsNotes: {},
        status: false,
        severity: '',
        message: ''
      };
    case DELETE_TRANSACTIONSNOTES_SUCCESS:
      return {
        ...state,
        loading: false,
        deletedtransactionsNotes: action.payload,
        status: true,
        severity: 'success',
        message: 'Congratulations, this transaction notes has been deleted successfully.'
      };
    case DELETE_TRANSACTIONSNOTES_FAILED:
      return {
        ...state,
        loading: false,
        deletedtransactionsNotes: {},
        status: true,
        severity: 'warning',
        message: action.payload
      };

    case UPDATE_FILESNOTES_PROCESSING:
      return {
        ...state,
        loading: true,
        filesNotes: [],
        status: false,
        severity: '',
        message: ''
      };
    case UPDATE_FILESNOTES_SUCCESS:
      return {
        ...state,
        loading: false,
        successClientUpdate: true,
        filesNotes: action.payload,
        status: true,
        severity: 'success',
        message: 'Congratulations, this file notes has been posted successfully.'
      };
    case UPDATE_FILESNOTES_FAILED:
      return {
        ...state,
        loading: false,
        errorClientUpdate: action.payload,
        successClientUpdate: false,
        filesNotes: [],
        status: true,
        severity: 'warning',
        message: action.payload
      };
    case UPDATE_FOLLOWUPNOTE_PROCESSING:
      return {
        ...state,
        loading: true,
        updatedfollowupnote: {},
        status: false,
        severity: '',
        message: '',
        updatedfollowupnotesuccessmsg: null
      };
    case UPDATE_FOLLOWUPNOTE_SUCCESS:
      return {
        ...state,
        loading: false,
        successClientUpdate: true,
        updatedfollowupnote: action.payload,
        status: true,
        severity: 'success',
        message: 'Congratulations, this notes has been updated successfully.',
        updatedfollowupnotesuccessmsg: true
      };
    case UPDATE_FOLLOWUPNOTE_FAILED:
      return {
        ...state,
        loading: false,
        updatedfollowupnote: {},
        status: true,
        severity: 'warning',
        message: action.payload,
        updatedfollowupnotesuccessmsg: null
      };
    case DELETE_FOLLOWUPNOTE_PROCESSING:
      return {
        ...state,
        loading: true,
        deletedfollowupnote: {},
        status: false,
        severity: '',
        message: '',
        deletedfollowupnotesuccessmsg: null
      };
    case DELETE_FOLLOWUPNOTE_SUCCESS:
      return {
        ...state,
        loading: false,
        deletedfollowupnote: action.payload,
        status: true,
        severity: 'success',
        message: 'Congratulations, this notes has been deleted successfully.',
        deletedfollowupnotesuccessmsg: true
      };
    case DELETE_FOLLOWUPNOTE_FAILED:
      return {
        ...state,
        loading: false,
        deletedfollowupnote: {},
        status: true,
        severity: 'warning',
        message: action.payload,
        deletedfollowupnotesuccessmsg: null
      };

    case DELETE_TRANSACTIONSDETAILS_PROCESSING:
      return {
        ...state,
        loading: true,
        deletetransactionsDetails: {},
        status: false,
        severity: '',
        message: ''
      };
    case DELETE_TRANSACTIONSDETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        deletetransactionsDetails: action.payload,
        status: true,
        severity: 'success',
        message: 'Congratulations, this notes has been deleted successfully.'
      };
    case DELETE_TRANSACTIONSDETAILS_FAILED:
      return {
        ...state,
        loading: false,
        deletetransactionsDetails: {},
        status: true,
        severity: 'warning',
        message: action.payload
      };

    case DELETE_CORRESPONDING_NOTE_PROCESSING:
      return {
        ...state,
        loading: true,
        status: false,
        severity: '',
        message: ''
      };
    case DELETE_CORRESPONDING_NOTE_SUCCESS:
      return {
        ...state,
        loading: false,
        deleteCorrespondingNote: action.payload,
        status: true,
        severity: 'success',
        message: 'Congratulations, this corresponding Files and notes have been deleted successfully.'
      };
    case DELETE_CORRESPONDING_NOTE_FAILED:
      return {
        ...state,
        loading: false,
        status: true,
        severity: 'warning',
        message: 'Sorry, we are unable to delete files and notes right now. Please try again later.'
      };
    case FOLLOW_UP_TASK_COMPLETE_PROCESSING:
      return {
        ...state,
        loading: true,
        status: false,
        severity: '',
        message: '',
        followUpTaskCompleted: {},
        followUpTaskCompletedsuccessmsg: null
      };
    case FOLLOW_UP_TASK_COMPLETE_SUCCESS:
      return {
        ...state,
        followUpTaskCompleted: action.payload,
        loading: false,
        status: true,
        severity:
          action.payload === 'Congratulations, this task has been completed successfully.' ? 'success' : 'warning',
        message: action.payload,
        followUpTaskCompletedsuccessmsg: true
      };
    case FOLLOW_UP_TASK_COMPLETE_FAILED:
      return {
        ...state,
        followUpTaskCompleted: {},
        loading: false,
        status: true,
        severity: 'warning',
        message: action.payload,
        followUpTaskCompletedsuccessmsg: null
      };
    case OTHERS_DETAILS_FILES_AND_CORRESPONDENCE_PROCESSING:
      return {
        ...state,
        loading: true,
        othersdetailsfilesandcorrespondence: []
      };
    case OTHERS_DETAILS_FILES_AND_CORRESPONDENCE_SUCCESS:
      return {
        ...state,
        othersdetailsfilesandcorrespondence: action.payload,
        loading: false
      };
    case OTHERS_DETAILS_FILES_AND_CORRESPONDENCE_FAILED:
      return {
        ...state,
        othersdetailsfilesandcorrespondence: [],
        loading: false
      };
    case FILES_AND_CORRESPONDENCE_OTHERS_DETAILS_PROCESSING:
      return {
        ...state,
        loading: true,
        filesandcorrespondenceothersdetails: []
      };
    case FILES_AND_CORRESPONDENCE_OTHERS_DETAILS_SUCCESS:
      return {
        ...state,
        filesandcorrespondenceothersdetails: action.payload,
        loading: false
      };
    case FILES_AND_CORRESPONDENCE_OTHERS_DETAILS_FAILED:
      return {
        ...state,
        filesandcorrespondenceothersdetails: [],
        loading: false
      };
    case GET_FOLLOW_UP_NOTES_PROCESSING:
      return {
        ...state,
        loading: true,
        getFollowupNotes: []
      };
    case GET_FOLLOW_UP_NOTES_SUCCESS:
      return {
        ...state,
        getFollowupNotes: action.payload,
        loading: false
      };
    case GET_FOLLOW_UP_NOTES_FAILED:
      return {
        ...state,
        getFollowupNotes: [],
        loading: false
      };

    default:
      return state;
  }
};
