import UserService from '../../services/user-service';
import {
  USER_LIST_PROCESSING,
  USER_LIST_SUCCESS,
  USER_LIST_FAILED,
  UPDATE_USER_PROCESSING,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILED,
  GET_USER_PROCESSING,
  GET_USER_SUCCESS,
  GET_USER_FAILED,
  UPDATE_USER_RESET,
  DELETE_USER_PROCESSING,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILED,
  DELETE_USER_RESET,
  UN_DELETE_USER_PROCESSING,
  UN_DELETE_USER_SUCCESS,
  UN_DELETE_USER_FAILED,
  UPLOAD_PROFILE_PICTURE_PROCESSING,
  UPLOAD_PROFILE_PICTURE_SUCCESS,
  UPLOAD_PROFILE_PICTURE_FAILED,
  UPLOAD_PROFILE_PICTURE_RESET,
} from '../types/types';

//get allcrm users
export const getCrmUsers = () => (dispatch) => {
  dispatch({
    type: USER_LIST_PROCESSING
  });

  return UserService.getcrmusers()
    .then(({ status, data }) => {
      if (status === 200) {
        dispatch({
          type: USER_LIST_SUCCESS,
          payload: data
        });
      } else {
        dispatch({
          type: USER_LIST_FAILED,
          payload: 'Failed'
        });
      }
    })
    .catch(() => {
      dispatch({
        type: USER_LIST_FAILED,
        payload: 'Failed'
      });
    });
};

//get crm users by id
export const getCrmUser = (id) => (dispatch) => {
  dispatch({
    type: GET_USER_PROCESSING
  });

  return UserService.getcrmuser(id)
    .then(({ status, data }) => {
      if (status === 200) {
        dispatch({
          type: GET_USER_SUCCESS,
          payload: data
        });
      } else {
        dispatch({
          type: GET_USER_FAILED,
          payload: 'Failed'
        });
      }
    })
    .catch(() => {
      dispatch({
        type: GET_USER_FAILED,
        payload: 'Failed'
      });
    });
};

//update crm users
export const updateAction = (updatedData, id, resetForm, setPristine, history) => (dispatch) => {
  dispatch({
    type: UPDATE_USER_PROCESSING
  });

  return UserService.updateuser(updatedData, id)
    .then(({ status, data }) => {
      if (status === 200) {
        dispatch({
          type: UPDATE_USER_SUCCESS,
          payload: data
        });
        resetForm();
        setPristine(false);
        setTimeout(() => history.push(`/user-detail/${id}`), 100)
        // window.onbeforeunload = null;
        // window.location.href = '/';
      } else {
        dispatch({
          type: UPDATE_USER_FAILED,
          payload: 'Failed'
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: UPDATE_USER_FAILED,
        payload:
          typeof err?.response?.data?.message === 'string'
            ? err.response.data.message
            : 'Sorry, but we are unable to update the user data right now. Please try again later.'
      });
    })
    .finally(() => {
      setTimeout(() => {
        resetUpdateState();
      }, 5000);
    });
};

export const deleteUserAction = (id) => (dispatch) => {
  dispatch({
    type: DELETE_USER_PROCESSING
  });

  return UserService.deleteuser(id)
    .then(({ status, data }) => {
      if (status === 200) {
        dispatch({
          type: DELETE_USER_SUCCESS,
          payload: data
        });
        // dispatch(getCrmUsers());
      } else {
        dispatch({
          type: DELETE_USER_FAILED,
          payload: 'Failed'
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: DELETE_USER_FAILED,
        payload:
          typeof err?.response?.data?.message === 'string'
            ? err.response.data.message
            : 'Sorry, but we are unable to delete the user data right now. Please try again later.'
      });
    })
    .finally(() => {
      setTimeout(() => {
        dispatch({
          type: DELETE_USER_RESET
        });
      }, 3000);
    });
};
export const resetUpdateState = () => (dispatch) => {
  dispatch({ type: UPDATE_USER_RESET });
};

export const unDeleteUserAction = (id) => (dispatch) => {
  dispatch({
    type: UN_DELETE_USER_PROCESSING
  });

  return UserService.unDeleteuser(id)
    .then(({ status, data }) => {
      if (status === 200) {
        dispatch({
          type: UN_DELETE_USER_SUCCESS,
          payload: data
        });
        // dispatch(getCrmUsers());
      } else {
        dispatch({
          type: UN_DELETE_USER_FAILED,
          payload: 'Failed'
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: UN_DELETE_USER_FAILED,
        payload:
          typeof err?.response?.data?.message === 'string'
            ? err.response.data.message
            : 'Sorry, but we are unable to undelete the user data right now. Please try again later.'
      });
    })
    .finally(() => {
      setTimeout(() => {
        dispatch({
          type: DELETE_USER_RESET
        });
      }, 3000);
    });
};


export const uploadUserProfilePicture = (data) => (dispatch) => {
  dispatch({
    type: UPLOAD_PROFILE_PICTURE_PROCESSING
  });

  return UserService.uploadProfilePicture(data).then(({ status, data }) => {
    if (status === 200) {
      dispatch({
        type: UPLOAD_PROFILE_PICTURE_SUCCESS,
        payload: data
      });

    } else {
      dispatch({
        type: UPLOAD_PROFILE_PICTURE_FAILED,
        payload: 'Error occured while trying to upload your picture. Please try again later.'
      });
    }
  }).catch((err) => {
    dispatch({
      type: UPLOAD_PROFILE_PICTURE_FAILED,
      payload: 'Error occured while trying to upload your picture. Please try again later.'
    });
  }).finally(() => {
    setTimeout(() => {
      dispatch({
        type: UPLOAD_PROFILE_PICTURE_RESET
      });
    }, 3000);
  });
};

export const resetUploadStatus = () => (dispatch) => {
  dispatch({ type: UPLOAD_PROFILE_PICTURE_RESET });
};
