export const ADD_CLIENT_PROCESSING = 'ADD_CLIENT_PROCESSING';
export const ADD_CLIENT_SUCCESS = 'ADD_CLIENT_SUCCESS';
export const ADD_CLIENT_FAILED = 'ADD_CLIENT_FAILED';

export const ADDNEW_CLIENT_PROCESSING = 'ADDNEW_CLIENT_PROCESSING';
export const ADDNEW_CLIENT_SUCCESS = 'ADDNEW_CLIENT_SUCCESS';
export const ADDNEW_CLIENT_FAILED = 'ADDNEW_CLIENT_FAILED';

export const LOGIN_PROCESSING = 'LOGIN_PROCESSING';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGIN_RESET = 'LOGIN_RESET';
export const RESET_LOGIN_STATE = 'RESET_LOGIN_STATE';

export const CLIENT_LISTS_PROCESSING = 'CLIENT_LISTS_PROCESSING';
export const CLIENT_LISTS_SUCCESS = 'CLIENT_LISTS_SUCCESS';
export const CLIENT_LISTS_RESET = 'CLIENT_LISTS_RESET';
export const CLIENT_LISTS_FAILED = 'CLIENT_LISTS_FAILED';

export const CLIENT_DETAILS_PROCESSING = 'CLIENT_DETAILS_PROCESSING';
export const CLIENT_DETAILS_SUCCESS = 'CLIENT_DETAILS_SUCCESS';
export const CLIENT_DETAILS_FAILED = 'CLIENT_DETAILS_FAILED';

export const UPDATE_CLIENT_DETAILS_PROCESSING = 'UPDATE_CLIENT_DETAILS_PROCESSING';
export const UPDATE_CLIENT_DETAILS_SUCCESS = 'UPDATE_CLIENT_DETAILS_SUCCESS';
export const UPDATE_CLIENT_DETAILS_FAILED = 'UPDATE_CLIENT_DETAILS_FAILED';

export const DELETE_FOLLOWUPNOTE_PROCESSING = 'DELETE_FOLLOWUPNOTE_PROCESSING';
export const DELETE_FOLLOWUPNOTE_SUCCESS = 'DELETE_FOLLOWUPNOTE_SUCCESS';
export const DELETE_FOLLOWUPNOTE_FAILED = 'DELETE_FOLLOWUPNOTE_FAILED';

export const UPDATE_FOLLOWUPNOTE_PROCESSING = 'UPDATE_FOLLOWUPNOTE_PROCESSING';
export const UPDATE_FOLLOWUPNOTE_SUCCESS = 'UPDATE_FOLLOWUPNOTE_SUCCESS';
export const UPDATE_FOLLOWUPNOTE_FAILED = 'UPDATE_FOLLOWUPNOTE_FAILED';

export const DELETE_CLIENT_DETAILS_PROCESSING = 'DELETE_CLIENT_DETAILS_PROCESSING';
export const DELETE_CLIENT_DETAILS_SUCCESS = 'DELETE_CLIENT_DETAILS_SUCCESS';
export const DELETE_CLIENT_DETAILS_FAILED = 'DELETE_CLIENT_DETAILS_FAILED';

export const UPDATE_CLIENT_FOLLOWUPNOTES_PROCESSING = 'UPDATE_CLIENT_FOLLOWUPNOTES_PROCESSING';
export const UPDATE_CLIENT_FOLLOWUPNOTES_SUCCESS = 'UPDATE_CLIENT_FOLLOWUPNOTES_SUCCESS';
export const UPDATE_CLIENT_FOLLOWUPNOTES_FAILED = 'UPDATE_CLIENT_FOLLOWUPNOTES_FAILED';

export const UPDATE_PASSWORD_PROCESSING = 'UPDATE_PASSWORD_PROCESSING';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_FAILED = 'UPDATE_PASSWORD_FAILED';

export const SEND_EMAIL_PROCESSING = 'SEND_EMAIL_PROCESSING';
export const SEND_EMAIL_SUCCESS = 'SEND_EMAIL_SUCCESS';
export const SEND_EMAIL_FAILED = 'SEND_EMAIL_FAILED';

export const RESET_PASSWORD_PROCESSING = 'RESET_PASSWORD_PROCESSING';
export const RESET_PASSWORD_SUCCESS = ' RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED';

export const SEARCH_PROCESSING = 'SEARCH_PROCESSING';
export const SEARCH_SUCCESS = ' SEARCH_SUCCESS';
export const SEARCH_FAILED = 'SEARCH_FAILED';

export const SIGNUP_PROCESSING = 'SIGNUP_PROCESSING';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAILED = 'SIGNUP_FAILED';

export const SIGNUP_VERIFY_EMAIL_PROCESSING = 'SIGNUP_VERIFY_EMAIL_PROCESSING';
export const SIGNUP_VERIFY_EMAIL_SUCCESS = 'SIGNUP_VERIFY_EMAIL_SUCCESS';
export const SIGNUP_VERIFY_EMAIL_FAILED = 'SIGNUP_VERIFY_EMAIL_FAILED';

export const USER_LIST_PROCESSING = 'USER_LIST_PROCESSING';
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS';
export const USER_LIST_FAILED = 'USER_LIST_FAILED';

export const UPLOAD_PROFILE_PICTURE_PROCESSING = 'UPLOAD_PROFILE_PICTURE_PROCESSING';
export const UPLOAD_PROFILE_PICTURE_SUCCESS = 'UPLOAD_PROFILE_PICTURE_SUCCESS';
export const UPLOAD_PROFILE_PICTURE_FAILED = 'UPLOAD_PROFILE_PICTURE_FAILED';
export const UPLOAD_PROFILE_PICTURE_RESET = 'UPLOAD_PROFILE_PICTURE_RESET';

export const GET_USER_PROCESSING = 'GET_USER_PROCESSING';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILED = 'GET_USER_FAILED';

export const UPDATE_USER_PROCESSING = 'UPDATE_CRM_USER_PROCESSING';
export const UPDATE_USER_SUCCESS = 'UPDATE_CRM_USER_SUCCESS';
export const UPDATE_USER_FAILED = 'UPDATE_CRM_USER_FAILED';
export const UPDATE_USER_RESET = 'UPDATE_USER_RESET';

export const DELETE_USER_PROCESSING = 'DELETE_USER_PROCESSING';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILED = 'DELETE_USER_FAILED';
export const DELETE_USER_RESET = 'DELETE_USER_RESET';

export const UN_DELETE_USER_PROCESSING = ' UN_DELETE_USER_PROCESSING';
export const UN_DELETE_USER_SUCCESS = ' UN_DELETE_USER_SUCCESS';
export const UN_DELETE_USER_FAILED = ' UN_DELETE_USER_FAILED';
// export const DELETE_USER_RESET = 'DELETE_USER_RESET';

export const USER = 'USER';
export const ADMIN = 'ADMIN';
export const SUPER_ADMIN = 'SUPER_ADMIN';

export const CREATE_CATEGORY_PROCESSING = 'CREATE_CATEGORY_PROCESSING';
export const CREATE_CATEGORY_SUCCESS = 'CREATE_CATEGORY_SUCCESS';
export const CREATE_CATEGORY_FAILED = 'CREATE_CATEGORY_FAILED';
export const RESET_MESSAGE_STATE = 'RESET_MESSAGE_STATE';

export const GET_CATEGORY_PROCESSING = 'GET_CATEGORY_PROCESSING';
export const GET_CATEGORY_SUCCESS = 'GET_CATEGORY_SUCCESS';
export const GET_CATEGORY_FAILED = 'GET_CATEGORY_FAILED';

export const UPDATE_CATEGORY_PROCESSING = 'UPDATE_CATEGORY_PROCESSING';
export const UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS';
export const UPDATE_CATEGORY_FAILED = 'UPDATE_CATEGORY_FAILED';

export const DELETE_CATEGORY_PROCESSING = 'DELETE_CATEGORY_PROCESSING';
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS';
export const DELETE_CATEGORY_FAILED = 'DELETE_CATEGORY_FAILED';

export const BULK_EMAIL_SEND_PROCESSING = 'BULK_EMAIL_SEND_PROCESSING';
export const BULK_EMAIL_SEND_SUCCESS = 'BULK_EMAIL_SEND_SUCCESS';
export const BULK_EMAIL_SEND_FAILED = 'BULK_EMAIL_SEND_FAILED';
export const BULK_EMAIL_SEND_RESET = 'BULK_EMAIL_SEND_RESET';

export const GET_EVENT_LIST_PROCESSING = 'GET_EVENT_LIST_PROCESSING';
export const GET_EVENT_LIST_SUCCESS = 'GET_EVENT_LIST_SUCCESS';
export const GET_EVENT_LIST_FAILED = 'GET_EVENT_LIST_FAILED';

export const DELETE_EVENT_PROCESSING = 'DELETE_EVENT_PROCESSING';
export const DELETE_EVENT_SUCCESS = 'DELETE_EVENT_SUCCESS';
export const DELETE_EVENT_FAILED = 'DELETE_EVENT_FAILED';
export const DELETE_EVENT_RESET = 'DELETE_EVENT_RESET';

export const UPDATE_EVENT_PROCESSING = 'UPDATE_EVENT_PROCESSING';
export const UPDATE_EVENT_SUCCESS = 'UPDATE_EVENT_SUCCESS';
export const UPDATE_EVENT_FAILED = 'UPDATE_EVENT_FAILED';
export const UPDATE_EVENT_RESET = 'UPDATE_EVENT_RESET';

export const GET_SELF_EVENT_LIST_PROCESSING = 'GET_SELF_EVENT_LIST_PROCESSING';
export const GET_SELF_EVENT_LIST_SUCCESS = 'GET_SELF_EVENT_LIST_SUCCESS';
export const GET_SELF_EVENT_LIST_FAILED = 'GET_SELF_EVENT_LIST_FAILED';

export const ADD_EVENT_PROCESSING = 'ADD_EVENT_PROCESSING';
export const ADD_EVENT_SUCCESS = 'ADD_EVENT_SUCCESS';
export const ADD_EVENT_FAILED = 'ADD_EVENT_FAILED';

export const ADD_TRANSACTION_PROCESSING = 'ADD_TRANSACTION_PROCESSING';
export const ADD_TRANSACTION_SUCCESS = 'ADD_TRANSACTION_SUCCESS';
export const ADD_TRANSACTION_FAILED = 'ADD_TRANSACTION_FAILED';
export const ADD_TRANSACTION_RESET = 'ADD_TRANSACTION_RESET';

export const ADD_FILES_AND_CORRESPONDENCE_PROCESSING = 'ADD_FILES_AND_CORRESPONDENCE_PROCESSING';
export const ADD_FILES_AND_CORRESPONDENCE_SUCCESS = 'ADD_FILES_AND_CORRESPONDENCE_SUCCESS';
export const ADD_FILES_AND_CORRESPONDENCE_FAILED = 'ADD_FILES_AND_CORRESPONDENCE_FAILED';
export const ADD_FILES_AND_CORRESPONDENCE_RESET = 'ADD_FILES_AND_CORRESPONDENCE_RESET';

export const DELETE_FILES_AND_CORRESPONDENCE_PROCESSING = 'DELETE_FILES_AND_CORRESPONDENCE_PROCESSING';
export const DELETE_FILES_AND_CORRESPONDENCE_SUCCESS = 'DELETE_FILES_AND_CORRESPONDENCE_SUCCESS';
export const DELETE_FILES_AND_CORRESPONDENCE_FAILED = 'DELETE_FILES_AND_CORRESPONDENCE_FAILED';
export const DELETE_FILES_AND_CORRESPONDENCE_RESET = 'DELETE_FILES_AND_CORRESPONDENCE_RESET';

export const DELETE_FILES_PROCESSING = 'DELETE_FILES_PROCESSING';
export const DELETE_FILES_SUCCESS = 'DELETE_FILES_SUCCESS';
export const DELETE_FILES_FAILED = 'DELETE_FILES_FAILED';
export const DELETE_FILES_RESET = 'DELETE_FILES_RESET';

export const DELETE_CORRESPONDENCE_PROCESSING = 'DELETE_CORRESPONDENCE_PROCESSING';
export const DELETE_CORRESPONDENCE_SUCCESS = 'DELETE_CORRESPONDENCE_SUCCESS';
export const DELETE_CORRESPONDENCE_FAILED = 'DELETE_CORRESPONDENCE_FAILED';
export const DELETE_CORRESPONDENCE_RESET = 'DELETE_CORRESPONDENCE_RESET';

export const UPDATE_CORRESPONDENCE_PROCESSING = 'UPDATE_CORRESPONDENCE_PROCESSING';
export const UPDATE_CORRESPONDENCE_SUCCESS = 'UPDATE_CORRESPONDENCE_SUCCESS';
export const UPDATE_CORRESPONDENCE_FAILED = 'UPDATE_CORRESPONDENCE_FAILED';
export const UPDATE_CORRESPONDENCE_RESET = 'UPDATE_CORRESPONDENCE_RESET';

export const UPDATE_TRANSACTIONSNOTES_PROCESSING = 'UPDATE_TRANSACTIONSNOTES_PROCESSING';
export const UPDATE_TRANSACTIONSNOTES_SUCCESS = 'UPDATE_TRANSACTIONSNOTES_SUCCESS';
export const UPDATE_TRANSACTIONSNOTES_FAILED = 'UPDATE_TRANSACTIONSNOTES_FAILED';

export const EDIT_TRANSACTIONSNOTES_PROCESSING = 'EDIT_TRANSACTIONSNOTES_PROCESSING';
export const EDIT_TRANSACTIONSNOTES_SUCCESS = 'EDIT_TRANSACTIONSNOTES_SUCCESS';
export const EDIT_TRANSACTIONSNOTES_FAILED = 'EDIT_TRANSACTIONSNOTES_FAILED';

export const DELETE_TRANSACTIONSNOTES_PROCESSING = 'DELETE_TRANSACTIONSNOTES_PROCESSING';
export const DELETE_TRANSACTIONSNOTES_SUCCESS = 'DELETE_TRANSACTIONSNOTES_SUCCESS';
export const DELETE_TRANSACTIONSNOTES_FAILED = 'DELETE_TRANSACTIONSNOTES_FAILED';

export const UPDATE_FILESNOTES_PROCESSING = 'UPDATE_FILESNOTES_PROCESSING';
export const UPDATE_FILESNOTES_SUCCESS = 'UPDATE_FILESNOTES_SUCCESS';
export const UPDATE_FILESNOTES_FAILED = 'UPDATE_FILESNOTES_FAILED';

export const GET_CLIENT_TOTAL_COUNT_PROCESSING = 'GET_CLIENT_TOTAL_COUNT_PROCESSING';
export const GET_CLIENT_TOTAL_COUNT_SUCCESS = 'GET_CLIENT_TOTAL_COUNT_SUCCESS';
export const GET_CLIENT_TOTAL_COUNT_FAILED = 'GET_CLIENT_TOTAL_COUNT_FAILED';

export const FETCH_TEMPLATES_PROCESSING = 'FETCH_TEMPLATES_PROCESSING';
export const FETCH_TEMPLATE_SUCCESS = 'FETCH_TEMPLATE_SUCCESS';
export const FETCH_TEMPLATE_FAILED = 'FETCH_TEMPLATE_FAILED';

export const GET_TOTAL_FOLLOWUP_NOTE_PROCESSING = 'GET_TOTAL_FOLLOWUP_NOTE_PROCESSING';
export const GET_TOTAL_FOLLOWUP_NOTE_SUCCESS = 'GET_TOTAL_FOLLOWUP_NOTE_SUCCESS';
export const GET_TOTAL_FOLLOWUP_NOTE_FAILED = 'GET_TOTAL_FOLLOWUP_NOTE_FAILED';

export const GET_FOLLOWUP_NOTE_PROCESSING = 'GET_FOLLOWUP_NOTE_PROCESSING';
export const GET_FOLLOWUP_NOTE_SUCCESS = 'GET_FOLLOWUP_NOTE_SUCCESS';
export const GET_FOLLOWUP_NOTE_FAILED = 'GET_FOLLOWUP_NOTE_FAILED';

export const DELETE_TRANSACTIONSDETAILS_PROCESSING = 'DELETE_TRANSACTIONSDETAILS_PROCESSING';
export const DELETE_TRANSACTIONSDETAILS_SUCCESS = 'DELETE_TRANSACTIONSDETAILS_SUCCESS';
export const DELETE_TRANSACTIONSDETAILS_FAILED = 'DELETE_TRANSACTIONSDETAILS_FAILED';

export const DELETE_CORRESPONDING_NOTE_PROCESSING = 'DELETE_CORRESPONDING_NOTE_PROCESSING';
export const DELETE_CORRESPONDING_NOTE_SUCCESS = 'DELETE_CORRESPONDING_NOTE_SUCCESS';
export const DELETE_CORRESPONDING_NOTE_FAILED = 'DELETE_CORRESPONDING_NOTE_FAILED';

export const FETCH_SEARCH_PROCESSING = 'FETCH_SEARCH_PROCESSING';
export const FETCH_SEARCH_FAILED = 'FETCH_SEARCH_FAILED';
export const FETCH_SEARCH_SUCCESS = 'FETCH_SEARCH_SUCCESS';

//OTHER DETAILS
export const ADD_OTHERDETAILS_PROCESSING = 'ADD_OTHERDETAILS_PROCESSING';
export const ADD_OTHERDETAILS_SUCCESS = 'ADD_OTHERDETAILS_SUCCESS';
export const ADD_OTHERDETAILS_FAILED = 'ADD_OTHERDETAILS_FAILED';
export const ADD_OTHERDETAILS_RESET = 'ADD_OTHERDETAILS_RESET';

export const DELETE_OTHERDETAILS_PROCESSING = 'DELETE_OTHERDETAILS_PROCESSING';
export const DELETE_OTHERDETAILS_SUCCESS = 'DELETE_OTHERDETAILS_SUCCESS';
export const DELETE_OTHERDETAILS_FAILED = 'DELETE_OTHERDETAILS_FAILED';

export const ADD_FILE_PROCESSING = 'ADD_FILE_PROCESSING';
export const ADD_FILE_SUCCESS = 'ADD_FILE_SUCCESS';
export const ADD_FILE_FAILED = 'ADD_FILE_FAILED';

export const DELETE_FILE_PROCESSING = 'DELETE_FILE_PROCESSING';
export const DELETE_FILE_SUCCESS = 'DELETE_FILE_SUCCESS';
export const DELETE_FILE_FAILED = 'DELETE_FILE_FAILED';

export const ADD_FILENOTE_PROCESSING = 'ADD_FILENOTE_PROCESSING';
export const ADD_FILENOTE_SUCCESS = 'ADD_FILENOTE_SUCCESS';
export const ADD_FILENOTE_FAILED = 'ADD_FILENOTE_FAILED';

export const UPDATE_FILENOTE_PROCESSING = 'UPDATE_FILENOTE_PROCESSING';
export const UPDATE_FILENOTE_SUCCESS = 'UPDATE_FILENOTE_SUCCESS';
export const UPDATE_FILENOTE_FAILED = 'UPDATE_FILENOTE_FAILED';

export const DELETE_FILENOTE_PROCESSING = 'DELETE_FILENOTE_PROCESSING';
export const DELETE_FILENOTE_SUCCESS = 'DELETE_FILENOTE_SUCCESS';
export const DELETE_FILENOTE_FAILED = 'DELETE_FILENOTE_FAILED';

export const FOLLOW_UP_TASK_COMPLETE_PROCESSING = ' FOLLOW_UP_TASK_COMPLETE_PROCESSING';
export const FOLLOW_UP_TASK_COMPLETE_SUCCESS = 'FOLLOW_UP_TASK_COMPLETE_SUCCESS';
export const FOLLOW_UP_TASK_COMPLETE_FAILED = ' FOLLOW_UP_TASK_COMPLETE_FAILED';

export const OTHERS_DETAILS_FILES_AND_CORRESPONDENCE_PROCESSING = ' OTHERS_DETAILS_FILES_AND_CORRESPONDENCE_PROCESSING';
export const OTHERS_DETAILS_FILES_AND_CORRESPONDENCE_SUCCESS = 'OTHERS_DETAILS_FILES_AND_CORRESPONDENCE_SUCCESS';
export const OTHERS_DETAILS_FILES_AND_CORRESPONDENCE_FAILED = ' OTHERS_DETAILS_FILES_AND_CORRESPONDENCE_FAILED';

export const FILES_AND_CORRESPONDENCE_OTHERS_DETAILS_PROCESSING = ' FILES_AND_CORRESPONDENCE_OTHERS_DETAILS_PROCESSING';
export const FILES_AND_CORRESPONDENCE_OTHERS_DETAILS_SUCCESS = 'FILES_AND_CORRESPONDENCE_OTHERS_DETAILS_SUCCESS';
export const FILES_AND_CORRESPONDENCE_OTHERS_DETAILS_FAILED = ' OFILES_AND_CORRESPONDENCE_OTHERS_DETAILS_FAILED';

export const GET_FOLLOW_UP_NOTES_PROCESSING = ' GET_FOLLOW_UP_NOTES_PROCESSING';
export const GET_FOLLOW_UP_NOTES_SUCCESS = 'GET_FOLLOW_UP_NOTES_SUCCESS';
export const GET_FOLLOW_UP_NOTES_FAILED = ' OGET_FOLLOW_UP_NOTES_FAILED';

