import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField
} from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Message from '../../components/message/Message';
import { signupEmailVerifyAction } from '../../store/actions/authAction';
import theme from '../../styles/theme';
import logo from '../../assets/images/logo.svg';
import mail from '../../assets/images/open-email.svg';
import ClearIcon from '@material-ui/icons/Clear';
import { useState } from 'react';
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import { ConfirmPasswordValidation, PasswordValidation } from '../../components/validations/YupValidations';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const SignupConfirmEmail = ({ verifyAction, verifyData, history }) => {
  const query = new URLSearchParams(window.location.search);
  const [openConfirmEmail, setOpenConfirmEmail] = useState(false);
  const handleCloseConfirmEmail = () => {
    setOpenConfirmEmail(false);
  };

const [showPasword, setShowPasword] = useState(false)
const handleClickShowPassword=()=>{
  setShowPasword(!showPasword)

}
const [showConfirmPasword, setShowConfirmPasword] = useState(false)
const handleClickShowConfirmPassword=()=>{
  setShowConfirmPasword(!showConfirmPasword)

}
  const emailUrl = query.get('email');
  const token = query.get('verificationToken');
  const initialValues = {
    password: '',
    confirmPassword: ''
  };

  const validationSchema = yup.object({
    password: PasswordValidation(),
    confirmPassword: ConfirmPasswordValidation('password')
  });

  const onSubmit = (value, onSubmitProps) => {
    const verifyValue = {
      email: emailUrl,
      verificationToken: token,
      password: value.password,
    };
    // console.log(verify)

    verifyAction(verifyValue);
  };

  return (
    <ThemeProvider theme={theme}>
      <Backdrop open={verifyData.loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Message status={verifyData.status} severity={verifyData.severity} message={verifyData.message} />
      <Dialog
        open={openConfirmEmail}
        onClose={handleCloseConfirmEmail}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="responsive-dialog-title">
          <div className="centerbetween">
          {'Set Password'}
          <IconButton onClick={handleCloseConfirmEmail}>
            <ClearIcon></ClearIcon>
          </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            enableReinitialize
          >
            {(formik) => {
              return (
                <Form>
                  <div className="email-confirm">
                    <Field name="password">
                      {(props) => {
                        const { field, meta } = props;

                        return (
                          <div>
                            <TextField
                              label={<>Password</>}
                              {...field}
                              type={showPasword ? 'text' : 'password'}
                              error={meta.touched && meta.error ? true : false}
                              helperText={meta.touched && meta.error ? <div>{meta.error}</div> : null}
                              className="text-field-custom"
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword}>
                                      {showPasword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                  </InputAdornment>
                                )
                              }}
                            />
                            
                          </div>
                        );
                      }}
                    </Field>
                  </div>

                  <div className="email-confirm">
                    <Field name="confirmPassword">
                      {(props) => {
                        const { field, meta } = props;

                        return (
                          <div>
                            <TextField
                              label={<>Confirm Password</>}
                              {...field}
                              type={showConfirmPasword ? 'text' : 'password'}
                              error={meta.touched && meta.error ? true : false}
                              helperText={meta.touched && meta.error ? <div>{meta.error}</div> : null}
                              className="text-field-custom"
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton aria-label="toggle password visibility" onClick={handleClickShowConfirmPassword}>
                                      {showConfirmPasword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                  </InputAdornment>
                                )
                              }}
                            />
                            
                          </div>
                        );
                      }}
                    </Field>
                  </div>
                  <DialogActions>
                    <Button
                      onClick={() => {
                        handleCloseConfirmEmail();
                      }}
                      color="primary"
                      variant="contained"
                      autoFocus
                      type="submit"
                    >
                      Verify
                    </Button>
                    <Button autoFocus type="reset" color="primary" variant="outlined" className="cancel-button">
                      Clear
                    </Button>
                  </DialogActions>
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>

      <div className="verify-email-holder">
        <div className="container">
          <div className="verify-header">
            <div className="logo-holder">
              <img src={logo} alt="description"></img>
            </div>
                <div className="ganesh-logo">
              <span className="icon-ganesh-logo"></span>
            </div>
          </div>
          <div className="verify-content">
            <div className="content-holder">
              <div className="icon-holder">
                <img src={mail} alt="description"></img>
              </div>
              <h3>Confirm Your Email</h3>
              <p>
                Thanks for signing up for Ganesh CRM We need a little more information to complete your registration, Please
                click the button below to confirm its you.
                <br />
                <span className="sm-text">
                  If you didn't create an account for Ganesh CRM, you can safely delete this mail.
                </span>
              </p>
              <Button
                variant="contained"
                color="primary"
                onClick={
                  () => {
                    setOpenConfirmEmail(true);
                  }
                }
              >
                Verify Your Email
              </Button>
            </div>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    verifyData: state.authReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    verifyAction: (verifyValues) => dispatch(signupEmailVerifyAction(verifyValues))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignupConfirmEmail);
