import * as yup from 'yup';
import {
  AlphabetsWithoutRequiredValidation,
  EmailValidationWithoutRequiredField,
  NumberWithoutRequiredValidation,
  PhoneValidation
  // AlphabetsValidation,
  // ConfirmPasswordValidation, EmailValidation,
  // PasswordValidation,
  // RequiredValidation, UserNameValidation
} from '../validations/YupValidations';

export const clientProfileinitialValues = {
  title: '',
  firstName: '',
  middleName: '',
  lastName: '',
  personalEmail: '',
  personalMobile: '',
  personalPhone: '',
  mailingStreetNo: '',
  mailingStreetAddress: '',
  mailingSuburb: '',
  mailingState: '',
  mailingZip: '',
  sameAsMailingAddress: false,
  mailingCountry: '',
  residentialStreetNo: '',
  residentialStreetAddress: '',
  residentialSuburb: '',
  residentialState: '',
  residentialZip: '',
  residentialCountry: '',
  maritalStatus: '',
  spouseTitle: '',
  spouseFirstName: '',
  spouseMiddleName: '',
  spouseLastName: '',
  spouseEmail: '',
  spouseNumber: '',
  spouseLandline: '',
  partnerTitle: '',
  partnerFirstName: '',
  partnerMiddleName: '',
  partnerLastName: '',
  partnerEmail: '',
  partnerNumber: '',
  partnerLandline: '',
  customerType: '',
  entryDate: new Date().toJSON().split('T')[0],
  clientStatus: 'Active',
  referralCode: '',
  referralName: '',
  referralEmail: '',
  referralPhone: '',
  referralStreetNo: '',
  referralStreetAddress: '',
  referralSuburb: '',
  referralState: '',
  referralPostalCode: '',
  referralCountry: '',
  referralLeadOpen: '',
  referralLeadClose: '',
  transactions: [
    {
      propertyLotAddress: '',
      upTo: '',
      propertyType: '',
      purchaseStatus: '',
      ooInvst: '',
      // resultNotes: '',
      resultNotes: [{ note: '' }],
      correspondingNote: []
      // {
      //   correspondingUploadPaths: [],
      //   otherNotes: [{ otherNote: '' }]
      // }
    }
  ],

  // correspondingNote: '',
  clientFollowUpNotes: [
    {
      clientFollowUpDate: '',
      assignedTo: '',
      followUpNote: ''
    }
  ],
  files: [],
  otherDetails: [
    {
      newOtherNotes: [
        {
          newOtherNote: ''
        }
      ]
    }
  ]
};

export const clientProfileValidationSchema = yup.object({
  firstName: AlphabetsWithoutRequiredValidation().required('First Name is required'),
  middleName: AlphabetsWithoutRequiredValidation(),
  lastName: AlphabetsWithoutRequiredValidation().required('Last Name is required'),
  personalEmail: EmailValidationWithoutRequiredField('Email is invalid').required('Email is required'),
  personalMobile: PhoneValidation(),
  personalPhone: PhoneValidation(),
  spouseEmail: EmailValidationWithoutRequiredField("Spouse's Email is invalid"),
  partnerEmail: EmailValidationWithoutRequiredField("Partner's Email is invalid"),
  clientFollowUpNotes: yup.array().of(
    yup.object({
      followUpNote: yup.string().when('clientFollowUpDate', (clientFollowUpDate) => {
        if (clientFollowUpDate) {
          return yup.string().required('Follow Up Note is required');
        }
      })
    })
  ),

  mailingCountry: AlphabetsWithoutRequiredValidation(),
  mailingZip: NumberWithoutRequiredValidation('Postal Code'),
  residentialZip: NumberWithoutRequiredValidation('Residential postal code'),
  residentialCountry: AlphabetsWithoutRequiredValidation(),
  spouseFirstName: AlphabetsWithoutRequiredValidation(),
  spouseMiddleName: AlphabetsWithoutRequiredValidation(),
  spouseLastName: AlphabetsWithoutRequiredValidation(),
  spouseNumber: PhoneValidation(),
  spouseLandline: PhoneValidation(),
  partnerFirstName: AlphabetsWithoutRequiredValidation(),
  partnerMiddleName: AlphabetsWithoutRequiredValidation(),
  partnerLastName: AlphabetsWithoutRequiredValidation(),
  partnerNumber: PhoneValidation(),
  partnerLandline: PhoneValidation(),
  referralName: AlphabetsWithoutRequiredValidation(),
  referralEmail: EmailValidationWithoutRequiredField('Email is invalid'),
  referralPhone: PhoneValidation(),
  referralPostalCode: NumberWithoutRequiredValidation('Postal Code'),
  referralCountry: AlphabetsWithoutRequiredValidation(),
  transactions: yup.array().of(
    yup.object({
      propertyLotAddress: yup
        .string()
        .when(
          ['upTo', 'propertyType', 'purchaseStatus', 'ooInvst', 'resultNotes[0].note'],
          (upTo, propertyType, purchaseStatus, ooInvst, resultNotes) => {
            if (upTo || propertyType || purchaseStatus || ooInvst || resultNotes) {
              return yup.string().required('Property Lot Address  is required');
            }
          }
        )
    })
  )
});

export const clientProfileOnSubmit = (addClientDataAction, captialize, dispatch, setPristine) => {
  return (values, onSubmitProps) => {

    const transactionDetails = values.transactions.filter((transaction, i) => {
      return Object.keys(transaction).length > 0;
    });
    let referrals = {
      referralCode: values.referralCode,
      name: captialize(values.referralName),
      email: captialize(values.referralEmail),
      phone: values.referralPhone,
      streetNo: captialize(values.referralStreetNo),
      streetAddress: captialize(values.referralStreetAddress),
      suburb: captialize(values.referralSuburb),
      state: captialize(values.referralState),
      zip: values.referralPostalCode,
      country: captialize(values.referralCountry),
      referralLeadOpen: captialize(values.referralLeadOpen),
      referralLeadClose: captialize(values.referralLeadClose)
    };
    const formData = new FormData();

    formData.append('title', captialize(values.title));
    formData.append('firstName', captialize(values.firstName));
    formData.append('middleName', captialize(values.middleName));
    formData.append('lastName', captialize(values.lastName));
    formData.append('email', captialize(values.personalEmail));
    formData.append('mobile', values.personalMobile);
    formData.append('phone', values.personalPhone);
    formData.append('mailingStreetNo', captialize(values.mailingStreetNo));
    formData.append('mailingStreetAddress', captialize(values.mailingStreetAddress));
    formData.append('mailingSuburb', captialize(values.mailingSuburb));
    formData.append('mailingState', captialize(values.mailingState));
    formData.append('mailingZip', values.mailingZip);
    formData.append('mailingCountry', captialize(values.mailingCountry));
    formData.append('residentialStreetNo', captialize(values.residentialStreetNo));
    formData.append('residentialStreetAddress', captialize(values.residentialStreetAddress));
    formData.append('residentialSuburb', captialize(values.residentialSuburb));
    formData.append('residentialState', captialize(values.residentialState));
    formData.append('residentialZip', values.residentialZip);
    formData.append('residentialCountry', captialize(values.residentialCountry));
    formData.append('maritalStatus', values.maritalStatus);
    formData.append('spouseTitle', captialize(values.spouseTitle));
    formData.append('spouseNumber', values.spouseNumber);
    formData.append('spouseLandline', values.spouseLandline);
    formData.append('spouseFirstName', captialize(values.spouseFirstName));
    formData.append('spouseMiddleName', captialize(values.spouseMiddleName));
    formData.append('spouseLastName', captialize(values.spouseLastName));
    formData.append('spouseEmail', captialize(values.spouseEmail));
    formData.append('partnerTitle', captialize(values.partnerTitle));
    formData.append('partnerNumber', values.partnerNumber);
    formData.append('partnerLandline', values.partnerLandline);
    formData.append('partnerFirstName', captialize(values.partnerFirstName));
    formData.append('partnerMiddleName', captialize(values.partnerMiddleName));
    formData.append('partnerLastName', captialize(values.partnerLastName));
    formData.append('partnerEmail', captialize(values.partnerEmail));
    formData.append('customerType', values.customerType);
    formData.append('entryDate', values.entryDate);
    formData.append('clientStatus', values.clientStatus);
    formData.append('clientFollowUpNotes', JSON.stringify(values.clientFollowUpNotes));
    values.files.forEach((file) => {
      formData.append('files', file);
    });

    formData.append('correspondingNote', values.correspondingNote);
    formData.append('referrals', JSON.stringify(referrals));
    formData.append('transactionDetails', JSON.stringify(transactionDetails));

    dispatch(addClientDataAction(formData, onSubmitProps.resetForm, setPristine));
    onSubmitProps.setSubmitting(false);
  };
};
