import http from './httpHelper';

export default class AuthService {
  static login(email, password, userName) {
    return http(false).post('/login', { email: email, password, userName });
  }

  static signUp(signupValues) {
    return http(false).post('/signUp', signupValues);
  }

  static signupVerify(verifyValues) {
    return http(false).post('/confirmEmail', verifyValues);
  }

  static loadMe() {
    return http().get('/me');
  }

  static logout() {
    return http().post('/logout');
  }

  static forgotPassword(email) {
    return http(false).post('/forgotPassword', { email });
  }

  static requestAccess(email, name) {
    return http(false).post('/requestAccess', { email: email, name: name });
  }
  //updatePassword
  static updateClientPassword(passwordValues) {
    return http().put('/user/password', passwordValues);
  }
  s;
  //sendemail
  static sendClientEmail(email) {
    return http(false).post('/forgotPassword', email);
  }
  //resetpassword
  static resetClientPassword(newPassword, email, verificationToken) {
    return http(false).post('/resetPassword', {
      newPassword,
      email,
      verificationToken
    });
  }
  //getcrmuser
  static getcrmuser() {
    return http(false).get('/user/:id', {});
  }


  //updatecrmuser
  static updatecrmuser() {
    return http(false).put('/user/:id', {});
  }
  static fetchSearchData() {
    return http().get('/globalSearch', {});
  }
}
