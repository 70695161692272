import {
  GET_CLIENT_TOTAL_COUNT_FAILED,
  GET_CLIENT_TOTAL_COUNT_PROCESSING,
  GET_CLIENT_TOTAL_COUNT_SUCCESS,
  GET_TOTAL_FOLLOWUP_NOTE_FAILED,
  GET_TOTAL_FOLLOWUP_NOTE_PROCESSING,
  GET_TOTAL_FOLLOWUP_NOTE_SUCCESS,
  GET_FOLLOWUP_NOTE_PROCESSING,
  GET_FOLLOWUP_NOTE_SUCCESS,
  GET_FOLLOWUP_NOTE_FAILED
} from '../types/types';

const initialState = {
  status: false,
  severity: '',
  message: '',
  loading: false,
  clientListsAction: {},
  followUpNotes: [],
  getFollowupNotes: {},

  loadingClientsCount: false
};

export const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TOTAL_FOLLOWUP_NOTE_PROCESSING:
      return {
        ...state,
        loading: true,
        followUpNotes: []
      };
    case GET_TOTAL_FOLLOWUP_NOTE_SUCCESS:
      return {
        ...state,
        loading: false,
        followUpNotes: action.payload
      };
    case GET_TOTAL_FOLLOWUP_NOTE_FAILED:
      return {
        ...state,
        loading: false,
        followUpNotes: []
      };

    case GET_CLIENT_TOTAL_COUNT_PROCESSING:
      return {
        ...state,
        loading: true,
        // clientListsAction: {},
        loadingClientsCount: true
      };
    case GET_CLIENT_TOTAL_COUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        clientListsAction: action.payload,

        loadingClientsCount: false,
      };
    case GET_CLIENT_TOTAL_COUNT_FAILED:
      return {
        ...state,
        loading: false,
        clientListsAction: {},

        loadingClientsCount: false
      };
    case GET_FOLLOWUP_NOTE_PROCESSING:
      return {
        ...state,
        loading: true,
        getFollowupNotes: {}
      };
    case GET_FOLLOWUP_NOTE_SUCCESS:
      return {
        ...state,
        loading: false,
        getFollowupNotes: action.payload
      };
    case GET_FOLLOWUP_NOTE_FAILED:
      return {
        ...state,
        loading: false,
        getFollowupNotes: {}
      };
    default:
      return state;
  }
};
