import {
  AlphabetsValidation,
  AlphabetsWithoutRequiredValidation,
  // ConfirmPasswordValidation,
  EmailValidation,
  NumberWithoutRequiredValidation,
  // PasswordValidation,
  PhoneValidation,
  RequiredValidation,
  UserNameValidation
} from '../validations/YupValidations';
import * as yup from 'yup';
import { changeToSmallCase} from "../common/CleanInputData";
import { capitilized } from '../common/CapitalizedWord'
export const signupPageinitialValues = {
  userName: '',
  title: '',
  firstName: '',
  middleName: '',
  lastName: '',
  email: '',
  password: '',
  residentialStreet: '',
  residentialState: '',
  residentialCountry: '',
  residentialZip: '',
  phoneNumber: '',
  rolePosition: '',
  userRole: '',
  residentialStreetAddress: '',
  residentialSuburb: '',
  emergencyContactPersonTitle: '',
  emergencyContactPersonFirstName: '',
  emergencyContactPersonMiddleName: '',
  emergencyContactPersonLastName: '',
  emergencyResidentialStreet: '',
  emergencyResidentialStreetAddress: '',
  emergencyResidentialSuburb: '',
  emergencyResidentialState: '',
  emergencyResidentialCountry: '',
  emergencyResidentialZip: '',
  emergencyContactPersonMobileNo: '',
  emergencyContactPersonPhone: '',
  emergencyContactPersonEmail: '',
  emergencyContactPersonRelation: '',
  maritalStatus: '',
  confirmPassword: ''
};

export const signupPagevalidationSchema = yup.object({
  userName: UserNameValidation().required('Username is required'),
  rolePosition: AlphabetsWithoutRequiredValidation(),
  userRole: RequiredValidation('Access Level'),
  title: yup.string(),
  firstName: AlphabetsValidation('First name'),
  middleName: AlphabetsWithoutRequiredValidation(),
  lastName: AlphabetsValidation('Last name'),
  residentialStreet: yup.string(),
  residentialZip: NumberWithoutRequiredValidation('Residential postal code'),
  residentialStreetAddress: yup.string(),
  residentialSuburb: yup.string(),
  residentialState: yup.string(),
  residentialCountry: AlphabetsWithoutRequiredValidation(),
  email: EmailValidation().required('Email is required'),
  phoneNumber: PhoneValidation(),
  emergencyContactPersonTitle: yup.string(),
  emergencyContactPersonFirstName: AlphabetsWithoutRequiredValidation('First name'),
  emergencyContactPersonMiddleName: AlphabetsWithoutRequiredValidation(),
  emergencyContactPersonLastName: AlphabetsWithoutRequiredValidation('Last name'),
  emergencyResidentialStreet: yup.string(),
  emergencyResidentialZip: NumberWithoutRequiredValidation('Emergency Contact Residential postal code'),
  emergencyResidentialStreetAddress: yup.string(),
  emergencyResidentialSuburb: yup.string(),
  maritalStatus: yup.string(),
  emergencyResidentialCountry: AlphabetsWithoutRequiredValidation(),
  emergencyContactPersonMobileNo: PhoneValidation(),
  emergencyContactPersonPhone: PhoneValidation(),
  emergencyContactPersonEmail: EmailValidation(),
  emergencyContactPersonRelation: AlphabetsWithoutRequiredValidation()
});

export const signupOnSubmit =
  (signupAction, setPristine) =>
  (values, { resetForm }) => {
    const signupValues = {
      userName: changeToSmallCase(values.userName),
      title: capitilized(values.title),
      firstName:  capitilized(values.firstName),
      middleName: capitilized(values.middleName),
      lastName: capitilized(values.lastName),
      email: changeToSmallCase(values.email),
      phone: values.phoneNumber,
      rolePosition: values.rolePosition,
      accessLevel: values.userRole,
      maritalStatus: values.maritalStatus,
      residential: {
        street: values.residentialStreet,
        streetAddress: values.residentialStreetAddress,
        state: values.residentialState,
        country: values.residentialCountry,
        zip: values.residentialZip,
        suburb: values.residentialSuburb
      },
      contactPerson: {
        emergencyContactPersonTitle: capitilized(values.emergencyContactPersonTitle),
        emergencyContactPersonFirstName: capitilized(values.emergencyContactPersonFirstName),
        emergencyContactPersonMiddleName: capitilized(values.emergencyContactPersonMiddleName),
        emergencyContactPersonLastName: capitilized(values.emergencyContactPersonLastName),
        emergencyContactPersonMobileNo: values.emergencyContactPersonMobileNo,
        emergencyContactPersonPhone: values.emergencyContactPersonPhone,
        emergencyContactPersonEmail: changeToSmallCase(values.emergencyContactPersonEmail),
        emergencyContactPersonRelation: values.emergencyContactPersonRelation

      },
      emergencyResidential: {
        emergencyStreet: values.emergencyResidentialStreet,
        emergencyStreetAddress: values.emergencyResidentialStreetAddress,
        emergencyState: values.emergencyResidentialState,
        emergencyCountry: values.emergencyResidentialCountry,
        emergencyZip: values.emergencyResidentialZip,
        emergencySuburb: values.emergencyResidentialSuburb
      }
    };
    signupAction(signupValues, resetForm, setPristine);
  };
