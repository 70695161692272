import { addClientDataAction, addNewClientDataAction } from '../../../store/actions/clientAction';
import { capitalize } from '../../common/CapitalizedWord';
import {capitilized} from '../../common/CapitalizedWord';
import {changeToSmallCase} from '../../common/CleanInputData'

export const createClientProfile = (
  values,
  onSubmitProps,
  dispatch,
  leadOpen,
  leadClose,
  setleadOpen,
  setleadClose,
  resetDirty,
  followUpAssigendTo,
  setFollowUpAssignedTo,
  setReferral,
  setReferralName,
  createWithoutValidation,
  setCreateWithoutValidation
) => {
  const transactionDetails = values.transactions.filter((transaction, i) => {
    return Object.keys(transaction).length > 0;
  });

  let referrals = {
    referralCode: values.referralCode,
    name: capitilized(values.referralName),
    email: changeToSmallCase(values.referralEmail),
    phone: values.referralPhone,
    streetNo: capitalize(values.referralStreetNo),
    streetAddress: capitalize(values.referralStreetAddress),
    suburb: capitalize(values.referralSuburb),
    state: capitalize(values.referralState),
    zip: values.referralPostalCode,
    country: capitilized(values.referralCountry),
    referralLeadOpen: leadOpen != null ? leadOpen.email : '',
    referralLeadClose: leadClose != null ? leadClose.email : ''
  };
  const formData = new FormData();
  formData.append('title', capitilized(values.title));
  formData.append('firstName', capitilized(values.firstName));
  formData.append('middleName', capitilized(values.middleName));
  formData.append('lastName', capitilized(values.lastName));
  formData.append('email', changeToSmallCase(values.personalEmail));
  formData.append('mobile', values.personalMobile);
  formData.append('phone', values.personalPhone);
  formData.append('mailingStreetNo', capitalize(values.mailingStreetNo));
  formData.append('mailingStreetAddress', capitalize(values.mailingStreetAddress));
  formData.append('mailingSuburb', capitalize(values.mailingSuburb));
  formData.append('mailingState', capitalize(values.mailingState));
  formData.append('mailingZip', values.mailingZip);
  formData.append('mailingCountry', capitilized(values.mailingCountry));
  formData.append('residentialStreetNo', capitalize(values.residentialStreetNo));
  formData.append('residentialStreetAddress', capitalize(values.residentialStreetAddress));
  formData.append('residentialSuburb', capitalize(values.residentialSuburb));
  formData.append('residentialState', capitalize(values.residentialState));
  formData.append('residentialZip', values.residentialZip);
  formData.append('residentialCountry', capitilized(values.residentialCountry));
  formData.append('maritalStatus', values.maritalStatus);
  formData.append('spouseTitle', capitilized(values.spouseTitle));
  formData.append('spouseNumber', values.spouseNumber);
  formData.append('spouseLandline', values.spouseLandline);
  formData.append('spouseFirstName', capitilized(values.spouseFirstName));
  formData.append('spouseMiddleName', capitilized(values.spouseMiddleName));
  formData.append('spouseLastName', capitilized(values.spouseLastName));
  formData.append('spouseEmail', changeToSmallCase(values.spouseEmail));
  formData.append('partnerTitle', capitalize(values.partnerTitle));
  formData.append('partnerNumber', values.partnerNumber);
  formData.append('partnerLandline', values.partnerLandline);
  formData.append('partnerFirstName', capitilized(values.partnerFirstName));
  formData.append('partnerMiddleName', capitilized(values.partnerMiddleName));
  formData.append('partnerLastName', capitilized(values.partnerLastName));
  formData.append('partnerEmail', changeToSmallCase(values.partnerEmail));
  formData.append('customerType', values.customerType);
  formData.append('entryDate', values.entryDate);
  formData.append('clientStatus', values.clientStatus);
  formData.append('otherDetails', JSON.stringify(values.otherDetails));


  // At a time only one follow up is being added so
  const clientFollowUpNotes =
    values.clientFollowUpNotes.length > 0 && values.clientFollowUpNotes[0].clientFollowUpDate
      ? [{ ...values.clientFollowUpNotes[0], assignedTo: followUpAssigendTo ? followUpAssigendTo?.email : '' }]
      : [];

  formData.append('clientFollowUpNotes', JSON.stringify(clientFollowUpNotes));



  // const otherDetails=[{notes:[ values.correspondingNote]}]
  // formData.append('otherDetails', JSON.stringify([values.correspondingNote]));
  // formData.append('correspondingNote', values.correspondingNote);

  formData.append('referrals', JSON.stringify(referrals));
  formData.append('transactionDetails', JSON.stringify(transactionDetails));

  // values.files.forEach((file, index) => {
  //   // formData.append('files', file);
  //   file.forEach(f => {
  //     formData.append(`files[${index}]`, f)
  //   })

  // });

  values.files.forEach((file) => {
    formData.append('files', file);
  });
  if (!createWithoutValidation) {
    dispatch(
      addClientDataAction(
        formData,
        onSubmitProps.resetForm,
        resetDirty,
        setleadOpen,
        setleadClose,
        setFollowUpAssignedTo,
        setReferral,
        setReferralName
      )
    );
  } else {

    dispatch(
      addNewClientDataAction(
        formData,
        onSubmitProps.resetForm,
        resetDirty,
        setleadOpen,
        setleadClose,
        setFollowUpAssignedTo,
        setReferral,
        setReferralName
      )
    );
    // reset the state
    setCreateWithoutValidation(false)

  }
  onSubmitProps.setSubmitting(false);
};
