import { Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { useEffect, useState } from 'react';

const Message = ({ status, severity, message }) => {
  const [snackbarStatus, setSnackbarStatus] = useState(status);
  const [snackbarSeverify, setSnackbarServerity] = useState(severity);
  const [snackbarMessage, setSnackbarMessage] = useState(message);

  useEffect(() => {
    setSnackbarStatus(status);
    setSnackbarServerity(severity);
    setSnackbarMessage(message);
  }, [status, severity, message]);

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      open={snackbarStatus}
      autoHideDuration={15000}
      onClose={() => setSnackbarStatus(false)}
    >
      <Alert severity={snackbarSeverify}>{snackbarMessage}</Alert>
    </Snackbar>
  );
};

export default Message;
