import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
// import CheckIcon from '@material-ui/icons/Check';
import { Button } from '@material-ui/core';

const AreYouSurePopUp = ({
  buttonColor,
  buttonName,
  popUpHeading,
  popUpDetail,
  formName,
  buttonType,
  isSubmitting,
  buttonIcon,
  backColor,
  onAgreeClick,
  onClickRemoveData,
  validationError,
  yesButtonName
}) => {
  const [openUpdate, setOpenUpdate] = React.useState(false);

  const handleClickOpenUpdate = () => {
    setOpenUpdate(true);
  };

  const handleCloseUpdate = () => {
    setOpenUpdate(false);
  };
  return (
    <div style={{ zIndex: '1000', display: 'inline-block' }}>
      {validationError ? (
        <Button
          variant="contained"
          color={buttonColor}
          startIcon={buttonIcon}
          // onClick={handleClickOpenUpdate}
          // disabled={isSubmitting}
          style={{ backgroundColor: `${backColor}` }}
        >
          {buttonName}
        </Button>
      ) : (
        <Button
          variant="contained"
          color={buttonColor}
          startIcon={buttonIcon}
          onClick={handleClickOpenUpdate}
          disabled={isSubmitting}
          style={{ backgroundColor: `${backColor}` }}
        >
          {buttonName}
        </Button>
      )}

      <Dialog
        open={openUpdate}
        onClose={handleCloseUpdate}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{popUpHeading}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <span className="text-danger">Alert!!!</span>
            {popUpDetail}
          </DialogContentText>
        </DialogContent>
        <DialogActions className="alert-btn-holder">
          <Button
            form={formName}
            type={buttonType}
            onClick={() => {
              onClickRemoveData && onClickRemoveData();
              if (typeof onAgreeClick === 'function') {
                onAgreeClick();
              }
              handleCloseUpdate();
            }}
            // color="primary"
            variant="contained"
            className="cancel-button"
          >
            {/* Delete */}
            {yesButtonName || 'Delete'}
          </Button>
          <Button
            onClick={handleCloseUpdate}
            color="primary"
            variant="outlined"
            // className="cancel-button"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default AreYouSurePopUp;
