import { combineReducers } from 'redux';
import { loginReducer } from './authReducer';
import { categoryReducer } from './categoryReducer';
import { clientReducer } from './clientReducer';
import { dashboardReducer } from './dashboardReducer';
import { eventReducer } from './eventReducer';
import { userReducer } from './userReducer';
import { templateReducer } from './templateReducers';

const rootReducer = combineReducers({
  clientReducer: clientReducer,
  authReducer: loginReducer,
  userReducer: userReducer,
  categoryReducer: categoryReducer,
  eventReducer: eventReducer,
  dashboardReducer: dashboardReducer,
  templateReducer: templateReducer,
});

export default rootReducer;
