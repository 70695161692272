import React, { useEffect } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from '../../styles/theme';
import logoWhite from '../../assets/images/logo-white.svg';
import bgPattern from '../../assets/images/pattern.svg';
import logo from '../../assets/images/logo.svg';
import { Backdrop, Button, CircularProgress, TextField } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { resetLoginState, resetPassword } from '../../store/actions/authAction';
import { ConfirmPassword, PasswordValidation } from '../validations/YupValidations';
import Message from '../message/Message';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';

const ResetLink = () => {
  const dispatch = useDispatch();
  const {
    location: { search }
  } = useHistory();
  let params = new URLSearchParams(search);

  const validationSchema = yup.object({
    newPassword: PasswordValidation(),
    confirmPassword: ConfirmPassword()
  });

  const formik = useFormik({
    initialValues: {
      newPassword: '',
      confirmPassword: '',
      email: params.get('email'),
      verificationToken: params.get('verificationToken')
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      // alert(JSON.stringify(values, null, 2));
      dispatch(resetPassword(values.newPassword, values.email, values.verificationToken, resetForm));
    }
  });
  const authReducer = useSelector((state) => state.authReducer);

  const [values, setValues] = React.useState({
    password: '',
    showPassword: false,
    showPassword1: false
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  const handleClickShowPassword1 = () => {
    setValues({ ...values, showPassword1: !values.showPassword1 });
  };

  useEffect(() => {
    dispatch(resetLoginState());
    return () => {
      dispatch(resetLoginState());
    };
  }, [dispatch]);
  return (
    <ThemeProvider theme={theme}>
      <Backdrop open={authReducer.loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Message status={authReducer.status} severity={authReducer.severity} message={authReducer.message} />
      <div className="login-wrapper">
        <div className="welcome-screen">
          <div className="login-container">
            <div className="logo-holder">
              <Link href="https://www.arsgroup.com.au/">
                <img src={logoWhite} alt="ARS mate property group"></img>
              </Link>
            </div>
            <h1>
              Welcome to <br />
              <div className="ganesh-logo">
                <span className="icon-ganesh-logo"></span> <span className="ganesh-text">GANESH</span>
              </div>
            </h1>
            <p className="login-note">
              -A CRM Application of <a href="https://www.arsgroup.com.au/">ARS Mates Property Group</a>
            </p>
            <span className="login-subtitle">Please enter your email to reset your password</span>
            <p className="login-note">
              If you hit the wrong URL you can visit
              <br />
              our website for more details. <a href="https://www.arsgroup.com.au/">https://www.arsgroup.com.au/</a>
            </p>
          </div>
          <div className="bg-pattern">
            <img src={bgPattern} alt="" />
          </div>
        </div>
        <div className="login-screen">
          <div className="logo-holder-sm">
            <Link href="https://www.arsgroup.com.au/">
              <img src={logo} alt="ARS mate property group"></img>
            </Link>
          </div>
          <div className="login-container">
            <form className="login-form" onSubmit={formik.handleSubmit}>
              <div className="screen-heading">
                <h2>Update Password</h2>
                <p>What would you like your new password to be ?</p>
              </div>
              <div className="form-group">
                <TextField
                  name="newPassword"
                  label=" New Password"
                  type={values.showPassword1 ? 'text' : 'password'}
                  value={formik.values.newPassword}
                  onChange={formik.handleChange}
                  error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
                  helperText={formik.touched.newPassword && formik.errors.newPassword}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword}>
                          {values.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </div>
              <div className="form-group">
                <TextField
                  name="confirmPassword"
                  label=" Confirm Password"
                  type={values.showPassword1 ? 'text' : 'password'}
                  value={formik.values.confirmPassword}
                  onChange={formik.handleChange}
                  error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                  helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword1}>
                          {values.showPassword1 ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </div>
              <div className="button-holder">
                <Button type="submit" variant="contained" color="primary">
                  Send
                </Button>
                <Button
                  type="reset"
                  // variant="outlined"
                  color="primary"
                  className="cancel-button"
                  onClick={() => {
                    formik.resetForm();
                  }}
                >
                  Clear
                </Button>
              </div>
            </form>
          </div>
          <div className="login-footer">
            <ul>
              <li>
                <Link href="mailto:info@arsgroup.com.au">Support</Link>
              </li>
              <li>&copy; Copyright 2021</li>
            </ul>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};
export default ResetLink;
