export const changeToSmallCase = str => {
  if (typeof str === 'string') {
    return str.split(" ").map((word) => {
      if (typeof word[0] !== 'undefined') {
        return word.toLowerCase()
      }
      else {
        return ''
      }
    }).join(" ")
  }
  return str

}
