import ClientProfileFormTemplate from "./ClientProfileFormTemplate"
import {
  clientProfileinitialValues,
  clientProfileValidationSchema
} from '../formik-component/clientProfileFormFormik';


const ClientProfileCreate = () => {
  return <ClientProfileFormTemplate
    formType="create"
    initialValues={clientProfileinitialValues}
    validationSchema={clientProfileValidationSchema}
  />
}

export default ClientProfileCreate
