import { useEffect, useState } from "react"
import { Prompt } from "react-router-dom";


const UseUnsavedWarningMsg = (message = "Changes you made may not be saved.") => {

    const [isDirty, setDirty] = useState(false);

    useEffect(() => {

        window.onbeforeunload = isDirty && (() => message);

        return () => {
            window.onbeforeunload = null;
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDirty])

    const routerPromp = <Prompt when={isDirty} message={message} />

    return [routerPromp, () => setDirty(true), () => setDirty(false)];

}

export default UseUnsavedWarningMsg;