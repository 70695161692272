import { lazy, Suspense, useEffect, useState } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import ForgotPassword from './components/auth/ForgotPassword';
import ResetLink from './components/auth/ResetLink'; //
import SignupPage from './components/auth/SignupPage';
import ClientProfileCreate from './components/client/ClientProfileCreate';
// import UserTable from './components/client/UserTable';
import { AuthRole } from './components/common/AuthRole';
import ErrorBoundary from './components/error-boundary/error-boundary';
import Header from './components/header/header';
import Sidebar from './components/sidebar/sidebar';
import PrivateRoute from './Routing/PrivateRoute';
import SignupConfirmEmail from './pages/ConfirmEmail/SignupConfirmEmail';
import { ADMIN, SUPER_ADMIN, USER } from './store/types/types';
import EditCategory from './pages/EditCategory';

const AuthPage = lazy(() => import('./pages/AuthPage'));
const DashboardPage = lazy(() => import('./pages/Dashboard'));
const ClientTable = lazy(() => import('./components/client/ClientTable'));
const ClientDetail = lazy(() => import('./components/client/ClientDetail'));
const ClientUpdateForm = lazy(() => import('./components/client/ClientUpdateForm'));
const UpdateCrmUser = lazy(() => import('./components/auth/updateCrmUser'));
const MyEvent = lazy(() => import('./pages/SelfEventManagement'));
const EventManagement = lazy(() => import('./pages/EventManagent'));
const EmailManagement = lazy(() => import('./pages/EmailManagement'));
const CreateCategory = lazy(() => import('./pages/CreateCategory'));
const UserDetail = lazy(() => import('./pages/Userdetail'));
const ViewUserList = lazy(() => import('./pages/ViewUserList/index'));

const App = () => {
  const Auth = AuthRole();
  const [auth, setAuth] = useState(Auth);

  const accessToken = sessionStorage.getItem('user_accessToken');

  useEffect(() => {
    if (typeof Auth !== 'undefined' && Auth !== {}) {
      setAuth(Auth);
    }
  }, [Auth]);

  return (
    <BrowserRouter>
      <ErrorBoundary>
        <Switch>
          <Route exact path="/confirmEmail" component={SignupConfirmEmail} />
          <Route exact path="/resetPassword">
            <ResetLink />
          </Route>
          <Suspense fallback={<> </>}>
            <Route
              exact
              path="/auth/:mode"
              render={() => (accessToken ? <Redirect to="/dashboard" /> : <AuthPage />)}
            />
            <Route exact path="/" render={() => <Redirect to="/auth/login" />} />
            <Route exact path="/forgotpassword">
              <ForgotPassword />
            </Route>

            {accessToken ? (
              <div>
                <Header auth={auth} />
                <main id="main">
                  <div className="content-wrapper" style={{ flexWrap: 'initial' }}>
                    <Sidebar auth={auth} />
                    <Suspense fallback={<div>Loading.....</div>}>
                      <PrivateRoute
                        exact
                        path="/dashboard"
                        component={DashboardPage}
                        showFor={[ADMIN, SUPER_ADMIN, USER]}
                        auth={auth}
                      />
                      <PrivateRoute
                        exact
                        path="/event-management"
                        component={EventManagement}
                        showFor={[ADMIN, SUPER_ADMIN, USER]}
                        auth={auth}
                      />
                      <PrivateRoute
                        exact
                        path="/my-event"
                        component={MyEvent}
                        showFor={[ADMIN, SUPER_ADMIN, USER]}
                        auth={auth}
                      />
                      <PrivateRoute
                        exact
                        path="/view-users"
                        component={ViewUserList}
                        showFor={[ADMIN, SUPER_ADMIN]}
                        auth={auth}
                      />
                      <PrivateRoute
                        exact
                        path="/client-profile/create"
                        component={ClientProfileCreate}
                        showFor={[ADMIN, SUPER_ADMIN, USER]}
                        auth={auth}
                      />
                      <PrivateRoute
                        exact
                        path="/user-detail/:id"
                        component={UserDetail}
                        showFor={[ADMIN, SUPER_ADMIN, USER]}
                        auth={auth}
                      />
                      <PrivateRoute
                        exact
                        path="/email-management"
                        component={EmailManagement}
                        showFor={[ADMIN, SUPER_ADMIN, USER]}
                        auth={auth}
                      />

                      <PrivateRoute
                        exact
                        path="/client-profile/view"
                        component={ClientTable}
                        showFor={[ADMIN, SUPER_ADMIN, USER]}
                        auth={auth}
                      />
                      <PrivateRoute
                        exact
                        path="/client-profile/update/:id"
                        component={ClientDetail}
                        showFor={[SUPER_ADMIN, ADMIN, USER]}
                        auth={auth}
                      />
                      <Route exact path="/create-category" component={CreateCategory} />
                      <Route exact path="/edit-category/:id" component={EditCategory} />

                      <PrivateRoute
                        exact
                        path="/crm-user"
                        component={SignupPage}
                        showFor={[ADMIN, SUPER_ADMIN]}
                        auth={auth}
                      />
                      <PrivateRoute
                        exact
                        path="/update-client-profile/:id"
                        component={ClientUpdateForm}
                        showFor={[SUPER_ADMIN, ADMIN]}
                        auth={auth}
                      />
                      <PrivateRoute
                        exact
                        path="/update-user/:id"
                        component={UpdateCrmUser}
                        showFor={[SUPER_ADMIN, ADMIN, USER]}
                        auth={auth}
                      />
                    </Suspense>
                  </div>
                </main>
              </div>
            ) : (
              <Redirect to="/auth/login" />
            )}
          </Suspense>
        </Switch>
      </ErrorBoundary>
    </BrowserRouter>
  );
};

export default App;
