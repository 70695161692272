import axios from 'axios';
import { API_URL, TIMEOUT } from '../config';
import { logout } from '../store/actions/authAction';

export default function http(isAuthRequired = true) {
  let instance = axios.create({ baseURL: API_URL, timeout: TIMEOUT });

  if (isAuthRequired) {
    instance.defaults.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem('user_accessToken')}`;
  }

  instance.interceptors.response.use(null, (error) => {
    const status = error.response.status;
    if (status === 401) {
      logout();
    }
    return Promise.reject(error);
  });

  return instance;
}
