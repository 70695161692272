export const capitalize = (words) => {
  if (typeof words !== 'string') return words;
  return words
    .trim()
    .split(' ')
    .map((w) => w.substring(0, 1).toUpperCase() + w.substring(1))
    .join(' ');
};

export const capitilized = (str) => {
  return str
    ?.split(' ')
    ?.filter?.((v, i) => v !== '')
    ?.map?.((word) => {
      if (typeof word[0] !== 'undefined') {
        return word[0].toUpperCase() + word.substring(1).toLowerCase();
      } else {
        return '';
      }
    })
    ?.join(' ');
};
