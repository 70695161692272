import http from './httpHelper';

export default class CategoryService {
  static createCategory(category) {
    return http().post('/category', category);
  }
  static getCategory() {
    return http().get('/category');
  }
  static bulkEmailSend(emailValues) {
    return http().post('/sendEmails', emailValues);
  }
  static updateCategory(category,paramsId) {
    return http().put(`/category/${paramsId}`, category);
  }
  static deleteCategory(id) {
    return http().delete(`/category/${id}`);
  }
}
