// import { Http } from 'winston/lib/winston/transports';
import http from './httpHelper';

export default class ClientProfileService {
  static create(clientDetail) {
    return http().post('/clients', clientDetail);
  }
  static createnew(clientDetail) {
    return http().post('/clientsWithoutValidation', clientDetail);
  }

  static updateClient(updatedData, id) {
    return http().put(`/newClientProfile/${id}`, updatedData);
  }

  static deleteClient(id) {
    return http().delete(`/clients/${id}`);
  }

  static showClientLists() {
    return http().get('/clients?sortOrder=desc');
  }

  static paginatedClientQuery(query) {
    return http().get(`/newClientProfile${query}`);
  }

  static showClientDetails(id) {
    return http().get(`/clients/${id}`);
  }

  static updateClientFollowUpNotes(updatedData, id) {
    return http().post(`/clients/${id}/followUpNotes`, updatedData);
  }

  static searchUsers(text) {
    return http().get(`/clients?keyword=${text}`);
  }

  static search(filterCriteria) {
    return http().get('/clients', {
      params: {
        ...filterCriteria
      }
    });
  }

  static addTransaction(data, paramsId) {
    return http().post(`clients/${paramsId}/transactionDetails`, data);
  }

  static addFilesCorrespondence(clientId, transactionId, formData) {
    return http().post(`/clients/${clientId}/transactionDetails/${transactionId}/corresponding`, formData);
  }
  static addOtherDetails(paramsId, formData, handleCloseOtherDetails) {
    return http().post(`/clients/${paramsId}/otherDetails`, formData);
  }
  static updateFilesNotes(data, paramsId, transactionIdForFileNote, valuesId) {
    return http().post(
      `/clients/${paramsId}/transactionDetails/${transactionIdForFileNote}/correspondece/${valuesId}`,
      data
    );
  }
  static updateFilesNotesOthers(data, paramsId, valuesId, history) {
    return http().post(`/clients/${paramsId}/noteOtherDetails/${valuesId}`, data);
  }
  static addFiles(clientId, transactionId, correspondingId, formData) {
    return http().post(
      `/clients/${clientId}/transactionDetails/${transactionId}/correspondeceFile/${correspondingId}`,
      formData
    );
  }
  static addFilesActionOther(clientId, currentCorrespondingId, formData, handleClose) {
    return http().post(`/clients/${clientId}/newOtherDetails/${currentCorrespondingId}`, formData);
  }
  static deleteCorrespndingFileAndNote(clientId, transactionId, correspondingId) {
    return http().delete(`/clients/${clientId}/transactionDetails/${transactionId}/otherDetails/${correspondingId}`);
  }

  static updateTransactionsNotes(data, paramsId, transactionId) {
    return http().post(`/clients/${paramsId}/transactionDetails/${transactionId}`, data);
  }
  static editTransactionsNotes(data, paramsId, transactionId, index) {
    return http().put(`/clients/${paramsId}/transactionDetails/${transactionId}/note/${index}`, data);
  }
  static deleteTransactionsNotes(paramsId, transactionId, index) {
    return http().delete(`/clients/${paramsId}/transactionDetails/${transactionId}/note/${index}`);
  }
  static deleteFilesCorrespondence(paramsId, othersId) {
    return http().delete(`/newClientProfile/ClientOtherDetails/${paramsId}/${othersId}`);
  }
  static deleteOtherDetails(paramsId, othersId) {
    return http().delete(`/clients/${paramsId}/otherDetails/${othersId}`);
  }
  static deleteFiles(paramsId, deleteFileTransactions, othersId, index) {
    return http().delete(
      `/clients/${paramsId}/transactionDetails/${deleteFileTransactions}/correspondece/${othersId}/file/${index}`
    );
  }
  static deleteFilesOther(paramsId, othersId, index) {
    return http().delete(`/clients/${paramsId}/otherDeleteFile/${othersId}/file/${index}`);
  }
  static deleteCorrespondingNotes(paramsId, tranId, othersId, index) {
    return http().delete(`/clients/${paramsId}/transactionDetails/${tranId}/correspondece/${othersId}/note/${index}`);
  }
  static deleteCorrespondigNotesOther(paramsId, othersId, index) {
    return http().delete(`/clients/${paramsId}/noteOtherDetails/${othersId}/note/${index}`);
  }
  static updateCorrespondingNotes(data, paramsId, tranId, othersId, index) {
    return http().put(
      `/clients/${paramsId}/transactionDetails/${tranId}/correspondece/${othersId}/note/${index}`,
      data
    );
  }
  static updateCorrespondingNotesOther(data, paramsId, othersId, index) {
    return http().put(`/clients/${paramsId}/noteOtherDetails/${othersId}/note/${index}`, data);
  }
  static deletefollowupnote(paramsId, noteId) {
    return http().delete(`/clients/${paramsId}/followUpNotes/${noteId}`);
  }
  static updatefollowupnote(paramsId, othersId, data) {
    return http().put(`/clients/${paramsId}/followUpNotes/${othersId}`, data);
  }
  static followUpTaskCompleted(paramsId, othersId) {
    if (othersId.isNoteCompleted) {
      return http().put(`/clients/${paramsId}/incompleteNote/${othersId.noteId}`);
    } else {
      return http().put(`/clients/${paramsId}/completeNote/${othersId.noteId}`);
    }
  }
  static deleteTransactionsDetails(paramsId, transactionId) {
    return http().delete(`/newClientProfile/updateClientTransactionDetails/${paramsId}/${transactionId}`);
  }
  static othersdetailsfileandcorrespondence(paramsId) {
    return http().get(`clients/${paramsId}/transactionDetailAndCorrespondence`);
  }
  static fileandcorrespondenceothersdetails(paramsId) {
    return http().get(`clients/${paramsId}/getOtherDetailsofClient`);
  }
  static getFollowupNotes(clientId) {
    return http().get(`clients/${clientId}/followUpNotes`);
  }
}
