import {
  LOGIN_FAILED,
  LOGIN_PROCESSING,
  LOGIN_SUCCESS,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_PROCESSING,
  UPDATE_PASSWORD_FAILED,
  SEND_EMAIL_PROCESSING,
  SEND_EMAIL_SUCCESS,
  SEND_EMAIL_FAILED,
  RESET_PASSWORD_PROCESSING,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILED,
  SIGNUP_FAILED,
  SIGNUP_PROCESSING,
  SIGNUP_SUCCESS,
  SIGNUP_VERIFY_EMAIL_FAILED,
  SIGNUP_VERIFY_EMAIL_PROCESSING,
  SIGNUP_VERIFY_EMAIL_SUCCESS,
  RESET_LOGIN_STATE,
  LOGIN_RESET,
  FETCH_SEARCH_FAILED,
  FETCH_SEARCH_PROCESSING,
  FETCH_SEARCH_SUCCESS
} from '../types/types';

const initialState = {
  loading: false,
  status: false,
  severity: '',
  message: '',
  loginData: {},
  searchDataLoading: false,
  searchData: [],
  searchError: null,
};

export const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_PROCESSING:
      return {
        ...state,
        loading: true,
        status: false,
        severity: '',
        loginData: {}
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        status: false,
        severity: '',
        loginData: action.payload
      };
    case LOGIN_FAILED:
      return {
        ...state,
        loading: false,
        status: true,
        severity: 'warning',
        loginData: {}
      };
    case RESET_LOGIN_STATE:
      return {
        ...state,
        status: false
      };
    case LOGIN_RESET:
      return {
        ...state,
        status: false,
        severity: '',
        message: ''
      };
    case SIGNUP_PROCESSING:
      return {
        ...state,
        loading: true,
        status: false,
        severity: ''
      };
    case SIGNUP_SUCCESS:
      return {
        ...state,
        loading: false,
        status: true,
        severity: 'success',
        message: 'Congratulations, your account has been created successfully.'
      };
    case SIGNUP_FAILED:
      return {
        ...state,
        loading: false,
        status: true,
        severity: 'warning',
        message: action.payload
      };
    case SIGNUP_VERIFY_EMAIL_PROCESSING:
      return {
        ...state,
        loading: true,
        status: false,
        severity: ''
      };
    case SIGNUP_VERIFY_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        status: true,
        severity: 'success',
        message: 'Congratulations, your account has been verified successfully.'
      };
    case SIGNUP_VERIFY_EMAIL_FAILED:
      return {
        ...state,
        loading: false,
        status: true,
        severity: 'warning',
        message: 'Sorry, but we are unable to verify your account right now. Please try again later.'
      };
    case UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: true,
        status: true,
        severity: 'success',
        message: 'Congratulations, your password has been updated successfully.'
      };
    case UPDATE_PASSWORD_PROCESSING:
      return {
        ...state,
        loading: false,
        status: false,
        severity: '',
        message: ''
      };
    case UPDATE_PASSWORD_FAILED:
      return {
        ...state,
        loading: false,
        status: true,
        severity: 'warning',
        message: action.payload
      };
    case SEND_EMAIL_PROCESSING:
      return {
        ...state,
        loading: true,
        status: false,
        severity: '',
        message: ''
      };
    case SEND_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        status: true,
        severity: 'success',
        message:
          'Congratulations, a password reset link has been sent to your registered email. Please check your email for further process.'
      };
    case SEND_EMAIL_FAILED:
      return {
        ...state,
        loading: false,
        status: true,
        severity: 'warning',
        message: 'Sorry, we could not find an account with the email address. Are you sure you typed it correctly?'
      };
    case RESET_PASSWORD_PROCESSING:
      return {
        ...state,
        loading: true,
        status: false,
        severity: '',
        message: ''
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        status: true,
        severity: 'success',
        message: 'Congratulations, your password has been reset successfully.'
      };
    case RESET_PASSWORD_FAILED:
      return {
        ...state,
        loading: false,
        status: true,
        severity: 'warning',
        message: 'Sorry but we are unable to reset your password right now. Please try again later.'
      };
    case FETCH_SEARCH_PROCESSING:
      return {
        ...state,
        searchDataLoading: true,
        // searchData: [],
        searchError: null,
      };
    case FETCH_SEARCH_SUCCESS:
      return {
        ...state,
        searchDataLoading: false,
        searchData: action.payload,
        searchError: null,
      };
    case FETCH_SEARCH_FAILED:
      return {
        ...state,
        searchDataLoading: false,
        searchData: [],
        searchError: action.payload,
      };

    default:
      return state;
  }
};
