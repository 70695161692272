import {
  BULK_EMAIL_SEND_FAILED,
  BULK_EMAIL_SEND_PROCESSING,
  BULK_EMAIL_SEND_RESET,
  BULK_EMAIL_SEND_SUCCESS,
  CREATE_CATEGORY_FAILED,
  CREATE_CATEGORY_PROCESSING,
  CREATE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_FAILED,
  DELETE_CATEGORY_PROCESSING,
  DELETE_CATEGORY_SUCCESS,
  GET_CATEGORY_FAILED,
  GET_CATEGORY_PROCESSING,
  GET_CATEGORY_SUCCESS,
  RESET_MESSAGE_STATE,
  UPDATE_CATEGORY_FAILED,
  UPDATE_CATEGORY_PROCESSING,
  UPDATE_CATEGORY_SUCCESS
} from '../types/types';

const initialState = {
  status: false,
  severity: '',
  message: '',
  loading: false,
  categoryList: [],
  errorMessage: '',
  posted: {},
  sentEmail: '',
  deletedCategor: {}
};

export const categoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_CATEGORY_PROCESSING:
      return {
        ...state,
        loading: true,
        errorMessage: '',
        status: false,
        severity: '',
        message: ''
      };
    case CREATE_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMessage: '',
        status: true,
        severity: 'success',
        message: 'Congratulations, this category  has been created successfully.'
      };
    case CREATE_CATEGORY_FAILED:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
        status: true,
        severity: 'warning',
        message: 'Sorry, we are unable to post this category right now. Please try again later.'
      };
    case UPDATE_CATEGORY_PROCESSING:
      return {
        ...state,
        loading: true,
        errorMessage: '',
        status: false,
        severity: '',
        message: ''
      };
    case UPDATE_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMessage: '',
        status: true,
        severity: 'success',
        message: 'Congratulations, this category  has been updated  successfully.'
      };
    case UPDATE_CATEGORY_FAILED:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
        status: true,
        severity: 'warning',
        message: 'Sorry, we are unable to update this category right now. Please try again later.'
      };
    case DELETE_CATEGORY_PROCESSING:
      return {
        ...state,
        loading: true,
        errorMessage: '',
        deletedCategory: {},
        status: false,
        severity: '',
        message: ''
      };
    case DELETE_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMessage: '',
        deletedCategory: action.payload,
        status: true,
        severity: 'success',
        message: 'Congratulations, this category  has been deleted  successfully.'
      };
    case DELETE_CATEGORY_FAILED:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
        deletedCategory: {},
        status: true,
        severity: 'warning',
        message: 'Sorry, we are unable to delete this category right now. Please try again later.'
      };
    case RESET_MESSAGE_STATE:
      return {
        ...state,
        status: false,
        severity: '',
        message: ''
      };
    case GET_CATEGORY_PROCESSING:
      return {
        ...state,
        loading: true,
        categoryList: []
      };
    case GET_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        categoryList: action.payload
      };
    case GET_CATEGORY_FAILED:
      return {
        ...state,
        loading: false,
        categoryList: []
      };
    case BULK_EMAIL_SEND_PROCESSING:
      return {
        ...state,
        loading: true,
        status: false,
        severity: '',
        message: ''
      };
    case BULK_EMAIL_SEND_SUCCESS:
      return {
        ...state,
        loading: false,
        sentEmail: action.payload,
        status: true,
        severity: 'success',
        message: 'Congratulations, Email  has been send  successfully.'
      };
    case BULK_EMAIL_SEND_FAILED:
      return {
        ...state,
        loading: false,
        sentEmail: '',
        status: true,
        severity: 'warning',
        message: 'Sorry, we are unable to email right now. Please try again later.'
      };
    case BULK_EMAIL_SEND_RESET:
      return {
        ...state,
        status: false,
        severity: '',
        message: '',
        sentEmail: ''
      };

    default:
      return state;
  }
};
