import {
  AlphabetsWithoutRequiredValidation,
  EmailValidationWithoutRequiredField,
  NumberWithoutRequiredValidation,
  PhoneValidation
} from '../validations/YupValidations';
import * as yup from 'yup';
import { capitalize } from '../common/CapitalizedWord.js';
import {capitilized} from '../common/CapitalizedWord';
import {changeToSmallCase} from '../common/CleanInputData';
import { updateClientDetailsAction } from '../../store/actions/clientAction';

export const clientUpdateInitialValues = (
  clientDetails,
  maritalStatusValue,
  customerTypeValue,
  entryDateValue,
  referalCodeValue
) => {
  return {
    title: clientDetails && clientDetails.title,
    firstName: clientDetails && clientDetails.firstName,
    middleName: clientDetails && clientDetails.middleName,
    lastName: clientDetails && clientDetails.lastName,
    personalEmail: clientDetails && clientDetails.email,
    personalMobile: clientDetails && clientDetails.mobile,
    personalPhone: clientDetails && clientDetails.phone,
    mailingStreetNo: clientDetails && clientDetails.mailingStreetNo,
    mailingStreetAddress: clientDetails && clientDetails.mailingStreetAddress,
    mailingSuburb: clientDetails && clientDetails.mailingSuburb,
    mailingState: clientDetails && clientDetails.mailingState,
    mailingZip: clientDetails?.mailingZip ? clientDetails.mailingZip : '',
    mailingCountry: clientDetails && clientDetails.mailingCountry,
    sameAsMailingAddress: false,
    residentialStreetNo: clientDetails && clientDetails.residentialStreetNo,
    residentialStreetAddress: clientDetails && clientDetails.residentialStreetAddress,
    residentialSuburb: clientDetails && clientDetails.residentialSuburb,
    residentialState: clientDetails && clientDetails.residentialState,
    residentialZip: clientDetails?.residentialZip ? clientDetails.residentialZip : '',
    residentialCountry: clientDetails && clientDetails.residentialCountry,
    maritalStatus: maritalStatusValue,
    spouseTitle: clientDetails && clientDetails.spouseTitle,
    spouseFirstName: clientDetails && clientDetails.spouseFirstName,
    spouseMiddleName: clientDetails && clientDetails.spouseMiddleName,
    spouseLastName: clientDetails && clientDetails.spouseLastName,
    spouseEmail: clientDetails && clientDetails.spouseEmail,
    spouseNumber: clientDetails && clientDetails.spouseNumber,
    spouseLandline: clientDetails && clientDetails.spouseLandline,

    partnerTitle: clientDetails && clientDetails.partnerTitle,
    partnerFirstName: clientDetails && clientDetails.partnerFirstName,
    partnerLastName: clientDetails && clientDetails.partnerLastName,
    partnerMiddleName: clientDetails && clientDetails.partnerMiddleName,
    partnerEmail: clientDetails && clientDetails.partnerEmail,
    partnerNumber: clientDetails && clientDetails.partnerNumber,
    partnerLandline: clientDetails && clientDetails.partnerLandline,
    customerType: customerTypeValue,
    entryDate: clientDetails?.entryDate ? clientDetails.entryDate.split('T')[0] : new Date().toJSON().split('T')[0],
    clientStatus: clientDetails?.clientStatus || 'Others',
    referralCode: referalCodeValue,
    referralName: clientDetails && clientDetails.referrals && clientDetails.referrals.name,
    referralEmail: clientDetails && clientDetails.referrals && clientDetails.referrals.email,
    referralPhone: clientDetails && clientDetails.referrals && clientDetails.referrals.phone,
    referralStreetNo: clientDetails && clientDetails.referrals && clientDetails.referrals.streetNo,
    referralStreetAddress: clientDetails && clientDetails.referrals && clientDetails.referrals.streetAddress,
    referralSuburb: clientDetails && clientDetails.referrals && clientDetails.referrals.suburb,
    referralState: clientDetails && clientDetails.referrals && clientDetails.referrals.state,
    referralPostalCode: clientDetails && clientDetails.referrals && clientDetails.referrals.zip,
    referralCountry: clientDetails && clientDetails.referrals && clientDetails.referrals.country,
    referralLeadOpen: clientDetails && clientDetails.referrals && clientDetails.referrals.referralLeadOpen,
    referralLeadClose: clientDetails && clientDetails.referrals && clientDetails.referrals.referralLeadClose,
    transactions: clientDetails && clientDetails.transactionDetails,
    // files: clientDetails && clientDetails.correspondingUploadPaths ? clientDetails.correspondingUploadPaths : [],
    files: [],
    correspondingNote: clientDetails && clientDetails.correspondingNote,
    // clientFollowUpNotes: [
    //   {
    //     clientFollowUpDate: '',
    //     assignedTo: '',
    //     followUpNote: ''
    //   }
    // ]
  };
};

export const clientUpdatevalidationSchema = yup
  .object({
    firstName: AlphabetsWithoutRequiredValidation().required('First Name is required'),
    middleName: AlphabetsWithoutRequiredValidation(),
    lastName: AlphabetsWithoutRequiredValidation().required('Last Name is required'),
    personalEmail: EmailValidationWithoutRequiredField('Email is invalid').required('Email is required'),
    personalMobile: PhoneValidation(),
    personalPhone: PhoneValidation(),
    spouseEmail: EmailValidationWithoutRequiredField("Spouse's Email is invalid"),
    partnerEmail: EmailValidationWithoutRequiredField("Partner's Email is invalid"),
    mailingCountry: AlphabetsWithoutRequiredValidation(),
    mailingZip: NumberWithoutRequiredValidation('Postal Code').nullable(),
    residentialZip: NumberWithoutRequiredValidation('Residential postal code').nullable(),
    residentialCountry: AlphabetsWithoutRequiredValidation(),
    spouseFirstName: AlphabetsWithoutRequiredValidation(),
    spouseMiddleName: AlphabetsWithoutRequiredValidation(),
    spouseLastName: AlphabetsWithoutRequiredValidation(),
    spouseNumber: PhoneValidation(),
    spouseLandline: PhoneValidation(),
    partnerFirstName: AlphabetsWithoutRequiredValidation(),
    partnerMiddleName: AlphabetsWithoutRequiredValidation(),
    partnerLastName: AlphabetsWithoutRequiredValidation(),
    partnerNumber: PhoneValidation(),
    partnerLandline: PhoneValidation(),
    referralName: AlphabetsWithoutRequiredValidation(),
    referralEmail: EmailValidationWithoutRequiredField('Email is invalid'),
    referralPhone: PhoneValidation(),
    referralPostalCode: NumberWithoutRequiredValidation('Postal Code').nullable(),
    referralCountry: AlphabetsWithoutRequiredValidation()
  })
  .nullable();

// (capitalize, dispatch, updateClientDetailsAction, paramsid, history, leadClose, leadOpen) =>
export const clientUpdateOnSubmit = (
  values,
  onSubmitProps,
  dispatch,
  paramsid,
  history,
  leadClose,
  leadOpen,
  resetDirty,
  setReferral,
  setReferralName
) => {
  const transactionDetails = values.transactions.filter((transaction, i) => {
    return Object.keys(transaction).length > 0;
  });
  
  let referrals = {
    referralCode: values.referralCode,
    name: capitilized(values.referralName),
    email: changeToSmallCase(values.referralEmail),
    phone: values.referralPhone,
    streetNo: capitalize(values.referralStreetNo),
    streetAddress: capitalize(values.referralStreetAddress),
    suburb: capitalize(values.referralSuburb),
    state: capitalize(values.referralState),
    zip: values.referralPostalCode,
    country: capitilized(values.referralCountry),
    referralLeadOpen: leadOpen !== null ? leadOpen.email : '',
    referralLeadClose: leadClose !== null ? leadClose.email : ''
  };
  const withoutFormData={
    'title': capitilized(values.title),
  'firstName': capitilized(values.firstName),
  'middleName': capitilized(values.middleName),
  'lastName': capitilized(values.lastName),
  'email':changeToSmallCase(values.personalEmail),
  'mobile': values.personalMobile,
  'phone': values.personalPhone,
  'mailingStreetNo': capitalize(values.mailingStreetNo),
  'mailingStreetAddress': capitalize(values.mailingStreetAddress),
  'mailingSuburb': capitalize(values.mailingSuburb),
  'mailingState': capitalize(values.mailingState),
  'mailingZip': values.mailingZip,
  'mailingCountry': capitalize(values.mailingCountry),
  'residentialStreetNo': capitalize(values.residentialStreetNo),
  'residentialStreetAddress': capitalize(values.residentialStreetAddress),
  'residentialSuburb': capitalize(values.residentialSuburb),
  'residentialState': capitalize(values.residentialState),
  'residentialZip': values.residentialZip,
  'residentialCountry': capitalize(values.residentialCountry),
  'maritalStatus': values.maritalStatus,
  'spouseTitle': capitilized(values.spouseTitle),
  'spouseNumber': values.spouseNumber,
  'spouseLandline': values.spouseLandline,
  'spouseFirstName': capitilized(values.spouseFirstName),
  'spouseMiddleName': capitilized(values.spouseMiddleName),
  'spouseLastName': capitilized(values.spouseLastName),
  'spouseEmail': changeToSmallCase(values.spouseEmail),
  'partnerTitle': capitilized(values.partnerTitle),
  'partnerNumber': values.partnerNumber,
  'partnerLandline': values.partnerLandline,
  'partnerFirstName': capitilized(values.partnerFirstName),
  'partnerMiddleName': capitilized(values.partnerMiddleName),
  'partnerLastName': capitilized(values.partnerLastName),
  'partnerEmail': changeToSmallCase(values.partnerEmail),
  'customerType': values.customerType,
  'entryDate': values.entryDate,
  'clientStatus': values.clientStatus,
  'correspondingNote': values.correspondingNote,
  'referrals': referrals,
  'transactionDetails': transactionDetails,
  
  }

  // files, correspondingNote, clientFollowUpNotes
  const formData = new FormData();
  formData.append('title', capitalize(values.title));
  formData.append('firstName', capitilized(values.firstName));
  formData.append('middleName', capitalize(values.middleName));
  formData.append('lastName', capitalize(values.lastName));
  formData.append('email', changeToSmallCase(values.personalEmail));
  formData.append('mobile', values.personalMobile);
  formData.append('phone', values.personalPhone);
  formData.append('mailingStreetNo', capitalize(values.mailingStreetNo));
  formData.append('mailingStreetAddress', capitalize(values.mailingStreetAddress));
  formData.append('mailingSuburb', capitalize(values.mailingSuburb));
  formData.append('mailingState', capitalize(values.mailingState));
  formData.append('mailingZip', values.mailingZip);
  formData.append('mailingCountry', capitilized(values.mailingCountry));
  formData.append('residentialStreetNo', capitalize(values.residentialStreetNo));
  formData.append('residentialStreetAddress', capitalize(values.residentialStreetAddress));
  formData.append('residentialSuburb', capitalize(values.residentialSuburb));
  formData.append('residentialState', capitalize(values.residentialState));
  formData.append('residentialZip', values.residentialZip);
  formData.append('residentialCountry', capitilized(values.residentialCountry));
  formData.append('maritalStatus', values.maritalStatus);
  formData.append('spouseTitle', capitalize(values.spouseTitle));
  formData.append('spouseNumber', values.spouseNumber);
  formData.append('spouseLandline', values.spouseLandline);
  formData.append('spouseFirstName', capitilized(values.spouseFirstName));
  formData.append('spouseMiddleName', capitilized(values.spouseMiddleName));
  formData.append('spouseLastName', capitilized(values.spouseLastName));
  formData.append('spouseEmail', changeToSmallCase(values.spouseEmail));
  formData.append('partnerTitle', capitalize(values.partnerTitle));
  formData.append('partnerNumber', values.partnerNumber);
  formData.append('partnerLandline', values.partnerLandline);
  formData.append('partnerFirstName', capitilized(values.partnerFirstName));
  formData.append('partnerMiddleName', capitilized(values.partnerMiddleName));
  formData.append('partnerLastName', capitilized(values.partnerLastName));
  formData.append('partnerEmail', changeToSmallCase(values.partnerEmail));
  formData.append('customerType', values.customerType);
  formData.append('entryDate', values.entryDate);
  formData.append('clientStatus', values.clientStatus);
  values.files.forEach((file) => {
    formData.append('files', file);
  });
  // formData.append('otherDetails',JSON.stringify([values.correspondingNote]))

  formData.append('correspondingNote', values.correspondingNote);
  formData.append('referrals', JSON.stringify(referrals));
  formData.append('transactionDetails', JSON.stringify(transactionDetails));
  // formData.append('clientFollowUpNotes', JSON.stringify([]));

  dispatch(updateClientDetailsAction(withoutFormData, paramsid, onSubmitProps, history, resetDirty));
  onSubmitProps.setSubmitting(false);
};
