import {
  GET_EVENT_LIST_FAILED,
  GET_EVENT_LIST_PROCESSING,
  GET_EVENT_LIST_SUCCESS,
  GET_SELF_EVENT_LIST_FAILED,
  GET_SELF_EVENT_LIST_PROCESSING,
  GET_SELF_EVENT_LIST_SUCCESS,
  DELETE_EVENT_PROCESSING,
  DELETE_EVENT_FAILED,
  DELETE_EVENT_SUCCESS,
  DELETE_EVENT_RESET,
  UPDATE_EVENT_PROCESSING,
  UPDATE_EVENT_SUCCESS,
  UPDATE_EVENT_FAILED,
  UPDATE_EVENT_RESET
} from '../types/types';

const initialState = {
  status: false,
  severity: '',
  message: '',
  loading: false,
  getEventList: [],
  getSelfEventList: [],

  deletingEvent: false,
  deleteSuccessMsg: '',
  deleteErrorMsg: '',

  updatingEvent: false,
  updateSuccessMsg: '',
  updateErrorMsg: ''
};

export const eventReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_EVENT_LIST_PROCESSING:
      return {
        ...state,
        loading: true,
        getEventList: []
      };
    case GET_EVENT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        getEventList: action.payload
      };
    case GET_EVENT_LIST_FAILED:
      return {
        ...state,
        loading: false,
        getEventList: []
      };
    case GET_SELF_EVENT_LIST_PROCESSING:
      return {
        ...state,
        loading: true,
        getSelfEventList: []
      };
    case GET_SELF_EVENT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        getSelfEventList: action.payload
      };
    case GET_SELF_EVENT_LIST_FAILED:
      return {
        ...state,
        loading: false,
        getSelfEventList: []
      };

    case DELETE_EVENT_PROCESSING:
      return {
        ...state,
        deletingEvent: true,
        status: false,
        severity: '',
        deleteSuccessMsg: '',
        deleteErrorMsg: '',
      };
    case DELETE_EVENT_SUCCESS:
      return {
        ...state,
        deletingEvent: false,
        status: true,
        severity: 'success',
        deleteSuccessMsg: action.payload,
        deleteErrorMsg: '',
      };
    case DELETE_EVENT_FAILED:
      return {
        ...state,
        deletingEvent: false,
        status: true,
        severity: 'warning',
        deleteSuccessMsg: '',
        deleteErrorMsg: action.payload,
      };
    case DELETE_EVENT_RESET:
      return {
        ...state,
        deletingEvent: false,
        status: false,
        severity: '',
        deleteSuccessMsg: '',
        deleteErrorMsg: '',
      };
    case UPDATE_EVENT_PROCESSING:
      return {
        ...state,
        updatingEvent: true,
        updateSuccessMsg: '',
        updateErrorMsg: '',
        status: false,
        severity: '',

      };
    case UPDATE_EVENT_SUCCESS:
      return {
        ...state,
        updatingEvent: false,
        updateSuccessMsg: action.payload,
        updateErrorMsg: '',
        status: true,
        severity: 'success',
      };
    case UPDATE_EVENT_FAILED:
      return {
        ...state,
        updatingEvent: false,
        updateSuccessMsg: '',
        updateErrorMsg: action.payload,
        status: true,
        severity: 'warning',

      };
    case UPDATE_EVENT_RESET:
      return {
        ...state,
        deletingEvent: false,
        status: false,
        severity: '',
        updateErrorMsg: '',
        updateSuccessMsg: '',
      };

    default:
      return state;
  }

};
