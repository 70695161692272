import { createTheme } from '@material-ui/core/styles';
const theme = createTheme({
  palette: {
    primary: {
      main: '#00abcc'
    },
    secondary: {
      main: '#4b5c65'
    }
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      'Maven Pro',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(',')
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 480,
      md: 768,
      lg: 1024,
      xl: 1200
    }
  },
  spacing: 10
});

theme.props = {
  MuiInputLabel: {
    shrink: false
  },
  MuiInput: {
    disableUnderline: true
  },
  MuiSelect: {}
};

theme.overrides = {
  MuiButton: {
    root: {
      borderRadius: 0,
      fontSize: '16px',
      disableElevation: true,
      padding: '10px 22px 10px 25px',
      fontWeight: 500,
      textTransform: 'none',
      boxShadow: 'none !important',
      transition: 'all 0.25s linear'
    },
    containedPrimary: {
      color: '#fff',
      '&:hover': {
        opacity: 0.8,
        backgroundColor: '#00abcc'
      }
    },
    outlinedPrimary: {
      color: '#333',
      padding: '9px 16px',
      '&:hover': {
        color: '#fff',
        backgroundColor: '#00abcc'
      },
      startIcon: {
        backgroundColor: 'red'
      }
    },
    outlinedSecondary: {
      padding: '9px 16px',
      '&:hover': {
        color: '#fff',
        backgroundColor: '#4b5c65'
      }
    }
  },
  MuiInput: {
    root: {
      border: '1px solid #DCDADA',
      // padding: "15px 10px",
      marginTop: '0 !important',
      height: '40px',
      '&$focused': {
        border: '1px solid #00abcc'
      }
    },
    input: {
      padding: '6px 18px 7px',
      color: '#333'
    }
  },
  MuiInputLabel: {
    root: {
      fontSize: '14px',
      marginBottom: '15px',
      fontWeight: '500',
      color: '#333',
      textTransform: 'none'
    }
  },
  MuiSelect: {
    select: {
      paddingRight: '45px !important',

      '&:focus': {
        backgroundColor: '#fff'
      }
    }
  },
  MuiPaper: {
    rounded: {
      borderRadius: '0'
    },
    elevation8: {
      boxShadow: '2px 2px 8px #ececec'
    }
  },
  MuiMenu: {
    list: {
      border: '1px solid #DCDADA',
      width: '300px !important'
    }
  },
  MuiList: {
    padding: {
      paddingTop: '14px'
    }
  },
  MuiMenuItem: {
    root: {
      padding: '10px 21px !important',
      transition: 'all 0.5s ease',
      '&:hover': {
        backgroundColor: '#4b5c65',
        color: '#fff'
      }
    },
    selected: {
      '&:hover': {
        color: '#333'
      }
    }
  }
};

export default theme;
