import { useEffect } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from '../../styles/theme';
import logoWhite from '../../assets/images/logo-white.svg';
import bgPattern from '../../assets/images/pattern.svg';
import logo from '../../assets/images/logo.svg';
import { Backdrop, Button, CircularProgress, TextField } from '@material-ui/core';
import { Link } from 'react-router-dom';
// import EmailIcon from '@material-ui/icons/Email';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { resetLoginState, sendEmail } from '../../store/actions/authAction';
import { EmailValidation } from '../validations/YupValidations';
import Message from '../message/Message';

const validationSchema = yup.object({
  email: EmailValidation()
});

const ForgetPassword = () => {
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      // alert(JSON.stringify(values, null, 2));

      dispatch(sendEmail(values, resetForm));
    }
  });
  const authReducer = useSelector((state) => state.authReducer);

  useEffect(() => {
    return () => {
      dispatch(resetLoginState());
    };
  }, [dispatch]);

  return (
    <ThemeProvider theme={theme}>
      <Backdrop open={authReducer.loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Message status={authReducer.status} severity={authReducer.severity} message={authReducer.message} />

      <div className="login-wrapper">
        <div className="welcome-screen">
          <div className="login-container">
            <div className="logo-holder">
              <Link href="https://www.arsgroup.com.au/">
                <img src={logoWhite} alt="ARS mate property group"></img>
              </Link>
            </div>
            <h1>
              Welcome to <br />
              <div className="ganesh-logo">
                <span className="icon-ganesh-logo"></span> <span className="ganesh-text">GANESH</span>
              </div>
            </h1>
            <p className="login-note">
              -A CRM Application of <a href="https://www.arsgroup.com.au/">ARS Mates Property Group</a>
            </p>
            <span className="login-subtitle">Please enter your email to reset your password</span>
            <p className="login-note">
              If you hit the wrong URL you can visit
              <br />
              our website for more details. <a href="https://www.arsgroup.com.au/">https://www.arsgroup.com.au/</a>
            </p>
          </div>
          <div className="bg-pattern">
            <img src={bgPattern} alt="description"></img>
          </div>
        </div>
        <div className="login-screen">
          <div className="logo-holder-sm">
            <Link href="https://www.arsgroup.com.au/">
              <img src={logo} alt="ARS mate property group"></img>
            </Link>
          </div>
          <div className="login-container">
            <div className="login-form">
              <div className="screen-heading">
                <h2>Reset Password</h2>
                <p>Enter your email address and we will send you a link to reset your password</p>
              </div>
              <form className="update-password-form" onSubmit={formik.handleSubmit}>
                <div className="form-group">
                  <TextField
                    name="email"
                    label="Email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                </div>
                <div className="button-holder">
                  <Button type="submit" variant="contained" color="primary">
                    Send
                  </Button>
                  <Button
                    type="reset"
                    variant="outlined"
                    color="primary"
                    // className="cancel-button"
                    onClick={() => {
                      formik.resetForm();
                    }}
                  >
                    Clear
                  </Button>
                  <Link to="/auth/login">Go Back</Link>
                </div>
              </form>

              {/* <div className="icon-holder">
                <EmailIcon />
              </div>
              <div className="screen-heading">
                <h2>Check in your email</h2>
                <p>We just emailed you with the instruction to reset your password</p>
              </div> */}
            </div>
          </div>
          <div className="login-footer">
            <ul>
              <li>
                <Link href="mailto:info@arsgroup.com.au">Support</Link>
              </li>
              <li>&copy; Copyright 2021</li>
            </ul>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};
export default ForgetPassword;
