import { useEffect } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { FocusError } from 'focus-formik-error';
import theme from '../../styles/theme';
import { Button, TextField, Grid, FormHelperText, Backdrop, Select, MenuItem, InputLabel } from '@material-ui/core';
import { useFormik } from 'formik';
import { resetLoginState, signupAction } from '../../store/actions/authAction';
import { connect, useDispatch } from 'react-redux';
import Message from '../message/Message';
import CircularProgress from '@material-ui/core/CircularProgress';
import UseUnsavedWarningMsg from '../../hooks/useUnsavedWarningMsg';
import {
  signupOnSubmit,
  signupPageinitialValues,
  signupPagevalidationSchema
} from '../formik-component/signupPageFormFormik';
// import Visibility from '@material-ui/icons/Visibility';
// import VisibilityOff from '@material-ui/icons/VisibilityOff';
// import InputAdornment from '@material-ui/core/InputAdornment';
// import IconButton from '@material-ui/core/IconButton';
import React from 'react';
import AreYouSurePopUp from '../dropdown/AreYouSure';
import CloseIcon from '@material-ui/icons/Close';
const SignupPage = ({ signupAction, signupData, auth }) => {
  const dispatch = useDispatch();
  const [Prompt, setDirty, setPristine] = UseUnsavedWarningMsg();

  const formik = useFormik({
    initialValues: signupPageinitialValues,
    validationSchema: signupPagevalidationSchema,
    onSubmit: signupOnSubmit(signupAction, setPristine)
  });

  console.log('auth.accessLevel');
  console.log(auth.accessLevel);
  // const [values, setValues] = React.useState({
  //   password: '',
  //   showPassword: false,
  //   showPassword1: false
  // });

  // const handleClickShowPassword = () => {
  //   setValues({ ...values, showPassword: !values.showPassword });
  // };
  // const handleClickShowPassword1 = () => {
  //   setValues({ ...values, showPassword1: !values.showPassword1 });
  // };

  useEffect(() => {
    return () => {
      dispatch(resetLoginState());
    };
  }, [dispatch]);

  return (
    <ThemeProvider theme={theme}>
      <Backdrop open={signupData.loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {Prompt}
      <Message status={signupData.status} severity={signupData.severity} message={signupData.message} />
      <div className="signup-form-wrapper content-section">
        <h2>Create New User</h2>
        <form className="signup-form" onSubmit={formik.handleSubmit}>
          <FocusError formik={formik} />
          <div className="form-group">
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={6}>
                <TextField
                  type="text"
                  {...formik.getFieldProps('userName')}
                  label={
                    <>
                      Username <span style={{ color: 'red' }}>*</span>
                    </>
                  }
                  autoComplete="off"
                  value={formik.values.userName}
                  onChange={(e) => {
                    formik.handleChange(e);
                    setDirty();
                  }}
                  error={formik.touched.userName && Boolean(formik.errors.userName)}
                  helperText={formik.touched.userName && formik.errors.userName}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  {...formik.getFieldProps('email')}
                  label={
                    <>
                      Email <span style={{ color: 'red' }}>*</span>
                    </>
                  }
                  value={formik.values.email}
                  onChange={(e) => {
                    formik.handleChange(e);
                    setDirty();
                  }}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Grid>
            </Grid>
          </div>
          <div className="form-group">
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={3}>
                <InputLabel error={formik.touched.rolePosition && formik.errors.rolePosition}>Role/Position</InputLabel>
                <Select
                  {...formik.getFieldProps('rolePosition')}
                  value={formik.values.rolePosition}
                  onChange={(e) => {
                    formik.handleChange(e);
                    setDirty();
                  }}
                >
                  <MenuItem value="DIRECTOR">Director</MenuItem>
                  <MenuItem value="SALES_COORDINATOR">Sales Coordinator</MenuItem>
                  <MenuItem value="SALES_MANAGER">Sales Manager</MenuItem>
                  <MenuItem value="SALES_ASSOCIATES">Sales Associates</MenuItem>
                  <MenuItem value="OTHER">Other</MenuItem>
                </Select>
                <FormHelperText error={formik.touched.rolePosition && formik.errors.rolePosition}>
                  {formik.touched.rolePosition && formik.errors.rolePosition}
                </FormHelperText>
              </Grid>
              <Grid item xs={12} md={12} lg={3}>
                <InputLabel error={formik.touched.userRole && formik.errors.userRole}>
                  Access Level <span style={{ color: 'red' }}>*</span>
                </InputLabel>
                <Select
                  {...formik.getFieldProps('userRole')}
                  value={formik.values.userRole}
                  onChange={(e) => {
                    formik.handleChange(e);
                    setDirty();
                  }}
                >
                  {auth.accessLevel === 'SUPER_ADMIN' ? <MenuItem value="SUPER_ADMIN">Super Admin</MenuItem> : null}
                  <MenuItem value="ADMIN">Admin</MenuItem>
                  <MenuItem value="USER">User</MenuItem>
                </Select>
                <FormHelperText error={formik.touched.userRole && formik.errors.userRole}>
                  {formik.touched.userRole && formik.errors.userRole}
                </FormHelperText>
              </Grid>
            </Grid>
          </div>
          <div className="form-group">
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  type="text"
                  {...formik.getFieldProps('title')}
                  label={<>Title</>}
                  value={formik.values.title}
                  onChange={(e) => {
                    formik.handleChange(e);
                    setDirty();
                  }}
                  error={formik.touched.title && Boolean(formik.errors.title)}
                  helperText={formik.touched.title && formik.errors.title}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  type="text"
                  {...formik.getFieldProps('firstName')}
                  label={
                    <>
                      First Name <span style={{ color: 'red' }}>*</span>
                    </>
                  }
                  value={formik.values.firstName}
                  onChange={(e) => {
                    formik.handleChange(e);
                    setDirty();
                  }}
                  error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                  helperText={formik.touched.firstName && formik.errors.firstName}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  type="text"
                  {...formik.getFieldProps('middleName')}
                  label="Middle Name"
                  value={formik.values.middleName}
                  onChange={(e) => {
                    formik.handleChange(e);
                    setDirty();
                  }}
                  error={formik.touched.middleName && Boolean(formik.errors.middleName)}
                  helperText={formik.touched.middleName && formik.errors.middleName}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  type="text"
                  {...formik.getFieldProps('lastName')}
                  label={
                    <>
                      Last Name <span style={{ color: 'red' }}>*</span>
                    </>
                  }
                  value={formik.values.lastName}
                  onChange={(e) => {
                    formik.handleChange(e);
                    setDirty();
                  }}
                  error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                  helperText={formik.touched.lastName && formik.errors.lastName}
                />
              </Grid>
            </Grid>
          </div>
          <div className="form-group">
            <Grid container spacing={3}>
              <Grid item xs={12} className="label-grid">
                <span className="subtitle-label color-primary">Residential Address: </span>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  type="text"
                  {...formik.getFieldProps('residentialStreet')}
                  value={formik.values.residentialStreet}
                  onChange={(e) => {
                    formik.handleChange(e);
                    setDirty();
                  }}
                  label="Street No"
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  type="text"
                  {...formik.getFieldProps('residentialStreetAddress')}
                  value={formik.values.residentialStreetAddress}
                  onChange={(e) => {
                    formik.handleChange(e);
                    setDirty();
                  }}
                  label="Street Address"
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  type="text"
                  {...formik.getFieldProps('residentialSuburb')}
                  value={formik.values.residentialSuburb}
                  onChange={(e) => {
                    formik.handleChange(e);
                    setDirty();
                  }}
                  label="Suburb"
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  type="text"
                  {...formik.getFieldProps('residentialState')}
                  value={formik.values.residentialState}
                  onChange={(e) => {
                    formik.handleChange(e);
                    setDirty();
                  }}
                  label="State"
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  type="text"
                  {...formik.getFieldProps('residentialZip')}
                  label="Postal Code"
                  value={formik.values.residentialZip}
                  onChange={(e) => {
                    formik.handleChange(e);
                    setDirty();
                  }}
                  error={formik.touched.residentialZip && Boolean(formik.errors.residentialZip)}
                  helperText={formik.touched.residentialZip && formik.errors.residentialZip}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  type="text"
                  {...formik.getFieldProps('residentialCountry')}
                  value={formik.values.residentialCountry}
                  label="Country"
                  onChange={(e) => {
                    formik.handleChange(e);
                    setDirty();
                  }}
                  error={formik.touched.residentialCountry && Boolean(formik.errors.residentialCountry)}
                  helperText={formik.touched.residentialCountry && formik.errors.residentialCountry}
                />
              </Grid>
            </Grid>
          </div>
          <div className="form-group">
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={3}>
                <TextField
                  type="text"
                  {...formik.getFieldProps('phoneNumber')}
                  label="Contact Number"
                  value={formik.values.phoneNumber}
                  onChange={(e) => {
                    formik.handleChange(e);
                    setDirty();
                  }}
                  error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                  helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={3}>
                <InputLabel>Marital status</InputLabel>
                <Select
                  {...formik.getFieldProps('maritalStatus')}
                  value={formik.values.maritalStatus}
                  onChange={(e) => {
                    formik.handleChange(e);
                    setDirty();
                  }}
                >
                  <MenuItem value="Single">Single</MenuItem>
                  <MenuItem value="Married">Married</MenuItem>
                  <MenuItem value="Partner">Partner</MenuItem>
                  <MenuItem value="Divorced">Divorced</MenuItem>
                  <MenuItem value="Widowed">Widowed</MenuItem>
                  <MenuItem value="Separated">Separated</MenuItem>
                  <MenuItem value="Others">Others</MenuItem>
                </Select>
              </Grid>
            </Grid>
          </div>
          <div className="form-group">
            <Grid container spacing={3}>
              <Grid item xs={12} className="label-grid">
                <span className="category-title">Emergency Contact Person:</span>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  type="text"
                  {...formik.getFieldProps('emergencyContactPersonTitle')}
                  label="Title"
                  value={formik.values.emergencyContactPersonTitle}
                  onChange={(e) => {
                    formik.handleChange(e);
                    setDirty();
                  }}
                  error={
                    formik.touched.emergencyContactPersonTitle && Boolean(formik.errors.emergencyContactPersonTitle)
                  }
                  helperText={formik.touched.emergencyContactPersonTitle && formik.errors.emergencyContactPersonTitle}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  type="text"
                  {...formik.getFieldProps('emergencyContactPersonFirstName')}
                  label="First Name"
                  value={formik.values.emergencyContactPersonFirstName}
                  onChange={(e) => {
                    formik.handleChange(e);
                    setDirty();
                  }}
                  error={
                    formik.touched.emergencyContactPersonFirstName &&
                    Boolean(formik.errors.emergencyContactPersonFirstName)
                  }
                  helperText={
                    formik.touched.emergencyContactPersonFirstName && formik.errors.emergencyContactPersonFirstName
                  }
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  type="text"
                  {...formik.getFieldProps('emergencyContactPersonMiddleName')}
                  label="Middle Name"
                  value={formik.values.emergencyContactPersonMiddleName}
                  onChange={(e) => {
                    formik.handleChange(e);
                    setDirty();
                  }}
                  error={
                    formik.touched.emergencyContactPersonMiddleName &&
                    Boolean(formik.errors.emergencyContactPersonMiddleName)
                  }
                  helperText={
                    formik.touched.emergencyContactPersonMiddleName && formik.errors.emergencyContactPersonMiddleName
                  }
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  type="text"
                  {...formik.getFieldProps('emergencyContactPersonLastName')}
                  label="Last Name"
                  value={formik.values.emergencyContactPersonLastName}
                  onChange={(e) => {
                    formik.handleChange(e);
                    setDirty();
                  }}
                  error={
                    formik.touched.emergencyContactPersonLastName &&
                    Boolean(formik.errors.emergencyContactPersonLastName)
                  }
                  helperText={
                    formik.touched.emergencyContactPersonLastName && formik.errors.emergencyContactPersonLastName
                  }
                />
              </Grid>
              <Grid item xs={12} className="label-grid">
                <span className="subtitle-label color-primary">Residential Address</span>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  type="text"
                  {...formik.getFieldProps('emergencyResidentialStreet')}
                  value={formik.values.emergencyResidentialStreet}
                  onChange={(e) => {
                    formik.handleChange(e);
                    setDirty();
                  }}
                  label="Street No"
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  type="text"
                  {...formik.getFieldProps('emergencyResidentialStreetAddress')}
                  value={formik.values.emergencyResidentialStreetAddress}
                  onChange={(e) => {
                    formik.handleChange(e);
                    setDirty();
                  }}
                  label="Street Address"
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  type="text"
                  {...formik.getFieldProps('emergencyResidentialSuburb')}
                  value={formik.values.emergencyResidentialSuburb}
                  onChange={(e) => {
                    formik.handleChange(e);
                    setDirty();
                  }}
                  label="Suburb"
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  type="text"
                  {...formik.getFieldProps('emergencyResidentialState')}
                  value={formik.values.emergencyResidentialState}
                  onChange={(e) => {
                    formik.handleChange(e);
                    setDirty();
                  }}
                  label="State"
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  type="text"
                  {...formik.getFieldProps('emergencyResidentialZip')}
                  value={formik.values.emergencyResidentialZip}
                  label="Postal Code"
                  onChange={(e) => {
                    formik.handleChange(e);
                    setDirty();
                  }}
                  error={formik.touched.emergencyResidentialZip && Boolean(formik.errors.emergencyResidentialZip)}
                  helperText={formik.touched.emergencyResidentialZip && formik.errors.emergencyResidentialZip}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  type="text"
                  {...formik.getFieldProps('emergencyResidentialCountry')}
                  value={formik.values.emergencyResidentialCountry}
                  label="Country"
                  onChange={(e) => {
                    formik.handleChange(e);
                    setDirty();
                  }}
                  error={
                    formik.touched.emergencyResidentialCountry && Boolean(formik.errors.emergencyResidentialCountry)
                  }
                  helperText={formik.touched.emergencyResidentialCountry && formik.errors.emergencyResidentialCountry}
                />
              </Grid>
            </Grid>
          </div>
          <div className="form-group">
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  type="text"
                  {...formik.getFieldProps('emergencyContactPersonMobileNo')}
                  value={formik.values.emergencyContactPersonMobileNo}
                  label="Contact Number"
                  onChange={(e) => {
                    formik.handleChange(e);
                    setDirty();
                  }}
                  error={
                    formik.touched.emergencyContactPersonMobileNo &&
                    Boolean(formik.errors.emergencyContactPersonMobileNo)
                  }
                  helperText={
                    formik.touched.emergencyContactPersonMobileNo && formik.errors.emergencyContactPersonMobileNo
                  }
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  type="email"
                  {...formik.getFieldProps('emergencyContactPersonEmail')}
                  value={formik.values.emergencyContactPersonEmail}
                  label="Email"
                  onChange={(e) => {
                    formik.handleChange(e);
                    setDirty();
                  }}
                  error={
                    formik.touched.emergencyContactPersonEmail && Boolean(formik.errors.emergencyContactPersonEmail)
                  }
                  helperText={formik.touched.emergencyContactPersonEmail && formik.errors.emergencyContactPersonEmail}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  type="text"
                  {...formik.getFieldProps('emergencyContactPersonRelation')}
                  value={formik.values.emergencyContactPersonRelation}
                  label="Relation To User"
                  onChange={(e) => {
                    formik.handleChange(e);
                    setDirty();
                  }}
                  error={
                    formik.touched.emergencyContactPersonRelation &&
                    Boolean(formik.errors.emergencyContactPersonRelation)
                  }
                  helperText={
                    formik.touched.emergencyContactPersonRelation && formik.errors.emergencyContactPersonRelation
                  }
                />
              </Grid>
            </Grid>
          </div>

          <div className="form-buttons">
            <div className="form-row">
              <Button type="submit" variant="contained" color="primary">
                Register
              </Button>

              <div className="cancel-button-holder">
                <AreYouSurePopUp
                  variant="outlined"
                  buttonName="Clear"
                  popUpHeading="Are you sure you want to clear everything"
                  popUpDetail="
                  Are you sure you want to clear everything?"
                  formName="myform"
                  buttonType="reset"
                  className="cancel-button"
                  buttonIcon={<CloseIcon />}
                  onAgreeClick={formik.handleReset}
                  yesButtonName="Clear"
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </ThemeProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    signupData: state.authReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signupAction: (signupValues, resetForm, setPristine) => dispatch(signupAction(signupValues, resetForm, setPristine))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignupPage);
