import http from './httpHelper';

export default class UserService {
  //get allusersList
  static getcrmusers() {
    return http(true).get(`/users`);
  }
  static getCrmUsersWithQuery(query) {
    return http(true).get(`/newUserProfile/user${query}`);
  }
  static getDeletedCrmUsersWithQuery(query) {
    return http(true).get(`/newUserProfile/deletedUser${query}`);
  }

  //getcrmuser
  static getcrmuser(user_id) {
    return http(true).get(`/user/${user_id}`);
  }

  static uploadProfilePicture(data) {
    return http(true).post('/uploadProfilePicture', data);
  }

  //updatecrmuser
  static updateuser(updatedData, user_id) {
    return http(true).put(`/user/${user_id}`, updatedData);
  }
  static deleteuser(user_id) {
    return http(true).delete(`/user/${user_id}`);
  }
  static unDeleteuser(user_id) {
    return http(true).put(`/revertUser/${user_id}`);
  }
}
